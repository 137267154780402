.crp-print-cont {
    display: inline-block;
    .crp-print-button {
        color: var(--pal-primary);
        text-transform: uppercase;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 133.932%; /* 18.75px */
        cursor: pointer;
    }
}

.crp-printable-report {
    zoom: 200%;
    width: 800px;
    top: 100vh;
    position: fixed;
    opacity: 0.0;
    pointer-events: nnone;

    .org-logo {
        width: auto;
        max-height: 32px;
        max-width: 111px;
    }

    .page-title {
        display: inline-block;
        padding-left: 11px;
        border-left: 1px solid #7A828D !important;
        margin-left: 10px;
        color: #030C43;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 123.688%; /* 19.79px */
        letter-spacing: 0.024px;
    }
    
    canvas.c-rating-canvas {
        -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 1));
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 1));  
    }

    .home-address {
        color: var(--pal-primary);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 123.688%; /* 17.316px */
        letter-spacing: 0.021px;
        img {
            margin-right: 3px;
            margin-top: -5px;
            filter: var(--pal-primary-filter) !important;
        }
    }

    .headers-cont {
        display: block;
        width: 100%;
        padding-bottom: 11px;
        border-bottom: 1px solid #7A828D;
        .iheader {
            display: inline-block;
            width: calc(33% - 11px);
            margin-right: 10px;
            .htitle {
                color: #252D3A;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 123.688%; /* 12.369px */
                letter-spacing: 0.015px;
                margin-bottom: 6px;
            }
            .hvalue {
                color: #292B2C;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 135.688%; /* 16.283px */
                letter-spacing: 0.018px;
            }
        }
    }

    .summary-text {
        .summary-line {
            display: block;
            margin-top: 10px;
            color: #252D3A;
            font-family: Poppins;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 123.688%; /* 12.369px */
            letter-spacing: 0.015px;
        }
    }
    .faqs-cont {
        width: calc(100% - 20px);
        border-radius: 10px;
        border: 1px solid #7A828D;
        background: #FFF;
        padding: 10px;
        .faqs-title {
            color: var(--pal-primary);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 123.688%; /* 12.369px */
            letter-spacing: 0.015px;
            margin-top: 5px;
            margin-left: 12px;
            text-transform: uppercase;
            img {
                margin-top: 0px;
                margin-right: 5px;
                filter: var(--pal-primary-filter) !important;
            }
        }
        .faq-item {
            margin-top: 10px;
            .faq-dot {
                display: inline-block;
                color: var(--pal-primary);
                font-size: 16px;
                width: 14px;
                margin-top: 0px;
                vertical-align: middle;
            }
            .faq-question {
                display: inline-block;
                color: #292B2C;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 118%; /* 11.8px */
            }
            .faq-answer {
                display: block;
                color: #292B2C;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 161%; /* 12.88px */
                letter-spacing: 0.008px;
                margin-left: 12px;
            }
        }
    }

    .blocks-left {
        display: inline-block;
        width: calc(50% - 12px);
        margin-right: 10px;
        margin-bottom: 12px;
    }

    .blocks-right {
        display: inline-block;
        width: calc(50% - 18px);
        margin-bottom: 12px;
    }

    .energy-rating-block {
        position: relative;
        border-radius: 10px;
        border: 1px solid #7A828D;
        background: #FFF;
        width: 100%;
        height: 250px;
        overflow: hidden;
        .res-meter-cont {
            margin-left: 30px;
            display: inline-block !important;
            width: 300px !important;
        }
        text-align: center;
    }

    .env-impact-block {
        position: relative;
        border-radius: 10px;
        border: 1px solid #7A828D;
        background: #FFF;
        width: 100%;
        height: calc(500px + 12px);
        overflow: hidden !important;
        .res-meter-cont {
            margin-left: 20px;
            width: 300px !important;
        }
    }

    .energy-use-breakdown-block {
        vertical-align: top;
        position: relative;
        border-radius: 10px;
        border: 1px solid #7A828D;
        background: #FFF;
        width: 100%;
        height: 250px;
        margin-top: 12px;
        overflow: hidden;
        .crp-breakdown-tab-content {
            margin-left: 20px;
            border: none !important;
            width: 325px !important;
        }
    }

    .meter-label-left, .meter-label-right {
        zoom: 80% !important;
        line-height: 10px !important;
        margin-top: 10px !important;
    }
    .meter-label-left {
        margin-right: 10px;
    }
    .crp-breakdown-items {
        zoom: 85% !important;
    }

    .block-header {
        text-align: left;
        text-transform: uppercase;
        padding-top: 15px;
        padding-left: 15px;
        margin-bottom: -10px;
        width: 100%;
        color: var(--pal-primary);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 123.688%; /* 12.369px */
        letter-spacing: 0.015px;
        img {
            vertical-align: middle;
            width: auto;
            height: 5mm;
            margin-right: 5px;
        }
    }

    .footer {
        color: #000;
        font-family: Poppins;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 166.188%; /* 9.971px */
        letter-spacing: 0.009px;
        position: absolute;
        bottom: 30px;
    }

    &.cant-print {
        font-size: 16px;
        color: #030C43;
        font-family: Poppins;
        font-weight: 600;
    }
}

@media print {
    .public-header-cont {
        display: none;
    }
    .crp-print-button {
        display: none;
    }
    .crp-printable-report {
        opacity: 1.0;
        print-color-adjust: exact;
        position: static;
        top: 0px;
    }
}