.cust-priorities {
    &.cp-loading {
        opacity: 0.5;
        pointer-events: none;
    }
    @media screen and (min-width: 599px) {
        .cust-data-disclaimer {
            margin-left: 16px;
        }
    }
    .custp-title {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 30px;
        color: #030F40;
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-top: 0px;
        margin-bottom: 12px;
        text-align: left;
    }
    .custp-para {
        display: none;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        color: #2E2E2E;
        width: calc(100% - 32px);
        margin-left: 16px;
        img {
            vertical-align: top;
        }
        .custp-info {
            display: inline-block;
            font-weight: 500;
            color: #2E2E2E;
            width: calc(100% - 32px);
        }
    }
    .custp-para + .custp-para {
        margin-top: 16px;
    }
    .cust-priorities-widget {
        background: #FFFFFF;
        box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
        border-radius: 5px;
        width: calc(100% - 32px);
        margin-left: 16px;
        padding: 16px;
        margin-top: 16px;
        .cprio-header {
            font-style: normal;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 25px;
            color: #04203C;
        }
        .cust-prio-value {
            margin-top: 16px;
            border-top: 1px solid #DDDDDD;
            padding-top: 16px;
            .cust-prio-value-title {
                font-weight: 600;
                font-size: 1rem;
                line-height: 125%;
                display: flex;
                align-items: center;
                letter-spacing: 0.25px;
                color: #000000;
            }
            .cprio-value-list {
                .cprio-item {
                    padding: 16px;
                    margin-top: 16px;
                    background: #F2F4F5;
                    border: 1px solid #F2F4F5;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    display: block;
                    .prio-down {
                        display: inline-block;
                        width: 26px;
                        vertical-align: top;
                        height: 30px;
                        position: relative;
                        margin-left: -8px;
                        &.disabled {
                            pointer-events: none;
                            opacity: 0.25;
                        }
                    }
                    .prio-up {
                        display: inline-block;
                        width: 26px;
                        vertical-align: top;
                        height: 30px;
                        position: relative;
                        margin-left: 8px;
                        &.disabled {
                            pointer-events: none;
                            opacity: 0.25;
                        }
                    }
                    .prio-val-icon {
                        margin-left: 12px;
                        padding-left: 12px;
                        border-left: 1px solid #DDDDDD;
                    }
                    .prio-val-title {
                        margin-left: 12px;
                        display: inline-block;
                        width: calc(100% - 26px - 26px - 32px - 24px - 12px);
                        font-weight: 700;
                        font-size: 1rem;
                        line-height: 125%;
                        text-align: left;
                        letter-spacing: 0.25px;
                        color: #292B2C;
                        vertical-align: middle;
                        border-right: 1px solid #DDDDDD;
                    }
                }
            }
        }
        .cprio-empty-section {
            padding: 16px;
            font-weight: 400;
            height: 46px;
            font-size: 1rem;
            line-height: 125%;
            text-align: center;
            letter-spacing: 0.25px;
            color: #26282b;
        }
        margin-bottom: 32px;
    }
    .cprio-actions {
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-bottom: 28px;
        .cprio-save {
            display: inline-block;
            height: 44px;
            left: 20px;
            top: 1010px;
            background: #1CCF79;
            border-radius: 10px;
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px;
            letter-spacing: 0.0015em;
            color: #030C43;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
            &.disabled {
                background: #DDDDDD;
                color: #7A828D;
                pointer-events: none;
            }
            width: calc(50% - 16px);
            max-width: 200px;
        }
        .cprio-cancel {
            display: inline-block;
            height: 44px;
            left: 20px;
            top: 1010px;
            background: #F7F5F5;
            border-radius: 10px;
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px;
            letter-spacing: 0.0015em;
            color: #030C43;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: calc(50% - 16px);
            max-width: 200px;
        }
        .cprio-save + .cprio-cancel {
            margin-left: 16px;
        }
    }
}