.cnotif-page-cont {
    .cnotif-page-inner {
        width: calc(100% - 32px);
        margin-left: 16px;
        min-height: calc(100vh - 126px) !important;
    }
    @media screen and (min-width: 979px) {
        .cnotif-page-inner {
            position: relative;
            left: 50%;
            margin-left: -470px;
            width: 940px;
            text-align: left;
        }
    }
    .cnp-notif-block + .cnp-notif-block {
        margin-top: 12px;
        border-top: 1px solid#EAEAEA;
        padding-top: 12px;
    }
    .cnp-title {
        color: #0D1840;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.065px;
        margin-top: 27px;
    }
    .cnp-btn-cont {
        margin-top: 8px;
        button {
            border-radius: 5px;
            background: #F6F5F5;
            width: calc(50% - 10px);
            color: #111C4E;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            padding: 9px;
        }
        button + button {
            margin-left: 16px;
        }
    }
    .cnp-notif-card {
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.10);
        padding: 16px;
        margin-top: 15px;
        margin-bottom: 30px;
    }
    .cnp-snooze-by-label {
        margin-top: 15px;
        margin-bottom: 5px;
        color: #2E2E2E;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .cnp-snooze-by-btn {
        margin-top: 32px;
        border-radius: 10px;
        background: #1CCF79;
        box-shadow: 0px 4px 2px 0px #11995E;
        color: #0D1840;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 123.688%; /* 19.79px */
        letter-spacing: 0.024px;
        height: 53px;
        padding: 10px;
        text-transform: uppercase;
        width: 100%;
    }
    .cnp-no-notif {
        color: #2E2E2E;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 147.2%;
    }
    .cnp-alert-title {
        color: #AE6201;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 6px;
        img {
            margin-right: 4px;
        }
    }
    .cnp-notif-title {
        color: #4A3DF0;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 6px;
        img {
            margin-right: 4px;
        }
    }
    .cnp-alert-desc {
        color: #2E2E2E;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 147.2%;
        white-space: pre-line;
    }
    .cnp-trigged-by, .cnp-modelling-level, .cnp-days-ago {
        color: #686868;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 147.2%;
        margin-top: 8px;
        .en-level-cont {
            display: inline-block;
        }
    }
}