.c-rating-bubble-cont {
    position: absolute;
    canvas.c-rating-canvas {
        -webkit-filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));  
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .c-rating-inner {
        position: absolute;
        z-index: 2;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }
}