html {
    font-size: 100% !important;
	letter-spacing: initial !important;
	word-spacing: initial !important;
    body {
        font-family: 'Poppins';
	    font-style: normal;
    }
}

body.customer-page {
    background: #ffffff !important;
    margin-top: 0px !important;
    .customer-terms-page {
        height: 100%;
        position: fixed;
        padding: 16px;
        z-index: 1000000;
        top: 0px;
        background-color: white;
        overflow-y: scroll !important;
        .ph-lang-switcher {
            position: absolute;
            right: 10px;
        }
        .ctc-title {
            color: #000;
            font-family: Poppins;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 124%; /* 29.76px */
            letter-spacing: 0.00375rem;
        }
        .ctc-terms {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 167.975%; /* 26.876px */
            letter-spacing: 0.005rem;
            margin-top: 11px;
            margin-bottom: 22px;
        }
        @media screen and (max-width: 750px) {
            .ctc-terms {
                margin-top: 50px;
            }
        }
        .ctc-terms-footer {
            background-color: #030C43;
            margin-left: -16px;
            margin-bottom: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
            .ctc-check {
                margin-top: 19px;
                &.ctc-check-checked {
                }
                &.ctc-check-unchecked {

                }
                &:focus{
                    img{
                        outline: 2px solid #ffffff !important;
                        outline-offset: 2px !important;
                    }
                }
                img {
                    vertical-align: top;
                    display: inline-block;
                    margin-right: 13px;
                }
                .ctc-checkbox-label {
                    vertical-align: top;
                    width: calc(100% - 25px - 25px);
                    display: inline-block;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    text-align: left;
                    font-weight: 500;
                    line-height: 129.688%; /* 20.75px */
                    letter-spacing: 0.0015rem;
                    margin-bottom: 16px;
                }
            }
            .ctc-save-and-continue {
                width: calc(100% - 32px);
                padding: 10px;
                margin-top: 0px;
                margin-left: 16px;
                margin-bottom: 16px;
                border-radius: 10px;
                background: #1CCF79;
                color: #030303;
                text-align: center;
                font-family: Inter;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 123.688%; /* 19.79px */
                letter-spacing: 0.0015;
                &:disabled {
                    color: #5661A4;
                    background-color: rgba(232, 231, 255, 0.15);
                }
                &:focus {
                    outline: 2px solid #ffffff !important;
                    outline-offset: 2px !important;
                }
            }
        }
    }
}