button.c-faq-button-cont {
    cursor: pointer;
    img.c-faq-button-large {
        display: inline-block;
    }
    img.c-faq-button-small {
        display: none;
    }
}
@media screen and (max-width: 470px) {
    button.c-faq-button-cont {
        img.c-faq-button-large {
            display: none !important;
        }
        img.c-faq-button-small {
            display: inline-block !important;
        }
    }
}