.page-title {
  color: $text-color;
  font-weight: 300;
  line-height: 1;
  /* stylelint-disable-next-line */
  margin: 11px 0 (1.5*$spacer);

  small {
    color: rgba($white, 0.7);
  }

  @media (max-width: breakpoint-max(sm)) {
    margin-top: 0;
  }
}

.page-top-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & .close {
    margin-left: 25px;
  }
}

.form-control {
  color: $content-color;
  &:focus,&:active {
    color: $content-color;
  }
  &.input-transparent {
    border: none;
    color: $text-color;
    background-color: $widget-bg-color;

    @include placeholder(rgba(#eee, 0.76));

    &:active,
    &:focus {
      background-color: $bg-custom-dark !important;
      box-shadow: inset 0 1px 1px rgba(0,0,0,0.2);
    }


    &[disabled],
    &[readonly],
    fieldset[disabled] & {
      background-color: lighten($widget-bg-color, 10%);
    }
  }
}


.input-group.focus {
  .input-group-addon,
  input {
    background-color: #f1f1f1;
  }
}

.animated {
  animation-duration: 0.5s;
}

.chat-notification-sing {
  position: absolute;
  top: 12px;
  left: 14px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: theme-color('danger');
}

#notification-buttons {
  display: flex;

  button {
    flex: 1;
    font-family: $font-family-base;
    font-size: 0.875rem;
    background-color: #f8f8f8;

    &.active {
      color: #343434;
      background-color: #dfdfdf;
      background-image: none;
      border-color: #adadad;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.12);
    }
  }
}

.btn-toolbar .btn {
  margin-left: 0.5rem;
}

/* --------- Forms --------- */
.form-action {
  background-color: #f5f5f5;
  margin: $spacer 0 0;
  padding: $spacer/2 $spacer;
}

.widget-top-overflow,
.widget-middle-overflow {
  position: relative;
  margin: 0 (-$widget-padding-horizontal);

  > img {
    max-width: 100%;
  }
}

table > thead > tr > th {
  border-top: none !important;
  border-bottom-width: 1px !important;
  text-transform: uppercase;
}

/*         Widgets Grid          */

.widget-container {
  min-height: 30px;

  .widget:not(.fullscreened) > header {
    cursor: move;
  }
}

.widget-placeholder {
  background: rgba($white, 0.4);
  border: 1px dashed $gray-600;
  margin: -1px -1px calc(#{$grid-gutter-width} - 1px) -1px;
}

.sortable-chosen { 
  opacity: 1!important
}

.widget-placeholder-react {
  background: rgba($white, 0.4)!important;
  border: 1px dashed $gray-600!important;
  margin: -1px -1px calc(#{$grid-gutter-width} - 1px) -1px!important;
  > * {
    opacity: 0;
  }
}
.btn-link {
  background: transparent;
  border: 0;
  outline: 0;
  color: $link-color;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}