.cust-faq-section {
    width: 100%;
    text-align: left;
    .cust-faq-header {
        color: #0D1840;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 14px;
    }
    .cust-faq-inner {
        padding: 16px;
    }
    .cust-faq {
        display: block;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #EAEAEA;
        margin-bottom: 12px;
        padding-bottom: 6px;
        .cust-faq-title {
            color: #030C43;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 6px;
            text-align: left;
        }
        &.cf-expanded {
            .cust-faq-title {
                color: #4A3DF0;
            }
        }
        .cust-faq-body {
            color: #2E2E2E;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 147.2%; /* 20.608px */
            hr {
                display: none;
            }
            strong{
                color: #757575;
            }
        }
        .cust-faq-read-more {
            color: #4A3DF0;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 147.2%; /* 20.608px */
        }
    }
    .cust-faq-more-less {
        width: 100%;
        color: #2A47CA;
        text-align: center;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 121.7%; /* 17.038px */
        text-transform: uppercase;
    }
}