html {
    font-size: 100% !important;
	letter-spacing: initial !important;
	word-spacing: initial !important;
    body {
        font-family: 'Poppins';
	    font-style: normal;
    }
}

body.customer-page {
    background: #ffffff !important;
    margin-top: 0px !important;
    overflow-y: scroll !important;
    .customer-success-page {
        height: 100%;
        position: relative;
        .csp-header {
            width: 100%;
            height: 70px;
            left: 0px;
            top: 0px;
            background: #ffffff;
            text-align: center;
            box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
            &.left-justify {
				text-align: left;
                a > img {
				    height: 28px;
                    margin-top: 21px;
                    margin-left: 45px;
                }
			}
            button {
                margin-top: 0px;
                height: 54px;
            }
            .csp-logout {
                cursor: pointer;
                position: absolute;
                top: 24px;
                right: 24px;
                width: 20px;
                height: 20px;
            }
            .lang-switcher {
                top: 8px;
                right: 50px;
    
                position: absolute;
                display: inline-block;
                box-sizing: border-box;
                border: 1px solid #000;
                border-radius: 7px;
    
                cursor: pointer;
    
                .lang-button {
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.3125rem;
                    text-transform: uppercase;
                    height: 33px;
    
                    background-color: #ffffff;
                    color: #1226AA;
                    border-radius: 6px 0px 0px 6px;
                    padding-left: 10px;
                    padding-right: 10px;
                    border: 1px solid black;
                    &.selected {
                        border: 1px solid transparent;
                        background: #1226AA;
                        color: #ffffff;
                    }
                    &:focus {
                        border: 2px solid #030C43;
                        box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                        outline: none;
                        border-radius: 6px 0px 0px 6px;
                    }
                }
    
                .lang-button + .lang-button {
                    border-radius: 0px 6px 6px 0px;
                }
    
            }
            @media screen and (max-width: 550px) {
                .lang-switcher {
                    right: 31px;
                }
            }
        }
        .csp-body {
            text-align: center;
            img {
                margin-top: 49px;
                margin-bottom: 13px;
            }
            .csp-text1 {
                display: inline-block;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 131.5%;
                text-align: center;
                letter-spacing: 0.0025rem;
                color: #292B2C;
                width: 450px;
                max-width: 90vw;
                text-align: center;
            }
            .csp-text2 {
                margin-top: 23px;
                display: inline-block;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                letter-spacing: 0.013125rem;
                color: #000000;
                width: 450px;
                max-width: 90vw;
                text-align: center;
                margin-top: 25px;
                margin-bottom: 25px;
            }
            .csp-homepage-button {
                display: inline-block;
                width: 236px;
                height: 48px;
                left: 88px;
                top: 556px;
                background: #0070CD;
                border-radius: 10px;
                font-weight: 500;
                font-size: 1rem;
                line-height: 48px;
                letter-spacing: 0.0015em;
                color: #FFFFFF;
                margin-top: 20px;
                cursor: pointer;
                &:hover {
                    opacity: 0.9;
                }
                margin-bottom: 100px;
            }
        }
    }
    .csp-footer {
        position: fixed;
        bottom: 0px;
        z-index: 2;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.75rem;
        color: #FFFFFF;
        height: 74px;
        width: 100vw;
        text-align: center;
        background: #04203C;
        .csp-fspace {
            height: 9px;
        }
    }
}