div.cust-dropdown-button {
  z-index: 2147483001;
}
.cust-dropdown-button {
    user-select: none;
    &.read-only {
      pointer-events: none !important;
    }
    vertical-align: top;
    display: inline-block;
    padding: 10px;
    height: 46px;
    background: #252D3A;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.1rem;
    width: 100%;
    color: #292B2C;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    text-align: left;
    padding-left: 16px !important;

    position: relative;
    &:hover {
      &:not(.active) {
        background: #EEEEEE;
      }
    }
    &:not(.active) {
      cursor: pointer;
    }
    &.active {
      border: 1px solid #55B9FC;
    }
    &.types-dropdown {
      width: 235.5px;
      color: #AAB2BD;
      border: 1px solid #2D3645;
      &.active {
        border: 1px solid #55B9FC;
      }
    }
    img.dropdown-arrow {
      position: absolute;
      top: 16px;
      right: 11px;
    }
    .cust-dropdown-arrow-icon {
      position: absolute;
      pointer-events: none;
      right: 11px;
      top: 5px;
    }

    .cust-dd-subtitle {
      display: block;
      color: #7A828D;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 118%; /* 11.8px */
      margin-top: 4px;
      padding-bottom: 12px;
      white-space: nowrap;
    }
  
    .cust-dropdown-filter-input {
      border: 0;
      background: transparent;
      box-shadow: none;
      outline: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 16px;
      letter-spacing: 0.001em;
      color: #AAB2BD;
      &input {
        height: 36px;
      }
      padding-left: 14px;
      margin-top: 8px;
      &:focus {
        border: 0;
        outline: 0;
        background: transparent;
        box-shadow: none;
        caret-color: #AAB2BD;
      }
      width: 100%;
    }
    .cust-dd-bubble {
      top: 46px;
      position: absolute;
      &.cust-dd-open-up {
        position: absolute;
        top: auto;
        bottom: 38px;
        margin-top: 0px;
      }
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 7px;
      box-sizing: border-box;
      padding: 0px;
      width: auto;
      z-index: 1;
      max-height: 350px;
      overflow-y: scroll;
      right: 0px;
      .cust-dd-option {
        display: block;
        &:hover {
          background: #DDDDDD;
          &.selected {
            background: #55b9fc;
          }
        }
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.1rem;
        width: 100%;
        text-align: left;
        height: 3.25rem;
        cursor: pointer;
        img {
          margin-top: 0.45rem;
        }
        &.selected {
          background: rgba(74, 61, 240, 0.11);
          /*pointer-events: none;*/
          cursor: auto;
        }
        &.first {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        &.last {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        padding: 8px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &.dd-pull-up {
      z-index: 5 !important;
      .cust-dd-bubble {
        min-width: 150px;
        height: 150px !important;
        top: -182px !important;
        right: 5px !important;
        overflow-y: scroll;
      }
      @media screen and (max-width: 848px) {
        .cust-dd-bubble {
          right: -64px !important;
        }
      }
    }
    &:focus {
      outline: 2px solid #111C4E !important;
      outline-offset: 2px;
    }
  }

  .cust-dropdown-button.cdb-style-btmh {
    padding-left: 8px !important;
    display: inline-block;
    border-radius: 5px;
    background: #F6F5F5;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    width: calc(50% - 16px);
    height: auto;
    box-shadow: none;
    color: #111C4E;
    cursor: pointer;
    border: 0px transparent;
    padding-left: 5px;
    img.dropdown-arrow {
      position: absolute;
      top: 8px;
      right: 0px;
    }
    .cust-dropdown-arrow-icon {
      position: absolute;
      pointer-events: none;
      right: 0px;
      top: 3px;
    }
    .cust-dd-bubble {
      min-width: 300px;
      height: auto;
      .cust-dd-option {
        font-size: 0.9rem;
        padding-left: 30px !important;
        padding-bottom: 5px !important;
        padding-top: 11px !important;
        line-height: 1.0rem;
        padding-right: 0px;
        position: relative;
        margin-top: -5px;
        img {
          position: absolute;
          top: 13px;
          left: 8px;
        }
        margin-bottom: 5px;
      }
    }
  }

  .cust-dropdown-button.cdb-style-res-more {
    padding-left: 0px !important;
    display: inline-block;
    border-radius: 5px;
    background: transparent;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    width: 24px;
    height: auto;
    box-shadow: none;
    color: #111C4E;
    cursor: pointer;
    border: 0px transparent;
    padding-left: 5px;
    left: 30px;
    top: 20px;
    img.dropdown-arrow {
      position: absolute;
      top: 8px;
      right: 0px;
    }
    .cust-dropdown-arrow-icon {
      position: absolute;
      pointer-events: none;
      right: 0px;
      top: 3px;
    }
    .cust-dd-bubble {
      min-width: 150px;
      height: auto;
      right: 24px !important;
      top: -20px !important;
      .cust-dd-option {
        font-size: 0.9rem;
        padding-left: 10px !important;
        padding-bottom: 5px !important;
        padding-top: 11px !important;
        line-height: 1.0rem;
        padding-right: 0px;
        position: relative;
        margin-top: -5px;
        margin-bottom: 5px;
      }
    }
    @media screen and (max-width: 470px) {
      .cust-dd-bubble {
        top: -49px !important;
      }
    }
  }

  .cust-dropdown-button.cust-dropdown-button.cdb-style-survey-steps {
    border-radius: 5px;
    background: #F2F4F5;
    height: 52px;
    border: none;
    box-shadow: none;
    width: calc(100vw - 102px);
    margin-left: 12px;
    img.cust-dropdown-arrow-icon {
      right: 0px;
      top: 8px;
    }
    .cust-dd-bubble {
      margin-top: -8px;
      margin-right: 12px;
      width: calc(100vw - 102px);
      .cust-dd-option {
        background-color: white;
      }
    }
    .cddss-step-title {
      display: inline-block;
      color: var(--pal-primary);
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 20px */
      letter-spacing: 0.015625rem;
    }
    .cddss-step-no {
      display: inline-block;
      color: #030C43;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      position: absolute;
      right: 48px;
    }
    .cddss-icont {

    }
    .cust-dd-option {
      height: 28px;
      padding-bottom: 30px !important;
      color: #2E2E2E;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.015625rem;
      &.selected {
        background-color: #F0F0F0 !important;
      }
      img {
        margin-top: 0px;
        margin-right: 5px;
        width: 28px;
        height: 28px;
      }
      .cddss-opt-step-no {
        display: inline-block;
        margin-top: 2px;
        margin-right: 7px;
        margin-bottom: 2px;
        margin-left: 2px;
        width: 24px;
        height: 24px;
        border-radius: 28px;
        border: 2px solid var(--pal-primary);
        color: #007960;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.021875rem;
        text-align: center;
      }
    }
    .cssdd-skip-cont {
      width: 100%;
      border-top: 1px solid#E4E4E4;
      margin-top: 7px;
      .cssdd-skip-to-end {
        color: var(--pal-primary);
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 20px */
        letter-spacing: 0.015625rem;
        margin-top: 14px;
        margin-left: 14px;
        img {
          margin-top: -4px;
        }
      }
    }
    .cust-dd-bubble {
      height: 320px;
    }
  }

  .cust-dropdown-button.cdb-style-report-home-switcher {
    padding-left: 8px !important;
    display: inline-block;
    border-radius: 5px;
    background: #F6F5F5;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    width: calc(100%);
    max-width: calc(100vw - 32px);
    height: auto;
    box-shadow: none;
    color: #111C4E;
    cursor: pointer;
    border: 0px transparent;
    padding-left: 5px;
    padding-right: 24px;
    margin-top: 8px;
    img.rhs-sel-icon {
      position: relative;
      top: -2px;
      left: 0px;
      margin-right: 6px;
    }
    img.dropdown-arrow {
      position: absolute;
      top: 8px;
      right: 0px;
    }
    .cust-dropdown-arrow-icon {
      position: absolute;
      pointer-events: none;
      right: 0px;
      top: 3px;
    }
    .cust-dd-bubble {
      min-width: 150px;
      height: auto;
      top: -5px !important;
      .cust-dd-option {
        font-size: 0.9rem;
        padding-left: 30px !important;
        padding-bottom: 5px !important;
        padding-top: 11px !important;
        line-height: 1.0rem;
        padding-right: 0px;
        position: relative;
        margin-top: -5px;
        img {
          position: absolute;
          top: 13px;
          left: 8px;
        }
        margin-bottom: 5px;
      }
    }
  }

  .cdd-action-button {
    padding-top: 14px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-bottom: 14px !important;
    color: var(--pal-primary);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    text-transform: uppercase;
    img {
      filter: var(--pal-primary-filter);
      margin-right: 5px;
      margin-left: 6px;
      margin-top: -2px;
    }
    &:hover {
      background: #EEEEEE;
    }
    &:not(.active) {
      cursor: pointer;
    }
  }