.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.navbar {
  height: $navbar-height;
  background-color: transparent !important;
  border: none;
  font-weight: 500;
  padding: 0.9rem 2rem;

  .nav {
    height: 100%;
  }

  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      color: $navbar-link-color;
      height: 100%;
      position: relative;

      &:hover {
        color: $navbar-link-hover-color;
        background: $navbar-link-hover-bg;
      }

      .la {
        font-size: 20px;
      }
    }

  }

  .nav > .nav-item > .nav-link.bs-tether-target.show {
    display: flex !important;
  }

  .dropdown-menu {
    margin: 0;
  }
}

.nav {
  .dropdown-toggle {
    color: $white;
  }

  .nav-item.dropdown.show .nav-link.active,
  .nav-item.show .dropdown-toggle,
  .nav-link.active,
  .dropdown-menu {
    color: $white;
    background-color: rgba($widget-bg-color, 1);
  }
}

.progress-bar {
  box-shadow: none;
}

.progress {
  height: $spacer * 1.3;
  margin-bottom: $spacer / 2;
  background: $bg-addition;
}

.progress-sm {
  height: 10px;
}

.progress-xs {
  height: 5px;
}

.form-control {
  font-family: $font-family-base;
  box-shadow: none;
  transition: border-color ease-in-out 0.15s, background-color ease-in-out 0.15s;
  background: $bg-custom-dark;
  border: none;
  &:focus {
    box-shadow: none;
    background: $bg-custom-dark;
  }

  &.no-border {
    border: none;

    &:focus {
    }
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: $gray-800;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $spacer;
  line-height: inherit;
  color: $text-muted;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-size: 15px;
  font-weight: $font-weight-normal;
}

.input-sm {
  font-size: 13px;
}

.alert {
  font-size: $font-size-sm;

  .close {
    color: rgba(#000, 0.4);
  }
}

.alert-sm {
  padding: 10px 15px;

  .close {
    font-size: 20px;
    top: 0;
    right: -8px;
  }
}

.table-sm {
  font-size: $font-size-sm;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    padding: 6px;
  }

  &.table-no-border {
    margin-left: -4px;
    margin-right: -4px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
}

.small,
small {
  font-size: 85%;
}

///////// Glyphicons //////////

.glyphicon {
  vertical-align: middle;
}

///////// Font Awesome //////////

.navbar {
  .fa {
    &.fa-lg {
      line-height: 19px;
    }
  }
}

////////// Headings //////////

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  small,
  .small {
    font-weight: 100;
    font-size: 75%;
  }
}

/* -------------------------
--------- Messenger --------
---------------------------- */

@-webkit-keyframes ui-spinner-rotate-right {
  /* line 64, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 65, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(180deg);
  }

  /* line 66, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 67, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(360deg);
  }

  /* line 68, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  /* line 72, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 73, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(0deg);
  }

  /* line 74, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 75, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(180deg);
  }

  /* line 76, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-right {
  /* line 80, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 81, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(180deg);
  }

  /* line 82, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 83, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(360deg);
  }

  /* line 84, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-left {
  /* line 88, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 89, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(0deg);
  }

  /* line 90, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 91, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(180deg);
  }

  /* line 92, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-right {
  /* line 96, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 97, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(180deg);
  }

  /* line 98, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 99, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(360deg);
  }

  /* line 100, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-left {
  /* line 104, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 105, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(0deg);
  }

  /* line 106, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 107, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(180deg);
  }

  /* line 108, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}

/* Messenger spinner */
.messenger-spinner {
  position: relative;
  border-radius: 100%;
}

/* line 120, ../../src/sass/messenger-spinner.scss */
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}

/* line 124, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}

/* line 130, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

/* line 140, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}

/* line 143, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-left;
  -moz-animation-name: ui-spinner-rotate-left;
  -o-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

/* line 152, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}

/* line 155, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-right;
  -moz-animation-name: ui-spinner-rotate-right;
  -o-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

///// Toastify /////
.Toastify {
  .Toastify__toast {
    font-family: Poppins, sans-serif;
    border-radius: $border-radius;
    &.Toastify__toast--success {
      background: $success-dark;
    }

    &.Toastify__toast--warn {
      background: $warning;
    }

    &.Toastify__toast--error {
      background: $danger;
    }

    &.Toastify__toast--info {
      background: $info;
    }

    &-body {
      text-align: center;
    }
  }

  .notification-close {
    height: $font-size-base;

    &:hover {
      color: darken($white, 10%);
    }
  }
}


//// React Tags Input ////
.react-tagsinput {
  &.react-tagsinput--focused {
    border-color: $input-focus-border-color;
  }

  .react-tagsinput-tag, .react-tagsinput-input {
    margin: 2px !important;
  }

  .react-tagsinput-tag {
    border-radius: $border-radius;
    background-color: $purple;
    color: white;
    border-color: darken($purple, 10%);
  }
}
//// Highcharts Width fix ////
.highcharts-container, .highcharts-root {
  width:100% !important;
}

.breadcrumb > .active {
  color: $text-color;
}

.btn-link {
  color: $primary
}

.table {
  background-color: transparent !important;
}

.table thead th {
  border-bottom: 2px solid $b360-Grayish-Blue;
}

.table tbody td {
  border-bottom: 1px solid adjust-color($b360-Grayish-Blue, $alpha: -0.75);
}

.dropdown-menu .dropdown-item:hover {
  background-color: adjust-color(theme-color('primary'), $alpha: -0.75);
}

.dropdown-item a, .dropdown-item {
  display: block;
}

pre code {
  color: $content-color;
}

.modal {
  .modal-body, .modal-header, .modal-footer {
    background: #1b1e3c;
    color: $content-color
  }
  .modal-header {
    border-bottom: 1px solid $bg-custom-dark;
  }
  .modal-footer {
    border-top: 1px solid $bg-custom-dark;
  }
}

.form-control-plaintext {
  color: theme-color('primary');
  border-radius: 0.5em;
  padding-left: 0.5em;
  background-color: darken($widget-bg-color, 1.5);
}

.selectCustomization {
  > div {
    background: $bg-custom-dark !important;
    border: 1px solid $bg-custom-dark !important;
  }
  .react-select__single-value {
    color: $content-color !important;
  }
  .react-select__option--is-focused {
    background: $subtle-blue !important;
  }
  .react-select__option--is-selected {
    background: rgba($subtle-blue,.6) !important;
  }
} 