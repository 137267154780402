.cstat-block {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding-top: 22px !important;
    padding-bottom: 22px !important;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.10);
    .cstb-0 {
        display: inline-block;
        white-space: pre;
        font-family: Poppins;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 121.7%; /* 19.472px */
        text-transform: uppercase;
        text-align: left;
    }
    .cstb-1 {
        display: inline-block;
        font-family: Poppins;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 118%; /* 42.48px */
        text-transform: uppercase;
        text-align: left;
        margin-right: 12px;
        img {
            margin-right: 6px;
        }
    }
    .cstb-2 {
        display: inline-block;
        white-space: pre;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 121.7%; /* 19.472px */
        text-transform: uppercase;
        text-align: left;
    }
}