html {
    font-size: 100% !important;
	letter-spacing: initial !important;
	word-spacing: initial !important;
    body {
        font-family: 'Poppins';
	    font-style: normal;
    }
}

/*.atlwdg-trigger {
    z-index: 100;
    transform: rotate(-90deg);
    right: -43px !important;
    top: 50vh;
    height: 45px;
    width: 106px;
}*/

.cwp-intro-survey-complete-btn {
    width: calc(100%);
    display: block;
    padding: 10px !important;
    border-radius: 5px;
    background: #1CCF79 !important;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #030C43;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    margin-top: 12px;
    cursor: pointer;
    &:disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}

.cwp-disabled-edits-cont {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    .cwp-disabled-edits {
        display: inline-block;
        width: 940px;
        max-width: calc(100vw - 32px);
        background: #FFFFFF;
        box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
        border-radius: 5px;
        padding: 20px;
        text-align: left;
        .cwp-de-title {
            color: #04203C;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 143.5%; /* 31.57px */
        }
        .cwp-de-sub-title {
            margin-top: 7px;
            color: #292B2C;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 20px */
            letter-spacing: 0.25px;
        }
        .cwp-de-field {
            display: inline-block;
            max-width: 400px;
            width: 100%;
            margin-right: 16px;
            margin-top: 4px;
            .cwp-def-label {
                vertical-align: top;
                width: calc(60% - 8px);
                max-width: 210px;
                display: inline-block;
                color:#757575;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 125%; /* 20px */
                letter-spacing: 0.25px;
            }
            .cwp-def-value {
                vertical-align: top;
                width: calc(40% - 8px);
                margin-left: 8px;
                text-align: left;
                display: inline-block;
                color: #292B2C;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 125%; /* 20px */
                letter-spacing: 0.25px;
            }
        }
        @media screen and (max-width: 550px) {
            .cwp-de-field {
                margin-top: 14px;
                position: relative;
                .cwp-def-label {
                    width: 85%;
                    line-height: 0.85rem;
                }
                .cwp-def-value {
                    text-align: right;
                    width: auto;
                    position: absolute;
                    right: 0px;
                    max-width: 120px;
                    line-height: 0.85rem;
                }
            }
        }
    }
    margin-bottom: 20px;
}

.report-generating-alert {
    z-index: 1000;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1) !important;
}

body.customer-page {
    background: #ffffff !important;
    margin-top: 0px !important;
    .customer-wizard-page, .cwp-intro-survey {
        &.cwp-intro-survey {
            .cwp-question-card {
                width: 100% !important;
                max-width: 350px;
            }
            .cwp-to-row-desktop {
                display: none !important;
            }
            .cwp-to-row-mobile {
                display: block !important;
            }
        }
        .cwp-header {
            width: 100%;
            height: 70px;
            left: 0px;
            top: 0px;
            background: #ffffff;
            text-align: center;
            box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
            &.left-justify {
				text-align: left;
                a > img {
				    height: 28px;
                    margin-top: 21px;
                    margin-left: 45px;
                }
			}
            a > img {
                margin-top: 7px;
                height: 54px;
            }
            .cwp-logout {
				cursor: pointer;
				position: absolute;
				top: 24px;
				right: 24px;
                width: 20px;
                height: 20px;
			}
            .lang-switcher {
                top: 8px;
                right: 50px;
    
                position: absolute;
                display: inline-block;
                box-sizing: border-box;
                border: 1px solid #000;
                border-radius: 7px;
    
                cursor: pointer;
    
                .lang-button {
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.3125rem;
                    text-transform: uppercase;
                    height: 33px;
    
                    background-color: #ffffff;
                    color: #1226AA;
                    border-radius: 6px 0px 0px 6px;
                    padding-left: 10px;
                    padding-right: 10px;
                    border: 1px solid black;
                    &.selected {
                        border: 1px solid transparent;
                        background: #1226AA;
                        color: #ffffff;
                    }
                    &:focus {
                        border: 2px solid #030C43;
                        box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                        outline: none;
                        border-radius: 6px 0px 0px 6px;
                    }
                }
    
                .lang-button + .lang-button {
                    border-radius: 0px 6px 6px 0px;
                }
    
            }
            @media screen and (max-width: 550px) {
                .lang-switcher {
                    right: 31px;
                }
            }
        }

        .cwp-info-text-cont {
            width: 100%;
            text-align: center;
            .cwp-info-text {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.25rem;
                color: #164B6F;
                width: 100%;
                text-align: left;
                display: inline-block;
                width: 940px;
                max-width: calc(100vw - 32px);
            }
        }

        .cwp-view-report-button {
            display: inline-block;
            width: 236px;
            height: 48px;
            left: 88px;
            top: 556px;
            background: #0492CF;
            border-radius: 10px;
            font-weight: 500;
            font-size: 1rem;
            line-height: 3rem;
            letter-spacing: 0.0015rem;
            color: #FFFFFF;
            margin-top: 0px;
            cursor: pointer;
            &:hover {
                opacity: 0.75;
            }
            margin-bottom: 42px;
        }

        .cwp-skip-to-end-desktop-cont {
            width: 100%;
            text-align: center;
            height: 0px;
        }
        .cwp-skip-to-end-desktop-inner {
            display: inline-block;
            position: relative;
            width: 940px;
            max-width: calc(100vw - 32px);
            height: 0px;
        }
        .cwp-skip-to-end-desktop {
            z-index: 1 !important;
            position: absolute;
            cursor: pointer;
            top: -67px;
            right: 0px;
            color: var(--pal-primary);
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 20px */
            letter-spacing: 0.015625rem;
            img {
                vertical-align: middle;
                margin-top: -2px;
            }
        }

        .cwp-progress-desktop {
            display: block;
            text-align: center;
            width: 100%;
            .cwp-progress-inner {
                margin-top: -8px;
                display: inline-block;
                position: relative;
                width: 940px;
                max-width: calc(100vw - 32px);
                height: 69px;
                .cwp-pd-bar-bg {
                    width: 100%;
                    background-color: #E5E5E5;
                    z-index: 1;
                    height: 10px;
                    border-radius: 5px;
                    position: absolute;
                    top: 14px;
                }
                .cwp-pd-bar-done {
                    background-color: #56BBCE;
                    z-index: 2;
                    height: 10px;
                    border-radius: 5px;
                    position: absolute;
                    top: 14px;
                }
                .cwp-pd-step {
                    &.cwp-pd-step-btn {
                        cursor: pointer;
                        &:hover {
                            .cwp-pds-circle {
                                background-color: #56BBCE;
                                color: #FFFFFF;
                            }
                            .cwp-pds-title {
                                color: #56BBCE;
                            }
                        }
                    }
                    display: inline-block;
                    position: relative;
                    text-align: center;
                    .cwp-pds-circle {
                        position: relative;
                        display: inline-block;
                        background-color: #E5E5E5;
                        width: 38px;
                        height: 38px;
                        z-index: 3;
                        border-radius: 19px;
                        font-weight: 700;
                        font-size: 1.125rem;
                        line-height: 2.675rem;
                        color: #B9BCC0;
                    }
                    .cwp-pds-title {
                        margin-top: 11px;
                        font-weight: 700;
                        font-size: 1rem;
                        line-height: 1.25rem;
                        color: #164B6F;
                        position: absolute;
                        width: 100%;
                        text-align: center;
                    }
                    &.active {
                        .cwp-pds-circle {
                            background-color: #56BBCE;
                            color: #FFFFFF;
                        }
                        .cwp-pds-title {
                            color: #56BBCE;
                        }
                    }
                }
            }
            margin-bottom: 35px;
        }

        .cwp-progress-mobile {
            display: block;
            width: 100%;
            text-align: center;
            margin-top: 0px;
            margin-bottom: 36px;
            .cwp-progress-inner {
                width: calc(100vw - 32px);
                display: inline-block;
                text-align: left;
                position: relative;
                .cwp-pm-step-no {
                    font-weight: 700;
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    color: #04203C;
                    position: absolute;
                    left: calc(54px + 16px);
                    top: 9px;
                }
                .cwp-pm-step-title {
                    font-weight: 700;
                    font-size: 1.25rem;
                    line-height: 1.5625rem;
                    color: #56BBCE;
                    position: absolute;
                    left: calc(54px + 16px);
                    top: calc(9px + 20px);
                }
                .cwp-pm-next-step {
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    color: #919191;
                    float: right;
                }
            }
        }

        .cwp-progress-mobile {
            display: none;
        }
        

        @media screen and (max-width: 600px) {
            .cwp-progress-desktop {
                display: none;
            }
            .cwp-skip-to-end-desktop-cont {
                display: none;
            }
            .cwp-progress-mobile {
                display: block;
            }
        }

        .cwp-body {
            .cwp-question-card-cont {
                width: 100%;
                text-align: center;
                .cwp-subheader-1 {
                    color: #292B2C;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 12px;
                }
                .cwp-subheader-2 {
                    color: #292B2C;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: italic;
                    font-weight: 500;
                    line-height: normal;
                }
                .cwp-qc-sub-header {
                    display: inline-block;
                    width: 940px;
                    max-width: calc(100vw - 32px);
                    text-align: left;
                    color: #1226AA;
                    font-family: Poppins;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 143.5%;
                    img {
                        margin-left: 16px;
                        margin-right: 12px;
                    }
                }
                .cwp-question-card {
                    display: inline-block;
                    width: 940px;
                    max-width: calc(100vw - 32px);
                    background: #FFFFFF;
                    box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
                    border-radius: 5px;
                    padding: 20px;
                    text-align: left;
                    .cwp-qc-title {
                        font-weight: 600;
                        font-size: 1.5rem;
                        line-height: 1.875rem;
                        color: #04203C;
                        width: 100%;
                    }
                    .cwp-sub-title {
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1.25rem;
                        color: #04203C;
                        margin-top: 30px;
                    }
                    .cwp-qc-unit-title {
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1.25rem;
                        color: #04203C;
                        margin-top: 30px;
                        margin-bottom: -20px;
                    }
                    .cwp-text-options {
                        margin-top: 30px;
                        .cwp-order-value-cont {
                            display: inline-block;
                            background: #F2F4F5;
                            border: 1px solid #F2F4F5;
                            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 5px;

                            user-select: none;
                            padding: 4px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            height: 60px;
                            overflow-y: hidden;
                            line-height: 1.875rem;
                            position: relative;
                            vertical-align: middle;
                            .cwp-ov-up-arrow, .cwp-ov-down-arrow {
                                width: 28px;
                                height: 28px;
                                position: absolute;
                                top: 16px;
                            }
                            .cwp-ov-up-arrow {
                                left: 8px;
                                margin-right: 8px;
                                &.cwp-ov-first {
                                    opacity: 0.1;
                                    pointer-events: none;
                                }
                                cursor: pointer;
                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                            .cwp-ov-down-arrow {
                                right: 8px;
                                &.cwp-ov-last {
                                    opacity: 0.1;
                                    pointer-events: none;
                                }
                                cursor: pointer;
                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                            .cwp-ov-item {
                                display: inline-block;
                                width: calc(100% - 92px);
                                font-weight: 600;
                                font-size: 0.875rem;
                                line-height: 1.125rem;
                                letter-spacing: 0.013125rem;
                                color: #164B6F;
                                left: 48px;
                                vertical-align: middle;
                                position: relative;
                            }
                        }
                        .cwp-option {
                            display: inline-block;
                            text-align: center;
                            overflow-x: hidden;
                            padding-left: 0px;
                            padding-right: 0px;
                            padding-top: 9px;
                            padding-bottom: 9px;
                            vertical-align: top;

                            background: #F2F4F5;
                            border: 1px solid #F2F4F5;
                            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 5px;

                            font-weight: 600;
                            font-size: 0.875rem;
                            line-height: 1.125rem;
                            letter-spacing: 0.013125rem;
                            color: #164B6F;
                            user-select: none;
                            cursor: pointer;

                            img {
                                margin-bottom: 10px;
                            }

                            &.selected, &.toggled {
                                background: rgba(53, 194, 220, 0.3);
                                border: 1px solid #56BBCE;
                            }
                            &.disabled {
                                opacity: 0.3;
                                pointer-events: none;
                            }
                            &:focus {
                                outline: 2px solid #000000;
                                outline-offset: 2px;
                            }
                        }
                        .cwp-option + .cwp-option {
                            margin-left: 14px;
                        }
                        .cwp-input-cont + .cwp-input-cont {
                            margin-left: 14px;
                        }
                        .cwp-order-value-cont + .cwp-order-value-cont {
                            margin-left: 14px;
                        }
                        .cwp-input-cont {
                            display: inline-block;
                            .cwp-i-label {
                                display: block;
                                font-weight: 400;
                                font-size: 0.875rem;
                                line-height: 1.125rem;
                                letter-spacing: 0.013125rem;
                                color: #131415;
                                width: 100%;
                                margin-bottom: 8px;
                            }
                            .cwp-i-input {
                                padding-top: 9px;
                                padding-bottom: 9px;
                                padding-left: 15px;
                                padding-right: 15px;
                                display: block;
                                background: #F2F4F5;
                                border: 1px solid transparent;
                                border-radius: 5px;
                                width: 100%;
                            }
                            .cwp-i-input-error {
                                border-color: #BF2414;
                            }
                            .cwp-i-input-error-msg {
                                margin-top: 8px;
                                font-weight: 600;
                                font-size: 0.875rem;
                                line-height: 1.125rem;
                                letter-spacing: 0.013125rem;
                                color: #BF2414;
                            }
                        }
                        .cwp-to-row-desktop + .cwp-to-row-desktop {
                            margin-top: 20px;
                        }
                        .cwp-to-row-mobile + .cwp-to-row-mobile {
                            margin-top: 20px;
                        }
                        .cwp-to-row-desktop {
                            display: block;
                        }
                        .cwp-to-row-mobile {
                            display: none;
                        }
                        @media screen and (max-width: 600px) {
                            .cwp-to-row-desktop {
                                display: none;
                            }
                            .cwp-to-row-mobile {
                                display: block;
                            }
                        }
                    }
                }
                margin-top: 20px;
            }
        }

        .cwp-button-cont {
            text-align: center;
            margin-top: 50px;
            height: 75px;
            .cwp-button-cont-inner {
                .cwp-continue-button {
                    display: inline-block;
                    background: #ECCF61;
                    border-radius: 10px;
                    width: 50%;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    font-weight: 700;
                    font-size: 1.25rem;
                    color: #030303;
                    cursor: pointer;
                    user-select: none;
                    pointer-events: none;
                    opacity: 0.5;
                    max-width: 300px;
                }
                .cwp-back-button {
                    display: inline-block;
                    background: transparent;
                    border-radius: 10px;
                    width: 50%;
                    height: 50px;
                    line-height: 50px;
                    text-align: left;
                    font-weight: 700;
                    font-size: 1.25rem;
                    color: #1226AA;
                    cursor: pointer;
                    user-select: none;
                    pointer-events: none;
                    opacity: 0.5;
                    max-width: 300px;
                    padding-left: 16px;
                }
                .enabled {
                    opacity: 1.0;
                    &:hover {
                        opacity: 0.8;
                    }
                    pointer-events: initial;
                }
                .full-size {
                    width: 100%;
                }
                width: 523px;
                max-width: calc(100vw - 32px);
                display: inline-block;
            }
        }
    }
    .cwp-finished {
        text-align: center;
        margin-top: 72px;
        width: 100%;
        img {
            margin-bottom: 40px;
        }
        .cwp-finished-title {
            font-weight: 700;
            font-size: 2.625rem;
            line-height: 53px;
            text-align: center;
            color: #164B6F;
            display: inline-block;
            width: 100vw;
            max-width: calc(100vw - 32px);
            margin-bottom: 28px;
        }
        .cwp-finished-body {
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.25rem;
            text-align: center;
            color: #000000;
            display: inline-block;
            width: 783px;
            max-width: calc(100vw - 32px);
            margin-bottom: 40px;
        }
    }
    @media screen and (max-width: 600px) {
        margin-top: 55px;
        .cwp-finished-title {
            margin-bottom: 22px;
            font-weight: 700;
            font-size: 30px;
            line-height: 2.375rem;
        }
    }
    .cwp-non-footer {
        min-height: calc(100vh - 75px);
    }
    .cwp-footer {
        z-index: 2;
        font-weight: 600;
        font-size: 1rem;
        line-height: 28px;
        color: #FFFFFF;
        height: 74px;
        width: 100vw;
        text-align: center;
        background: #04203C;
        .cwp-fspace {
            height: 9px;
        }
    }
}