$red-danger-dark: #C44343;

.csagent-page-cont {
    .csagent-page-inner {
        width: calc(100% - 32px);
        margin-left: 16px;
        min-height: calc(100vh - 126px) !important;
    }
    .page-card {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.15006);
        padding: 1rem;
        width: 100%;
    }
    input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #CCC;
        border-radius: 4px;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-family: Poppins;
        color: #030C43;
        outline: none;
    }
    .page-title {
        margin-top: 2.5rem;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        font-family: Poppins;
        color: #030C43;
    }
    .sub-title {
        margin-top: 0rem;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        font-family: Poppins;
        color: #030C43;
    }
    .sub-title-1 {
        margin-top: 0.5rem;
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        font-family: Poppins;
        color: #030C43;
    }
    @media screen and (min-width: 979px) {
        .csagent-page-inner {
            position: relative;
            left: 50%;
            margin-left: -470px;
            width: 940px;
            text-align: left;
        }
    }
    .loading {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 200;
        background-color: rgba(31, 31, 31, 0.5);
        .loading-claiming-msg {
            position: absolute;
            width: 100vw;
            text-align: center;
            left: 0px;
            top: calc(50vh + 10vh);
            color: white;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.5rem;
            padding-left: 3.5rem;
            padding-right: 3.5rem;
        }
        img {
            position: absolute;
            width: 10vh;
            height: auto;
            left: calc(50vw - 5vh);
            top: calc(50vh - 5vh);
        }
        &.loading-show {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.25s, visibility 0.01s;
        }
        &.loading-hide {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            transition: opacity 0.25s, visibility 0.25s;
        }
    }
    .signout-from-user-btn {
        cursor: pointer;
        vertical-align: middle;
        padding: 4px 12px;
        color: #FFF;
        text-transform: uppercase;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 5px;
        background: var(--pal-secondary);
        box-shadow: 0px 4px 0px 0px var(--pal-secondary-faded);
        margin-left: 16px;
        &:disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
    .search-results {
        .search-result {
            margin-top: 6px;
            background-color: #F4F4F4;
            padding: 8px;
            border-radius: 4px;

            .search-result-name {
                vertical-align: middle;
                display: inline-block;
                padding-left: 8px;
                font-family: Poppins;
                color: #030C43;
                width: 40%;
            }
            .search-result-email {
                vertical-align: middle;
                display: inline-block;
                font-family: Poppins;
                padding-left: 8px;
                color: #030C43;
                width: 35%;
            }
            .search-result-btn {
                cursor: pointer;
                vertical-align: middle;
                padding: 9px 8px;
                width: 25%;
                color: #FFF;
                text-transform: uppercase;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                border-radius: 5px;
                background: var(--pal-primary);
                box-shadow: 0px 4px 0px 0px var(--pal-primary-faded);
                &:disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }
}

.crp-negative {
    color: #C5271F !important;
}

.sah-signout-from-user {
    border-radius: 5px;
    cursor: pointer;
    margin-left: 16px;
    background: transparent !important;
    color: #E74C3C;
    border: 1px solid #E74C3C !important;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    img {
        margin-left: 0px;
        filter: var(--pal-red-filter) !important;
        width: 15px;
        height: auto;
    }
}

.sah-select-user-dropdown {
    position: absolute;
    z-index: 50;
    width: 100%;
    min-width: 250px;
    left: 0px;
    top: 28px;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 5.5px 0px rgba(0, 0, 0, 0.36);
    padding: 16px;
    text-align: left;
    cursor: initial;
    input {
        margin: 0px;
        border-radius: 10px;
        background: #F0F0F0;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        border: none;
        color: #2D3645;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
    }
    .sr-search-icon {
        position: relative;
        left: calc(100% - 48px);
        top: 28px !important;
    }
    input:focus{
        outline: none;
    }
    .sah-search-results {
        margin-top: 16px;
        width: 100%;
        .search-result + .search-result {
            margin-top: 2px;
        }
        .search-result {
            width: calc(100% + 32px);
            cursor: pointer;
            position: relative;
            padding: 6px;
            padding-left: 16px;
            padding-right: 16px;
            margin-left: -16px;
            margin-right: -16px;
            img.sr-selected-check {
                position: absolute;
                right: 16px;
                top: 12px;
            }
            &:hover {
                background-color: rgba(74, 61, 240, 0.10);               
            }
            .search-result-name {
                margin-top: 4px;
                text-align: left;
                display: block;
                color: #000;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }
            &.sr-selected, &:hover {
                .search-result-name {
                    color: var(--pal-primary);
                }
            }
            .search-result-email {
                text-align: left;
                display: block;
                color: #7A828D;
                font-family: Poppins;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: 214.5%; /* 25.74px */
            }
        }
    }
}

.sah-select-user-dropdown-block {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 49;
    background-color: rgba(0, 0, 0, 0.05);
}

.sa-tools-header-inner {
    width: 100%;
    height: 100%;
    background-color: #EFEFEF;
    text-align: center;
    .sah-signed-in-as {
        display: inline-block;
        color: #292B2C;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 214.5%;
        margin-top: 7px;
    }
    .sah-select-user {
        position: relative;
        display: inline-block;
        border-radius: 20px;
        border: 1px solid #292B2C;
        background: #FFF;
        color: var(--dark-3, #292B2C);
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 214.5%;
        padding-left: 8px;
        padding-right: 32px;
        height: 26px;
        margin-left: 13px;
        margin-top: 7px;
        cursor: pointer;
        img {
            position: absolute;
            right: 10px;
            top: 9px;
            filter: var(--pal-primary-filter) !important;
        }
    }
    &.sa-tools-header-locked {
        background-color: #FCEAE8;        
    }
    &.sa-tools-header-impersonating {
        background-color: #EBF8D1;
        .sah-signout-from-user {
            color: #2A500C !important;
            border: 1px solid #2A500C !important;
            img {
                filter: var(--pal-green-filter) !important;
            }
        }
    }
}