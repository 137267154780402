button.accept-invite-button {
    margin-top: 28px;
    background-color: var(--pal-primary) !important;
    color: white;
    display: inline-block;
    padding: 14px;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 123.688%; /* 19.79px */
    letter-spacing: 0.024px;
    border-radius: 6px;
    cursor: pointer;
}

.loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: rgba(31, 31, 31, 0.5);
    .loading-claiming-msg {
        position: absolute;
        width: 100vw;
        text-align: center;
        left: 0px;
        top: calc(50vh + 10vh);
        color: white;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
    img {
        position: absolute;
        width: 10vh;
        height: auto;
        left: calc(50vw - 5vh);
        top: calc(50vh - 5vh);
    }
    &.loading-show {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.25s, visibility 0.01s;
    }
    &.loading-hide {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: opacity 0.25s, visibility 0.25s;
    }
  }