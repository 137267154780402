.crp-hrt-hide-breakdown {
    .crp-home-rating-button {
        width: calc(100% / 2) !important;
    }
}
.crp-print-cont {
    float: right;
    margin-right: 16px;
}
.crp-env-impact-tab-content-print {
    margin-left: 12px;
    margin-top: 12px;
    zoom: 150%;
    width: 233px;
    text-align: center;
    .rrti-env-impact {
        color: #030C43;
        text-align: center;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.02px;
    }
    .rrti-env-impact-2 {
        color: #292B2C;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .rrti-trees {
        margin-left: 12px;
        width: 205px;
        display: inline-block;
        color: #030C43;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.025px;
        img {
            height: 20px;
            width: auto;
            margin-right: 6px;
            vertical-align: top;
        }
        .rrti-trees-inner {
            text-align: left;
            display: inline-block;
            vertical-align: top;
            width: 180px;
        }
    }
}
.res-meter-cont {
    height: 215px !important;
    &.no-this-home {
        height: 155px !important;
        .crating-units-text {
            margin-top: 20px !important;
        }
    }
    &.res-meter-cont-unknown {
        height: 100px !important;
    }
    position: relative;
    text-align: center;
    .meter-label-unknown-medium {
        color: #7A828D;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.001875rem;
    }
    .meter-label-left {
        vertical-align: top;
        display: inline-block;
        color: #292B2C;
        font-family: Poppins;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: 116.883%; /* 14.026px */
        letter-spacing: 0.01125rem;
        width: 45px;
    }
    .meter-inner-cont {
        display: inline-block;
        width: calc(100% - 96px);
        max-width: 221px;
        margin-left: 3px;
        margin-right: 3px;
        vertical-align: top;
        .meter-img {
            width: 100%;
        }
    }
    .meter-label-right {
        vertical-align: top;
        display: inline-block;
        color: #292B2C;
        font-family: Poppins;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        text-align: right;
        line-height: 116.883%; /* 14.026px */
        letter-spacing: 0.01125rem;
        width: 45px;
    }
    .meter-label-unknown {
        color: #7A828D;
        font-family: Poppins;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: 116.883%; /* 14.026px */
        letter-spacing: 0.01125rem;
    }
    .meter-label-short {
        line-height: 35px;
        font-size: 0.65rem;
    }
}
.crating-bubble-above-left-text,
.crating-bubble-above-right-text,
.crating-bubble-above-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0021875rem;
    text-align: center;
    padding-top: 0.1rem;
    > span {
        display: block;
        margin-top: 0.15rem;
        font-size: 1.25rem;
        line-height: 1rem;
    }
}
.crating-bubble-above-left-text {
    line-height: 0.9rem;
}
.crating-bubble-above-left-text,
.crating-bubble-above-right-text {
    padding-top: 0.4rem;
    > span {
        margin-top: 0.2rem;
    }
}

.crating-bubble-below-text {
    margin-top: 10px;
    color: #292B2C;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0021875rem;
    text-align: center;
    padding-top: 0.1rem;
    > span {
        color: #4A3DF0;
        display: block;
        margin-top: -.45rem;
        font-size: 1.25rem;
    }
}

.crating-units-text {
    margin-top: 80px;
    color: #292B2C;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.001125rem;
    text-align: left;
}