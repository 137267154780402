.cust-notif-bubble-cont {
    background: rgba(248, 172, 75, 0.10);
    padding: 12px 16px;
    width: 100%;
    margin-left: 16px;
    /*max-width: 381px;*/
    display: inline-block;
    text-align: left;
    .cnb-desc {
        color: #2E2E2E;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 147.2%; /* 20.608px */
        white-space: pre-line;
    }
    .cnb-view {
        color: #956100;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 133.932%; /* 18.75px */
        margin-top: 14px;
        cursor: pointer;
    }
    .cnb-title {
        color: #956100;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 100%;
        position: relative;
        .cnbt-notif-icon {
            margin-right: 8px;
        }
        .cnbt-hide {
            position: absolute;
            right: -4px;
            top: -4px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        margin-bottom: 6px;
    }
}