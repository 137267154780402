html {
    font-size: 100% !important;
	letter-spacing: initial !important;
	word-spacing: initial !important;
    body {
        font-family: 'Poppins';
	    font-style: normal;
    }
}

.customer-report-page.report-censored-1 {
    .crp-env-impact-tab-content, .crp-breakdown-tab-content, .res-meter-cont, .meter-select-package, .crp-energy-use-tab-content, .crp-package-rating-meter, .crp-recommendation-text, .crp-read-more-button, .crp-card-header-blue, .crp-ds-updated-date, .cdb-step-checkbox, .crp-helpful-links, .crp-main-title-updated, .crp-up-package-tabs, .crp-cstat-line-1, .crp-cstat-line-2, .crp-incl-upgrades-title, .crp-incl-upgrades-cont, .crp-package-details-btn, .crp-priorities-link, .crp-dp-btn-cont, .crp-priorities-link, .crp-dp-btn-cont, .cdb-dp-checkbox, .btmh-address, .en-level-cont {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .crp-priorities-link, .crp-up-package-tabs, .crp-read-more-button, .cdb-step-checkbox, .crp-helpful-links, .meter-select-package, .crp-dp-btn, .crp-package-details-btn {
        pointer-events: none;
    }
}

.customer-report-page.report-censored-2 {
    .crp-breakdown-tab-content, .crp-package-rating-meter, .crp-recommendation-text, .crp-read-more-button, .crp-card-header-blue, .crp-ds-updated-date, .cdb-step-checkbox, .crp-helpful-links, .crp-main-title-updated, .crp-up-package-tabs, .crp-cstat-line-1, .crp-cstat-line-2, .crp-incl-upgrades-title, .crp-incl-upgrades-cont, .crp-package-details-btn, .crp-priorities-link, .crp-dp-btn-cont, .cdb-dp-checkbox {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .crp-priorities-link, .crp-up-package-tabs, .crp-read-more-button, .cdb-step-checkbox, .crp-helpful-links, .crp-dp-btn, .crp-package-details-btn {
        pointer-events: none;
    }
}

.customer-report-page.report-censored-3 {
    .crp-read-more-button, .crp-ds-updated-date, .cdb-step-checkbox, .crp-helpful-links, .crp-main-title-updated, .crp-priorities-link, .crp-dp-btn-cont, .cdb-dp-checkbox {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .crp-priorities-link, .crp-read-more-button, .cdb-step-checkbox, .crp-helpful-links, .crp-dp-btn {
        pointer-events: none;
    }
}

body.customer-page {
    background: #ffffff !important;
    margin-top: 0px !important;
    overflow-y: scroll !important;
    button {
        outline: none;
        background-color: transparent;
        border: none;
    }
    .ho-back-button {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: 0.001rem;
        color: #0D1840;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
        b {
            color: #0493CF;
        }
        img {
            margin-top: -3px;
            margin-right: 4px;
        }
        margin-top: 16px;
        margin-left: 16px;
        width: 940px;
        max-width: calc(100vw - 32px);
    }
    .hbb-cont {
        text-align: center;
        .ho-back-button {
            display: inline-block;
            text-align: left;
        }
    }
    .customer-report-page {
        height: 100%;
        position: relative;
        .crp-faq-button {
            float: right;
        }
        .crp-notif-wrapper {
            margin-top: 30px;
            text-align: center;
            width: calc(100% - 42px);
        }
        .crp-body {
            position: relative;
        }
        .crp-top-cta {
            border-radius: 10px;
            background: rgba(74, 61, 240, 0.13);
            width: calc(100% - 16px);
            max-width: 400px;
            padding: 13px;
            margin-top: 13px;
            margin-bottom: 26px;
            .crp-tc-1 {
                color: #030C43;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .crp-tc-2 {
                color: #2E2E2E;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 147.2%; /* 20.608px */
            }
            .crp-tc-button {
                border-radius: 5px;
                background: #1CCF79;
                color: #111C4E;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding-top: 5px;
                padding-bottom: 5px;
                width: 100%;
                margin-top: 9px;
                cursor: pointer;
            }
        }
        .crp-header {
            width: 100%;
            height: 70px;
            left: 0px;
            top: 0px;
            background: #ffffff;
            text-align: center;
            box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
            &.left-justify {
				text-align: left;
                a > img {
				    height: 28px;
                    margin-top: 21px;
                    margin-left: 45px;
                }
			}
            a > img {
                margin-top: 7px;
                height: 54px;
            }
            .crp-logout {
				cursor: pointer;
				position: absolute;
				top: 24px;
				right: 24px;
                width: 20px;
                height: 20px;
			}
            .lang-switcher {
                top: 8px;
                right: 50px;
    
                position: absolute;
                display: inline-block;
                box-sizing: border-box;
                border: 1px solid #000;
                border-radius: 7px;
    
                cursor: pointer;
    
                .lang-button {
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.31rem;
                    text-transform: uppercase;
                    height: 33px;
    
                    background-color: #ffffff;
                    color: #1226AA;
                    border-radius: 6px 0px 0px 6px;
                    padding-left: 10px;
                    padding-right: 10px;
                    border: 1px solid black;
                    &.selected {
                        border: 1px solid transparent;
                        background: #1226AA;
                        color: #ffffff;
                    }
                    &:focus {
                        border: 2px solid #030C43;
                        box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                        outline: none;
                        border-radius: 6px 0px 0px 6px;
                    }
                }
    
                .lang-button + .lang-button {
                    border-radius: 0px 6px 6px 0px;
                }
    
            }
            @media screen and (max-width: 550px) {
                .lang-switcher {
                    right: 31px;
                }
            }
        }
        .crp-package-rating-tabs {
            margin-bottom: 0px;
            .crp-home-rating-button {
                vertical-align: top;
                img {
                    height: 26px;
                    margin-right: 8px;
                    margin-top: -4px;
                }
                font-weight: 600;
                font-size: 0.75rem;
                line-height: 118%;
                text-align: center;
                text-transform: uppercase;
                height: 60px;
                width: calc(100% / 2);
                padding-left: 0px;
                padding-right: 0px;
                padding-bottom: 3px;
                &.btn-selected {
                    color: #4A3DF0;
                    border-bottom: 4px solid #4A3DF0;
                    padding-bottom: 0px;
                }
                &.btn-left {

                }
                &.btn-middle {
                    border-left: 1px solid #EAEAEA;
                }
                border-bottom: 1px solid #EAEAEA;
            }
        }
        .crp-main-title-updated {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 16px;
            letter-spacing: 0.001em;
            margin-top: 8px;
            .crp-energuide-verified {
                color: #52C55C;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                float: right;
                margin-right: 16px;
            }
        }
        .crp-main-title {
            text-align: left;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #0D1840;
            margin-top: 30px;
            padding-left: 0px;
            .btmh-address {
                color: #030C43;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                width: calc(100% - 16px);
                margin-top: 7px;
                img {
                    margin-top: -3px;
                }
            }
        }
        .crp-priorities-link {
            border-radius: 5px;
            background: #F6F5F5;
            display: inline-block;
            width: 100%;
            max-width: 358px;
            height: 46px;
            color: #1226AA;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 46px;
            img {
                display: none;
            }
        }
        .crp-info-button {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.18rem;
            letter-spacing: 0.0015rem;
            text-align: center;
            margin-top: 14px;
            margin-left: 10px;
            cursor: pointer;
            span {
                /*text-decoration-line: underline;*/
                margin-left: 3px;
            }
            color: #0493CF;
        }
        .crp-info-button-desktop {
            display: none;
        }
        .crp-info-modal {
            .crp-im-title {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.43rem;
                letter-spacing: 0.0015rem;
                color: #000000;
                margin-bottom: 5px;
            }
            .crp-im-description {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.5rem;
                letter-spacing: 0.005rem;
                color: #000000;
                margin-bottom: 10px;
            }
            .crp-im-cta {
                background: #EDEDED;
                height: 119px;
                width: 100vw;
                max-width: 545px;
                margin-left: -38px;
                padding: 20px;
                padding-left: 38px;
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.625rem;
                letter-spacing: 0.0015rem;
                color: #000000;
                position: absolute;
                bottom: 0px;
                .crp-im-cta-link {
                    display: inline;
                    color: #0493CF;
                    /*text-decoration-line: underline;*/
                    cursor: pointer;
                }
            }
        }
        .res-meter-cont {
            height: 156px;
            position: relative;
            overflow-y: hidden;
            width: 100%;
            .meter-bg {
                position: absolute;
                left: 50%;
                top: 2px;
                margin-left: -125px;
                z-index: 1;
            }
            .meter-pointer {
                position: absolute;
                left: 50%;
                top: 2px;
                margin-left: -100px;
                margin-top: -6px;
                z-index: 2;
                width: 200px;
                height: 200px;
                transform: rotate(0deg);
                transition: transform 0.5s;
            }
            .meter-label {
                font-weight: 500;
                font-size: 1.35rem;
                line-height: 1.78rem;
                text-align: center;
                margin-top: 118px;
            }
        }
        .meter-select-package {
            height: 90px;
            width: 100%;
            .cust-dropdown-button {
                width: calc(100% - 40px);
                margin-left: 20px;
            }
            .crp-select-package-label {
                text-align: center;
            }
            border-bottom: 1px solid #EAEAEA;
        }
        @media screen and (min-width: 979px) {
            .crp-info-button-desktop {
                display: block;
                width: 100%;
                text-align: center;
                position: relative;
                .crp-info-button {
                    position: relative;
                    display: inline-block;
                    width: 940px;
                    max-width: calc(100vw - 32px);
                    text-align: right;
                    top: -30px;
                    cursor: default;
                    img, span {
                        cursor: pointer;
                    }
                }
            }
            .crp-info-button-mobile {
                display: none;
            }
            .crp-helpful-links-header {
                margin-left: calc(50% - 470px);
            }
            .crp-helpful-links {
                width: 940px;
                margin-left: calc(50% - 470px);
            }
        }
        .crp-title-green {
            font-weight: 500;
            font-size: 1rem;
            text-align: center;
            letter-spacing: 0.0025rem;
            color: #659540;
            display: block;
            width: 100%;
            margin-bottom: 18px;
            margin-top: 40px;
        }
        .crp-title-blue {
            font-weight: 500;
            font-size: 1rem;
            text-align: center;
            letter-spacing: 0.0025rem;
            color: #164B6F;
            display: block;
            width: 100%;
            margin-bottom: 18px;
            margin-top: 20px;
        }
        @media screen and (min-width: 979px) {
            .crp-main-title, .crp-notif-wrapper {
                position: relative;
                left: 50%;
                margin-left: -470px;
                width: 940px;
                text-align: left;
                .cust-notif-bubble-cont {
                    margin-left: 0px;
                }
            }
            .crp-title-blue, .crp-title-green {
                width: 940px;
            }
        }
        @media screen and (max-width: 979px) {
            .crp-main-title {
                padding-left: 16px;
            }
        }
    }
    .cdb-package-modal {
        .cdp-tabs {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: -9px;
            .cdp-tab {
                flex: 1;
                text-transform: uppercase;
                height: 48px;
                line-height: 48px;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                &.cdp-tab-selected {
                    color: #4A3DF0;
                    border-bottom: 4px solid #4A3DF0;
                }
            }
            .cdp-tab + .cdp-tab {
                border-left: 1px solid #EAEAEA;
            }
            border-bottom: 1px solid #EAEAEA;
        }
        .cdp-ba-cont {
            width: 100%;
            display: flex;
            .cdp-ba {
                flex: 1;
                text-transform: uppercase;
                height: 36px;
                padding: 5px 9px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                color: #4A3DF0;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                border-radius: 5px;
                background: #F6F5F5;
                &.cdp-ba-selected {
                    color: #FFF;
                    background: #4A3DF0;
                }
                margin-top: 18px;
            }
            .cdp-ba + .cdp-ba {
                margin-left: 8px;
            }
            margin-bottom: 17px;
        }
        .cdp-card {
            width: 100%;
            padding: 11px;
            border-radius: 5px;
            background: #FFF;
            box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.10);
            margin-top: 20px;
        }
        .cdp-card + .cdp-card {
            margin-top: 20px;
        }
        .cdp-title {
            color: #0D1840;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            white-space: pre;
            &.cdp-title-space {
                margin-top: 15px;
            }
        }
        .cdp-sub-title {
            color: #1F2631;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 137.188%; /* 21.95px */
            letter-spacing: 0.08px;
        }
        .meter-select-package {
            margin-top: 16px;
            padding-bottom: 0px;
            border: none;
        }
        .cdp-below-title {
            color: #1F2631;
            font-family: Poppins;
            font-size: 0.95rem;
            font-style: normal;
            font-weight: 400;
            line-height: 137.188%;
            letter-spacing: 0.08px;
        }
        .crp-vcb-btn {
            color: #2A47CA;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 46px;
            text-transform: uppercase;
            border-top: 1px solid rgba(122,130,141,0.34);
            height: 36px;
            width: 100%;
            margin-top: -8px;
        }
        .crp-vcb-block + .crp-vcb-block {
            border-top: 1px solid rgba(122,130,141,0.34);
            margin-top: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .crp-rebate-warning {
            background: rgba(248, 172, 75, 0.10);
            padding: 12px;
            margin-bottom: 16px;
            border-radius: 2px;
            b {
                color: #C97B18;
                font-family: Poppins;
                font-size: 0.8125rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                display: block;
            }
            @media screen and (max-width: 598px) {
                b {
                    display: inline !important;
                    margin-right: 4px;
                }
            }
            color: #292B2C;
            font-family: Poppins;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: left;
        }
        .crp-vcb-block {
            margin-top: 20px;
			.crp-vcb-1, .crp-vcb-2 {
                .crp-vcb-title {
                    display: inline-block;
                    position: relative;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;
                    width: 50%;
                    top: -6px;
                }
                .crp-vcb-title-number {
                    display: inline-block;
                    font-family: Poppins;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 36px;
                    text-transform: uppercase;
                    text-align: right;
                    width: 50%;
                }
                margin-bottom: 15px;
            }
            .crp-vcb-1 {
                color: #C5271F;
                .crp-vcb-title {
                    display: inline-block;
                    position: relative;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;
                    width: 50%;
                    top: -6px;
                }
                .crp-vcb-title-number {
                    display: inline-block;
                    font-family: Poppins;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 36px;
                    text-transform: uppercase;
                    text-align: right;
                    width: 50%;
                }
            }
            .crp-vcb-2 {
                color: #367B3D;
                .crp-vcb-title {
                    display: inline-block;
                    position: relative;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;
                    width: 50%;
                }
                .crp-vcb-title-number {
                    display: inline-block;
                    font-family: Poppins;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 36px;
                    text-transform: uppercase;
                    text-align: right;
                    width: 50%;
                }
            }
            .crp-vcb-stat-checkable {
                margin-top: 8px;
                padding-top: 14px;
                padding-bottom: 2px;
                border-top: 1px solid rgba(48, 51, 55, 0.22);
                .crp-vcb-stat-checkbox {
                    display: inline-block;
                    cursor: pointer;
                    margin-right: 6px;
                    margin-top: -5px;
                }
                .crp-vcb-stat-title {
                    display: inline-block;
                    color: #1F2631;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.024px;
                    width: calc(70% - 36px);
                    vertical-align: top;
                }
                .crp-vcb-stat-number {
                    text-align: right;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.024px;
                    display: inline-block;
                    width: 30%;
                    text-align: right;
                    vertical-align: top;
                    color: #1F2631;
                }
                .crp-vcb-stat-link {
                    margin-top: 4px;
                    padding-left: 36px;
                    display: block;
                    font-size: 0.9rem;
                    color: #4A3DF0;
                    font-weight: 700;
                    margin-bottom: -8px;
                }
            }
            .crp-vcb-stat {
                margin-top: 8px;
                .crp-vcb-stat-title {
                    display: inline-block;
                    color: #1F2631;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.024px;
                    width: 70%;
                    vertical-align: top;
                }
                .crp-vcb-stat-number {
                    text-align: right;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.024px;
                    display: inline-block;
                    width: 30%;
                    text-align: right;
                    vertical-align: top;
                    color: #1F2631;
                }
            }
        }
        .cdp-gen-comments-button {
            display: block;
            color: #0D1840;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            width: 100%;
            img {
                float: right;
                margin-top: 3px;
            }
        }
        .cdp-gen-comments {
            margin-top: 11px;
            color: #1F2631;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 128.188%; /* 20.51px */
            letter-spacing: 0.08px;
        }
        .cdp-rec-cont-type-2 {
            border-radius: 5px;
            border: 1px solid #DDD;
            background: #FFF;
            margin-top: 14px;
            padding: 8px;
            img {
                display: inline-block;
                vertical-align: top;
                width: 80px;
                margin-top: -4px;
                margin-right: 5px;
            }
            .cdp-rec-right {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 90px);
                .cdp-rec-name {
                    color: #1226AA;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .cdp-rec-summary {
                    color: #1F2631;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 128.188%; /* 20.51px */
                    letter-spacing: 0.08px;
                }
            }
            .cdp-rec-helpful-links-cont {
                border-top: 1px solid rgba(122, 130, 141, 0.34);
                margin-top: 8px;
                .crp-hlink-small {
                    display: block;
                    border-radius: 5px;
                    border: 1px solid #F2F4F5;
                    background: #F2F4F5;
                    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
                    padding: 10px;
                    margin-top: 12px;
                    .crp-hls-img {
                        display: inline-block;
                        width: 52px;
                        height: 52px;
                        border-radius: 5px;
                        background-size: auto 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        vertical-align: top;
                    }
                    .crp-hl-title {
                        display: inline-block;
                        color: #292B2C;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 116%; /* 18.56px */
                        letter-spacing: 0.024px;
                        vertical-align: middle;
                        margin-left: 9px;
                        width: calc(100% - 52px - 10px);
                    }
                }
            }
            .cdp-rec-comments-button {
                display: block;
                color: #2A47CA;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                text-transform: uppercase;
                font-weight: 600;
                line-height: normal;
                text-align: left;
                width: 100%;
                padding-top: 14px;
                padding-bottom: 3px;
                margin-top: 14px;
                border-top: 1px solid rgba(122, 130, 141, 0.34);
                img {
                    float: right;
                    margin-top: 0px;
                    width: 24px;
                    height: 24px;
                    filter: brightness(800%) hue-rotate(229.1) saturate(79.2);
                }
            }
            .cdp-rec-comments {
                color: #1F2631;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 128.188%;
                letter-spacing: 0.08px;
            }
            .cdp-rec-data {
                margin-top: 11px;
                margin-left: 4px;
                h4 {
                    color: #292B2C;
                    font-family: Poppins;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .cdp-rec-data-row {
                    height: 1.35rem;
                    position: relative;
                    .cdp-rec-data-title {
                        width: calc(100% - 4px);
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        color: #292B2C;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 160%; /* 25.6px */
                    }
                    .cdp-rec-data-value {
                        width: calc(100% - 4px);
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        color: #292B2C;
                        text-align: right;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%; /* 25.6px */
                    }
                }
            }
        }
        .cdp-rec-cont {
            margin-top: 14px;
            img {
                display: inline-block;
                vertical-align: top;
                width: 85px;
            }
            .cdp-rec-right {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 90px);
                .cdp-rec-name {
                    color: #1226AA;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .cdp-rec-summary {
                    color: #1F2631;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 128.188%; /* 20.51px */
                    letter-spacing: 0.08px;
                }
            }
        }
        .cstat-20-year-savings {
            color: #367B3D;
        }
        .crp-cstat-line-2 {
            text-align: center;
        }
        .cstat-cost-after-rebates {
            color: #C5271F;
        }
        .cstat-block { 
            box-shadow: none;
            border-radius: 0px;
        }
        .cstat-em-eng {
            .cstb-1 {
                margin-right: 0px;
            }
            .cstb-0, .cstb-1, .cstb-2 {
                display: block;
                width: 100%;
                text-align: center;
            }
        }
        .cstat-block + .cstat-block {
            border-left: 1px solid rgba(122, 130, 141, 0.34);
            margin-left: 0px;
        }
        .highcharts-container {
            margin-top: 18px;
            * {
                font-family: Poppins !important;
            }
        }
        .cdp-highcharts-cont-bar {
            .highcharts-container {
                margin-top: -30px;
            }
        }
        .highcharts-credits {
            display: none !important;
        }
        .cdp-highcharts-cont {
            height: 250px;
        }
        .crp-cstat-line-1, .crp-cstat-line-2 {
            display: block;
            width: 100%;
            margin-bottom: 12px;
        }
        .cstb-1 {
            font-size: 2.25rem;
        }
        .cstb-2 {
            text-align: center;
        }
        .crp-cstat-line-2 {
            .cstat-block {
                width: calc(50% - 6px);
            }
            .cstat-block + .cstat-block {
                margin-left: 12px;
            }
            margin-bottom: 20px;
        }
        .crp-em-eng-savings {
            width: 100%;
            margin-top: 8px;
            border-top: 1px solid rgba(122, 130, 141, 0.34);
            text-align: center;
            color: #4A3DF0;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: 0.06px;
            height: 39px;
            padding-top: 15px;
            padding-bottom: 29px;
            .crp-em-eng-savings-left-text {
                display: inline-block;
                color: #292B2C;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 121.7%;
                text-transform: uppercase;
                margin-right: 13px;
                position: relative;
                top: -4px;
            }
        }
        .crp-iui-reco-stat {
            color: #1F2631;
            text-align: left;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.024px;
            padding-top: 12px;
            padding-bottom: 12px;
            .crp-reco-stat-dot {
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border-radius: 1rem;
                margin-right: 6px;
                top: 2px;
                position: relative;
                vertical-align: top;
            }
            .crp-reco-stat-name {
                display: inline-block;
                width: calc(70% - 1rem - 8px);
                text-align: left;
                vertical-align: top;
            }
            .crp-reco-stat-value {
                display: inline-block;
                width: 30%;
                text-align: right;
                vertical-align: top;
            }
        }
        .crp-iui-reco-stat + .crp-iui-reco-stat {
            border-top: 1px solid rgba(122, 130, 141, 0.34);
        }
        .cdp-trees-card {
            margin-top: 20px;
            border-radius: 5px;
            background: #e6faf0;
            width: 100%;
            padding: 13px;
            text-align: center;
            .cdp-trees-top {
                .cdp-trees-top-num {
                    display: inline-block;
                    color: #367B3D;
                    font-family: Poppins;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 50px;
                    text-transform: uppercase;
                    margin-right: 10px;
                    margin-top: 5px;
                    position: relative;
                }
                .cdp-trees-top-2 {
                    display: inline-block;
                    color: #367B3D;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    text-transform: uppercase;
                    position: relative;
                    top: -8px;
                }
            }
            .cdp-trees-bottom {
                img {
                    vertical-align: top;
                    display: inline-block;
                }
                .cdp-trees-bottom-2 {
                    vertical-align: top;
                    display: inline-block;
                    color: #292B2C;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.027px;
                    width: calc(100% - 56px);
                    margin-top: 5px;
                    text-align: left;
                }
            }
        }
    }
    .cdb-share-modal-para {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.013671875rem;
        color: #292B2C;
    }
    .cdb-share-energuide-check-right {
        display: inline-block;
        width: calc(100% - 42px);
        margin-left: 16px;
    }
    .cdb-accept-checkbox {
        margin-top: 10px;
        text-align: left;
        > img {
            vertical-align: top;
        }
        .cdb-accept-checkbox-label {
            vertical-align: top;
            display: inline-block;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: 0.013671875rem;
            width: calc(100% - 40px);
            margin-left: 11px;
        }
    }
    .cdb-accept-buttons-block {
        width: calc(100% - 32px);
        height: 90px;
    }
    button.cdb-sem-download {
        border-radius: 5px;
        background: #F6F5F5;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 11px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin-top: 13px;
    }
    .cdb-sem-upload-cont {
        border-radius: 10px;
        border: 1px dashed #1226AA;
        background: var(--White, #FFF);
        width: 100%;
        &.upload-error {
            border: 1px dashed #E74C3C;
        }
		img {
            display: inline-block;
            margin-top: 13px;
            width: 46px;
            height: 89px;
            margin-bottom: 13px;
            margin-left: 42px;
        }
		.cdb-sem-upload-right {
            vertical-align: middle;
            display: inline-block;
            margin-left: 16px;
            width: calc(100% - 120px);
			.cdb-sem-upload-title {
                color: #1226AA;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 136.188%;
                letter-spacing: 0.0015rem;
                margin-left: 0px;
                width: calc(100%);
            }
			.cdb-sem-upload-subtitle {
                margin-left: 0px;
                color: #000;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 144.187%; /* 20.186px */
                letter-spacing: 0.013125rem;
                width: calc(100%);
            }
        }
    }
    .cdb-sem-error {
        margin-top: 13px;
        margin-bottom: 13px;
        img {
            margin-top: -4px;
        }
        color: #F53611;
        font-kerning: none;
        font-feature-settings: 'clig' off, 'calt' off, 'liga' off;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
    .cdb-sem-info {
        color: #727272;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .cdb-sem-info-2 {
        margin-top: 27px;
        margin-bottom: 13px;
        color: #292B2C;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.005rem;
    }
    .cdb-sem-file {
        border-radius: 5px;
        background: #EDEDED;
        padding: 11px;
        > img {

        }
        a.cdb-sem-file-link {
            color: #4A3DF0;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.000875rem;
        }
        button.cdb-sem-file-delete-btn {
            float: right;
            margin-right: 4px;
            > img {

            }
        }
    }
    .cdb-update-success-msg {
        margin-top: 18px;
        margin-bottom: 16px;
        img {
            vertical-align: top;
            margin-right: 8px;
        }
        .cdb-update-success-msg-inner {
            vertical-align: top;
            width: calc(100% - 40px);
            display: inline-block;
            color: #727272;
            font-family: Poppins;
            font-size: 12px;
            font-style: italic;
            font-weight: 500;
            line-height: normal;
        }
    }
    .cdb-accept-buttons {
        position: absolute;
        width: 100%;
        bottom: 0px;
        border-top: 1px solid #EDEDED;
        padding: 17px;
        .cdb-accept-done-btn {
            border-radius: 10px;
            background: #1CCF79;
            box-shadow: 0px 4px 2px 0px #11995E;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.41rem;
            letter-spacing: 0.0125rem;
            color: #030303;
            height: 44px;
            width: 100%;
            margin-left: -16px;
            &:hover {
                opacity: 0.6;
            }
            &.disabled {
                opacity: 0.3;
                pointer-events: none;
            }
        }
        .cdb-accept-cancel-btn {
            background: rgba(28, 207, 121, 0.12);
            border-radius: 10px;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.41rem;
            letter-spacing: 0.0125rem;
            color: #030303;
            height: 44px;
            width: calc(50% - 10px);
            max-width: 180px;
            float: right;
            &:hover {
                opacity: 0.6;
            }
            margin-left: 20px;
        }
    }
    .cdb-step-para {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.013671875rem;
        color: #292B2C;
        margin-left: 16px;
        width: calc(100% - 32px);
        margin-bottom: 16px
    }
    .cdb-dp-checkbox + .cdb-dp-checkbox {
        margin-top: 12px;
    }
    .cdb-dp-checkbox {
        display: block;
        margin-bottom: 10px;
        padding-left: 0px;
        width: 100%;
        > img {
            width: 24px;
            height: 24px;
            margin-left: 0px;
            margin-top: -2px;
        }
        .cdb-dp-check-title {
            display: inline-block;
            margin-left: 5px;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: 0.013671875rem;
            color: #292B2C;
            white-space: pre;
            width: 200px;
        }
    }
    .cdb-step-checkbox {
        margin-bottom: 10px;
        padding-left: 0px;
        > img {
            width: 24px;
            height: 24px;
            margin-left: 0px;
            margin-top: -2px;
        }
        &.pending {
            height: 36px;
        }
        .cdb-more-info-icon {
            width: 20px;
            height: 20px;
            margin-right: -8px;
        }
        .cdb-step-check-title {
            display: inline-block;
            margin-left: 5px;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: 0.013671875rem;
            color: #292B2C;
            white-space: pre;
        }
        .cdb-step-checkbox-button {
            float: right;
            font-weight: 600;
            font-size: 1rem;
            line-height: 123.69%;
            letter-spacing: 0.0015rem;
            color: #4A3DF0;
            text-align: right;
            padding: 0;
            margin-right: 0px;
            &.pending {
                color: #F39C12;
                margin-right: 16px;
                pointer-events: none;
                display: block;
                position: absolute;
                text-align: right;
                left: 42px;
            }
            &.cdb-more-info {
                margin-right: 12px;
            }
            > img {
                margin-left: 2px;
                height: 12px;
                margin-top: -3px;
            }
        }
    }
    .crp-up-package-tabs {
        margin-bottom: 22px;
        .crp-up-package-button {
            vertical-align: top;
            img {
                height: 22px;
                margin-bottom: 8px;
            }
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 118%;
            text-align: center;
            text-transform: uppercase;
            height: 72px;
            width: calc(100% / 3);
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 3px;
            white-space: pre;
            &.btn-selected {
                color: #4A3DF0;
                border-bottom: 4px solid #4A3DF0;
                padding-bottom: 0px;
            }
            &.btn-left {

            }
            &.btn-middle {
                border-left: 1px solid #EAEAEA;
            }
            &.btn-right {
                border-left: 1px solid #EAEAEA;
            }
            border-bottom: 1px solid #EAEAEA;
        }
    }
    .crp-helpful-links-header {
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #292B2C;
        margin-left: 16px;
        margin-top: 24px;
        &.crp-hl-modal {
            margin-left: 0px;
        }
        &.crp-next-steps {
            font-size: 1.1rem;
            position: absolute;
            bottom: calc(265px + 85px);
            max-width: 100vw;
            left: -12px;
            height: 42px;
            background-color: transparent;
            background-image: url('/public/images/next-steps-banner.svg');
            background-size: 100% 42px;
            padding-right: 20px;
            line-height: 42px;
            text-indent: 25px;
            z-index: 2;
            padding-top: 0px;
            padding-bottom: 0px;
            border: none;
            img {
                position: absolute;
                z-index: 1;
                left: 0px;
                top: 38px;
                width: 12px;
            }
        }
    }
    .cdb-share-optional-icon {
        vertical-align: top;
        display: inline-block;
        margin-left: 6px;
        margin-top: 20px;
    }
    .cdb-share-optional {
        vertical-align: top;
        display: inline-block;
        font-weight: 500;
        font-size: 1rem;
        line-height: 137%;
        color: #2E2E2E;
        width: calc(100% - 64px);
        margin-left: 12px;
        margin-top: 20px;
    }
    .crp-intro-modal-cont {
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
        .cdb-dont-show-again {
            width: 300px;

            margin-left: -23px;
        }
		.crp-im-img {
            height: 212px;
        }
        .crp-im-top {
            .crp-im-step-no {
                font-weight: 400;
                font-size: 1rem;
                line-height: 24px;
                color: #2E2E2E;
                float: left;
            }
            .crp-im-skip {
                font-weight: 500;
                font-size: 1rem;
                line-height: 24px;
                color: #4A3DF0;
                float: right;
            }
            margin-top: 30px;
            height: 40px;
        }
        .crp-im-title {
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.0025em;
            color: #030C43;
        }
        .crp-im-desc {
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            color: #2E2E2E;
            text-align: left;
            white-space: pre-line;
            width: 100%;
            margin-top: 22px;
            margin-bottom: 125px;
        }
        .crp-im-bottom {
            margin-top: 40px;
            display: inline-block;
            position: absolute;
            bottom: 32px;
            width: 100%;
            left: 0px;
            .crp-im-back {
                font-weight: 600;
                font-size: 1rem;
                line-height: 123.69%;
                text-align: center;
                letter-spacing: 0.0015em;
                color: #1226AA;
                padding: 12px;
                width: 125px;
                border-radius: 10px;
                &.disabled {
                    opacity: 0.0;
                    pointer-events: none;
                }
            }
            .crp-im-next {
                margin-left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 123.69%;
                text-align: center;
                letter-spacing: 0.0015em;
                color: #ffffff;
                background: #1226AA;
                border-radius: 10px;
                padding: 12px;
                width: 125px;
            }
        }
    }
    .crp-helpful-links {
        display: flex;
        height: 300px;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-left: 16px;
        margin-top: 16px;
        width: calc(100% - 32px);
        margin-bottom: -30px;
        &.crp-next-steps {
            border: 6px solid rgba(28, 207, 121, 0.40);
            padding: 1rem;
            padding-top: 74px;
            border-radius: 5px;
            height: 385px;
        }
        &.crp-hl-modal {
            margin-bottom: 0px;
            width: 100%;
            margin-left: 0px;
        }
        .crp-hlink {
            margin: 0;
            padding: 0px !important;
            vertical-align: top;
            box-sizing: border-box;
            cursor: pointer;
            background: #FFFFFF;
            box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
            border-radius: 5px;
            width: 282px;
            height: 274px;
            position: relative;
            .crp-hl-banner {
                width: 282px;
                height: 108px;
                display: block;
                margin-top: 0px;
                background-size: cover;
                background-color: #f0f0f0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
            .crp-hl-title {
                font-weight: 600;
                font-size: 1rem;
                line-height: 22px;
                letter-spacing: 0.0015em;
                color: #292B2C;
                margin-top: 12px;
                margin-left: 13.15px;
            }
            .crp-hl-para {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 137.2%;
                letter-spacing: 0.005em;
                color: #000000;
                margin-left: 13.15px;
                width: calc(100% - 26.3px);
                margin-top: 7px;
            }
        }
        .crp-hlink + .crp-hlink {
            margin-left: 16px;
        }
    }
    .crp-datasources-cont {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        .crp-datasources {
            padding: 13px;
            display: inline-block;
            width: 940px;
            max-width: calc(100vw - 32px);
            background: #FFFFFF;
            box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
            border-radius: 5px;
            color: #1F2631;
            text-align: left;
            position: relative;
            .crp-ds-title {
                font-family: Poppins;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }   
            .crp-ds-updated-date {
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 1.125rem;
                margin-bottom: 10px;
            }
            .crp-ds-copy {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                margin-bottom: 12px;
            }
            .faq-li {
                margin-top: 4px;
                .faq-lib {
                    color: #1226AA;
                    font-weight: bold;
                    display: inline-block;
                    font-size: 1.5rem;
                    vertical-align: top;
                    line-height: 1.5rem;
                }
                .faq-lit {
                    vertical-align: top;
                    margin-left: 0.5rem;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 149.19%;
                    letter-spacing: 0.005rem;
                    color: #000000;
                    display: inline-block;
                    width: calc(100% - 1.5rem);
                }
            }
        }
    }
    .crp-modal-cont {
        position: fixed;
        z-index: 20;
        background-color: rgba(31, 31, 31, 0.5);
        top: 0px;
        left: 0px;
        width: 100%;
        min-height: 100%;
        height: auto;
        overflow-y: scroll;
        .crp-modal, .cnp-modal {
            position: absolute;
            cursor: initial;
            width: 598px;
            margin-left: calc(50% - 299px);
            margin-top: 129px;
            border-radius: 16px;
            background-color: white;
            box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.15006);
            z-index: 5;
            padding: 16px;
            .crp-modal-header {
                .crp-modal-title {
                    display: inline-block;
                    font-weight: 600;
                    font-size: 1.25rem;
                    line-height: 1.9rem;
                    letter-spacing: 0.0015rem;
                    color: #000000;
                    width: calc(100% - 48px);
                    vertical-align: top;
                }
                button {
                    position: relative;
                    width: 16px;
                    height: 16px;
                    vertical-align: top;
                    margin-top: 6px;
                    cursor: pointer;
                    margin-left: 20px;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-top: -6px;
                    }
                }
                margin-bottom: 9px;
                border-bottom: 1px solid #EAEAEA;
                padding-bottom: 16px;
            }
            .crp-modal-body {

            }
        }
    }
    @media screen and (max-width: 598px) {
        .crp-modal-cont {
            .crp-modal {
                margin-top: 0px;
                width: 100%;
                margin-left: 0px;
                min-height: 100vh;
            }
            .cnp-modal {
                margin-top: 10vh;
                width: calc(100% - 32px);
                margin-left: 16px;
                min-height: 40vh;
            }
            &.crp-modal-fullscreen {
                .cnp-modal {
                    width: 100%;
                    min-height: 100vh;
                    left: 0px;
                    top: 0px;
                    margin-top: 0px;
                    margin-left: 0px;
                    border-radius: 0px;
                }
            }
        }
    }
    .crp-select-package-label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.0025em;
        color: #030C43;
        text-align: left;
        margin-top: 8px;
        margin-bottom: 6px;
    }
    .crp-savings-desc {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.31rem;
        letter-spacing: 0.005rem;
        color: #1F2631;
    }
    .crp-savings-row {
        height: 46px;
        border-bottom: 1px solid #EDEDED;
        width: 100%;
        .crp-savings-title, .crp-savings-value {
            font-weight: 500;
            font-size: 0.9375rem;
            line-height: 1.18rem;
            letter-spacing: 0.0015rem;
            color: #1F2631;
            line-height: 2.875rem;
        }
        .crp-savings-title {
            float: left;
        }
        .crp-savings-value {
            float: right;
        }
        .crp-savings-unavailable, .crp-savings-unavailable-1 {
            position: absolute;
            font-size: 0.9375rem;
            line-height: 0.9375rem;
            display: inline-block;
            width: 106px;
            margin-top: 6px;
            right: 0px;
        }
        .crp-savings-unavailable-1 {
            line-height: 2.5rem;
        }
    }
    .crp-unavailable-warning {
        font-weight: 500;
        font-size: 0.9375rem;
        line-height: 1.18rem;
        letter-spacing: 0.0015rem;
        color: #1F2631;
        line-height: 2.875rem;
        font-style: italic;
    }
    .crp-savings-trees {
        margin-top: 48px;
        background: rgba(4, 147, 207, 0.1);
        border-radius: 5px;
        font-weight: 500;
        font-size: 1rem;
        width: 100%;
        height: 68px;
        line-height: 4.25rem;
        letter-spacing: 0.0015rem;
        color: #1F2631;
        img {
            margin-left: 18px;
        }
    }
    .crp-comment {
        margin-top: 30px;
        .crp-comment-header {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.6875rem;
            letter-spacing: 0.0015rem;
            color: #000000;
            margin-bottom: 11px;
        }
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.005rem;
        color: #000000;
    }
    .crp-rec-body {
        .crp-recommendation-text {
            display: inline-block;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.75rem;
            letter-spacing: 0.005rem;
            color: #1F2631;
            width: auto;
        }
        margin-top: 19px;
        margin-bottom: 12px;
    }
    .crp-cta-button-cont {
        margin-top: -5px;
    }
    .crp-cta-button {
        background: #0493CF;
        box-shadow: 0px 4px #02437F;
        border-radius: 10px;
        text-transform: uppercase;
        padding: 17px;
        font-weight: 500;
        width: calc(100% - 26px);
        max-width: 400px;
        font-size: 1.0rem !important;
        line-height: 123.69%;
        text-align: center;
        letter-spacing: 0.0015rem;
        color: #FFFFFF;
        display: inline-block;
        margin-left: 30px;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
        &:focus {
            outline: 2px solid white;
            outline-offset: 2px;
        }
    }
    @media screen and (min-width: 801px) {
        .crp-non-footer {
            min-height: calc(100vh - 200px);
        }
        .crp-cta-button {
            margin-left: 0px;
            font-size: 0.8125rem;
        }
        .cta-float-text {
            margin-right: 32px;
        }
        .cta-float {
            position: fixed;
            bottom: 0px;
            z-index: 2;
            opacity: 0;
            display: none;
            &.cta-float-show {
                opacity: 1;
                display: block;
                transition: opacity 0.25;
            }
            transition: opacity 0.25;
            height: 73px !important;
            .crp-cta-button {
                margin-top: 12px;
                margin-left: 0px;
            }
        }
        .crp-cta-button {
            margin-top: 8px;
            margin-left: -6px
        }
        .crp-footer {
            .crp-cta {
                background: #EDEDED;
                width: 100%;
                height: 73px;
                font-weight: 600;
                font-size: 1.075rem;
                line-height: 3.4375rem;
                text-align: center;
                letter-spacing: 0.0025rem;
                color: #0D1840;
            }
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.75rem;
            color: #FFFFFF;
            height: 150px;
            width: 100vw;
            text-align: center;
            background: #04203C;
            .crp-fspace {
                height: 9px;
            }
            &.crp-footer-no-cta {
                .crp-cta {
                    display: none;
                }
                height: calc(200px - 73px);
            }
        }
    }
    @media screen and (max-width: 800px) {
        .crp-non-footer {
            min-height: calc(100vh - 200px);
        }
        .crp-cta-button {
            margin-left: 0px;
            font-size: 0.8125rem;
        }
        .cta-float {
            position: fixed;
            bottom: 0px;
            z-index: 2;
            opacity: 0;
            display: none;
            &.cta-float-show {
                opacity: 1;
                display: block;
                transition: opacity 0.25;
            }
            .cta-float-text {
                display: none;
            }
            transition: opacity 0.25;
            height: 73px !important;
            .crp-cta-button {
                margin-top: 12px;
            }
        }
        .crp-footer {
            .crp-cta {
                background: #EDEDED;
                width: 100%;
                height: 127px;
                font-weight: 600;
                font-size: 1.075rem;
                line-height: 3.4375rem;
                text-align: center;
                letter-spacing: 0.0025rem;
                color: #0D1840;
            }
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.75rem;
            color: #FFFFFF;
            height: 200px;
            width: 100vw;
            text-align: center;
            background: #04203C;
            .crp-fspace {
                height: 9px;
            }
            &.crp-footer-no-cta {
                .crp-cta {
                    display: none;
                }
                height: calc(200px - 127px);
            }
        }
        .cta-float-text {
            display: block;
        }
    }

    .crp-info-modal {
        .faq-row {
            margin-top: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
            border-bottom: 1px solid #EAEAEA;

            .faq-row-title {
                cursor: pointer;
                &.faq-expanded {
                    .fr-text {
                        color: #4A3DF0;
                    }
                }
                .fr-text {
                    display: inline-block;
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    letter-spacing: 0.0015rem;
                    color: #030C43;
                    width: calc(100% - 32px);
                    vertical-align: top;
                }
                img {
                    vertical-align: top;
                }
            }
            .faq-desc {
                display: none;
                &.faq-expanded {
                    display: block;
                }
                .faq-content {
                    .faq-para {
                        margin-top: 12px;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 149.19%;                   
                        letter-spacing: 0.005rem;

                        color: #000000;
                        width: 100%;
                        height: auto;
                        margin-bottom: 0px;
                    }
                    .faq-li {
                        margin-top: 8px;
                        .faq-lib {
                            color: #1226AA;
                            font-weight: bold;
                            display: inline-block;
                            font-size: 1.5rem;
                            vertical-align: top;
                            line-height: 1.5rem;
                        }
                        .faq-lit {
                            vertical-align: top;
                            margin-left: 0.5rem;
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 149.19%;
                            letter-spacing: 0.005rem;
                            color: #000000;
                            display: inline-block;
                            width: calc(100% - 1.5rem);
                        }
                    }
                }
            }
        }
    }
    .crp-package-rating-meter {
        width: 100%;
        margin-top: 24px;
        height: 215px !important;
        position: relative;
        text-align: center;
        .meter-label-left {
            vertical-align: top;
            display: inline-block;
            color: #292B2C;
            font-family: Poppins;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 116.883%; /* 14.026px */
            letter-spacing: 0.01125rem;
            width: 45px;
        }
        .meter-inner-cont {
            display: inline-block;
            width: calc(100% - 96px);
            max-width: 221px;
            margin-left: 3px;
            margin-right: 3px;
            vertical-align: top;
            .meter-img {
                width: 100%;
            }
        }
        .meter-label-right {
            vertical-align: top;
            display: inline-block;
            color: #292B2C;
            font-family: Poppins;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            text-align: right;
            line-height: 116.883%; /* 14.026px */
            letter-spacing: 0.01125rem;
            width: 45px;
        }
    }
}

.raa-report-preview-cont {
    background: #ffffff !important;
    margin-top: 0px !important;
    overflow-y: scroll !important;
    .ho-back-button {
        display: none;
    }
    .hbb-cont {
        display: none;
    }
    .customer-report-page {
        height: 100%;
        position: relative;
        width: 100%;
        .crp-header {
            width: 100%;
            height: 70px;
            left: 0px;
            top: 0px;
            background: #ffffff;
            text-align: center;
            box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
            a > img {
                margin-top: 7px;
                height: 54px;
            }
        }
        .crp-main-title {
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #0D1840;   
            text-align: left;
            margin-top: 30px;
            padding-left: 16px;
        }
        .crp-info-button {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.18rem;
            letter-spacing: 0.0015rem;
            text-align: center;
            margin-top: 14px;
            cursor: pointer;
            span {
                /*text-decoration-line: underline;*/
                margin-left: 3px;
            }
            color: #1226AA;
        }
        .crp-info-button-desktop {
            display: none;
        }
        .crp-info-modal {
            .crp-im-title {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.43rem;
                letter-spacing: 0.0015rem;
                color: #000000;
                margin-bottom: 5px;
            }
            .crp-im-description {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.5rem;
                letter-spacing: 0.005rem;
                color: #000000;
                margin-bottom: 10px;
            }
            .crp-im-cta {
                display: none;
            }
        }
        .crp-title-green {
            font-weight: 500;
            font-size: 1rem;
            text-align: center;
            letter-spacing: 0.0025rem;
            color: #030C43;
            display: block;
            width: 100%;
            margin-bottom: 18px;
            margin-top: 40px;
        }
        .crp-title-blue {
            font-weight: 500;
            font-size: 1rem;
            text-align: center;
            letter-spacing: 0.0025rem;
            color: #030C43;
            display: block;
            width: 100%;
            margin-bottom: 18px;
            margin-top: 40px;
        }
        .crp-card-cont {
            width: 100%;
            text-align: center;
            .crp-card {
                .crp-card-header-green {
                    background: #659540;
                }
                .crp-card-header-blue {
                    background: #164B6F;
                }
                .crp-comp-header {
                    height: 32px;
                    position: relative;
                    .crp-ch-left {
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 1.18rem;
                        letter-spacing: 0.0015rem;
                        color: #0D1840;
                        text-align: left;
                        float: left;
                    }
                    .crp-ch-right {
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 1.18rem;
                        letter-spacing: 0.0015rem;
                        color: #0D1840;
                        text-align: right;
                        float: right;
                    }
                }
                .crp-comp-meter {
                    font-weight: 500;
                    font-size: 1rem;
                    letter-spacing: 0.0015rem;
                    color: #0D1840;
                    width: 100%;
                    vertical-align: top;
                    .crp-cm-bar-cont {
                        display: inline-block;
                        height: 26px;
                        width: calc(100% - 58px);
                        background: #04203C;
                        border-radius: 48px;
                        vertical-align: middle;
                        margin-left: 8px;
                        margin-right: 8px;
                        position: relative;
                        .crp-cm-label {
                            position: absolute;
                            font-weight: 500;
                            font-size: 1rem;
                            line-height: 1.18rem;
                            letter-spacing: 0.0015rem;
                            color: #FFFFFF;
                            z-index: 1;
                            top: 3px;
                            left: 13px;
                        }
                        .crp-cm-bar {
                            display: inline-block;
                            height: 26px;
                            border-radius: 48px;
                            &.green {
                                background: #34B233;
                            }
                            &.blue {
                                background: #55B9FC;
                            }
                        }
                        .crp-cm-bubble-green {
                            font-weight: 500;
                            font-size: 1rem;
                            line-height: 1.18rem;
                            letter-spacing: 0.0015rem;
                            color: #FFFFFF;
                            background: #34B233;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            padding-left: 8px;
                            padding-right: 8px;
                            border-radius: 5px;
                            position: absolute;
                            top: -36px;
                            width: 70px;
                            height: 26px;
                            margin-left: -40px;
                            text-align: center;
                            display: inline-block;
                            .crp-cmb-arrow {
                                position: absolute;
                                width: 0; 
                                height: 0; 
                                border-left: 8px solid transparent;
                                border-right: 8px solid transparent;
                                
                                border-top: 10px solid #34B233;
                                left: 30px;
                            }
                        }
                        .crp-cm-bubble-blue {
                            display: inline-block;
                            font-weight: 500;
                            font-size: 1rem;
                            line-height: 1.18rem;
                            letter-spacing: 0.0015rem;
                            color: #FFFFFF;
                            background: #55B9FC;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            padding-left: 8px;
                            padding-right: 8px;
                            border-radius: 5px;
                            position: absolute;
                            top: 35px;
                            width: 70px;
                            margin-left: -40px;
                            text-align: center;
                            .crp-cmb-arrow {
                                position: absolute;
                                width: 0; 
                                height: 0; 
                                border-left: 8px solid transparent;
                                border-right: 8px solid transparent;
                                
                                border-bottom: 10px solid #55B9FC;
                                left: 30px;
                                top: -8px;
                            }
                        }
                    }
                    &.green {
                        margin-top: 42px;
                        margin-bottom: 16px;
                    }
                    &.blue {
                        margin-bottom: 32px;
                    }
                }
                .crp-card-header-green, .crp-card-header-blue {
                    width: calc(100% + 44px);
                    height: 66px;
                    font-weight: 500;
                    font-size: 1.25rem;
                    line-height: 4.125rem;
                    text-align: center;
                    letter-spacing: 0.0015rem;
                    color: #FFFFFF;
                    margin-left: -22px;
                    margin-top: -18px;
                    border-radius: 5px 5px 0px 0px;
                }
                .crp-read-more-button {
                    width: calc(100%);
                    margin-left: 0px;
                    height: 51px;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 3.1875rem;
                    text-align: left;
                    letter-spacing: 0.0015rem;
                    color: #0493CF;
                    margin-top: 8px;
                    cursor: pointer;
                    img {
                        margin-left: 4px;
                        margin-top: -2px;
                    }
                    margin-bottom: -18px;
                }
                display: inline-block;
                width: 940px;
                max-width: calc(100% - 32px);
                background: #FFFFFF;
                box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.15006);
                border-radius: 5px;
                padding: 20px;
                text-align: left;
                padding-left: 13px;
                padding-right: 13px;
                padding-top: 18px;
                padding-bottom: 18px;
                position: relative;
                .crp-breakdown-title {
                    font-weight: 500;
                    font-size: 1.25rem;
                    line-height: 1.43rem;
                    letter-spacing: 0.0015rem;
                    color: #0D1840;
                    margin-bottom: 16px;
                }
                .crp-breakdown-canvas {
                    width: 145px;
                    height: 145px;
                }
                .crp-breakdown-items {
                    position: absolute;
                    vertical-align: top;
                    top: 58px;
                    right: 18px;
                    .crp-breakdown-item {
                        display: block;
                        .crp-bd-img-cont {
                            display: inline-block;
                            img {
                                height: 24px;
                            }
                            text-align: center;
                            height: 24px;
                            width: 32px;
                            vertical-align: top;
                        }
                        .crp-bd-title {
                            display: inline-block;
                            font-weight: 500;
                            font-size: 0.875rem;
                            width: 100px;
                            line-height: 1rem;
                            letter-spacing: -0.05rem;
                            color: inherit;
                        }
                        .crp-bd-percent {
                            display: inline-block;
                            font-weight: 500;
                            font-size: 0.875rem;
                            line-height: 1rem;
                            letter-spacing: -0.05rem;
                            color: #0D1840;
                        }
                        margin-bottom: 14px;
                    }
                }
                /*.crp-bd-shift-down {
                    position: absolute !important;
                    top: 40px;
                }
                .crp-breakdown-canvas {
                    margin-left: 50px;
                    margin-top: 20px;
                    margin-bottom: 5px;
                }
                .crp-breakdown-items {
                    width: 600px;
                    margin-top: 55px;
                    left: 250px;
                    .crp-breakdown-item {
                        display: inline-block;
                        .crp-bd-title {
                            width: 150px;
                        }
                        .crp-bd-percent {
                            margin-right: 90px;
                        }
                    }
                }*/
            }
            margin-top: 20px;
        }
    }
    .crp-modal-cont {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 3;
        cursor: pointer;
        .crp-modal {
            cursor: initial;
            position: absolute;
            right: 0px;
            top: 0px;
            min-height: 100vh;
            width: 100%;
            max-width: 545px;
            background-color: white;
            box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.15006);
            z-index: 5;
            padding: 16px;
            .crp-modal-header {
                .crp-modal-title {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 2.125rem;
                    line-height: 100%;
                    letter-spacing: 0.0025rem;
                    color: #000000;
                    width: calc(100% - 48px);
                    vertical-align: top;
                }
                img {
                    position: relative;
                    width: 24px;
                    height: 24px;
                    vertical-align: top;
                    margin-top: 6px;
                    cursor: pointer;
                    margin-left: 20px;
                }
                margin-bottom: 24px;
            }
            .crp-modal-body {

            }
        }
    }
    .crp-savings-desc {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.31rem;
        letter-spacing: 0.005rem;
        color: #1F2631;
    }
    .crp-savings-row {
        height: 46px;
        border-bottom: 1px solid #EDEDED;
        width: 100%;
        .crp-savings-title, .crp-savings-value {
            font-weight: 500;
            font-size: 0.9375rem;
            line-height: 1.18rem;
            letter-spacing: 0.0015rem;
            color: #1F2631;
            line-height: 2.875rem;
        }
        .crp-savings-title {
            float: left;
        }
        .crp-savings-value {
            float: right;
        }
        .crp-savings-unavailable, .crp-savings-unavailable-1 {
            position: absolute;
            font-size: 0.9375rem;
            line-height: 0.9375rem;
            display: inline-block;
            width: 106px;
            margin-top: 6px;
            right: 0px;
        }
        .crp-savings-unavailable-1 {
            line-height: 2.5rem;
        }
    }
    .crp-unavailable-warning {
        font-weight: 500;
        font-size: 0.9375rem;
        line-height: 1.18rem;
        letter-spacing: 0.0015rem;
        color: #1F2631;
        line-height: 2.875rem;
        font-style: italic;
    }
    .crp-savings-trees {
        margin-top: 48px;
        background: rgba(4, 147, 207, 0.1);
        border-radius: 5px;
        font-weight: 500;
        font-size: 1rem;
        width: 100%;
        height: 68px;
        line-height: 4.25rem;
        letter-spacing: 0.0015rem;
        color: #1F2631;
        img {
            margin-left: 18px;
        }
    }
    .crp-comment {
        margin-top: 30px;
        .crp-comment-header {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.6875rem;
            letter-spacing: 0.0015rem;
            color: #000000;
            margin-bottom: 11px;
        }
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.005rem;
        color: #000000;
    }
    .crp-rec-body {
        .crp-recommendation-text {
            display: inline-block;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.75rem;
            letter-spacing: 0.005rem;
            color: #1F2631;
            width: auto;
        }
        margin-top: 19px;
        margin-bottom: 12px;
    }

    .crp-non-footer {
        min-height: calc(100vh - 200px);
    }
    .crp-cta-button {
        display: none;
    }
    .cta-float {
        display: none;
    }
    .crp-footer {
        display: none;
    }
 }

 .crp-card-cont {
    width: 100%;
    text-align: center;
    .crp-card {
        .crp-card-header-green {
            background: #659540;
        }
        .crp-card-header-blue {
            background: #164B6F;
        }
        .crp-comp-header {
            height: 32px;
            position: relative;
            .crp-ch-left {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.18rem;
                letter-spacing: 0.0015rem;
                color: #0D1840;
                text-align: left;
                float: left;
            }
            .crp-ch-right {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.18rem;
                letter-spacing: 0.0015rem;
                color: #0D1840;
                text-align: right;
                float: right;
            }
        }
        .crp-comp-meter {
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.0015rem;
            color: #0D1840;
            width: 100%;
            vertical-align: top;
            .crp-cm-bar-cont {
                display: inline-block;
                height: 26px;
                width: calc(100% - 58px);
                background: #04203C;
                border-radius: 48px;
                vertical-align: middle;
                margin-left: 8px;
                margin-right: 8px;
                position: relative;
                .crp-cm-label {
                    position: absolute;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.45rem;
                    letter-spacing: 0.0015rem;
                    color: #FFFFFF;
                    z-index: 1;
                    top: 3px;
                    left: 13px;
                }
                .crp-cm-bar {
                    display: inline-block;
                    height: 26px;
                    border-radius: 48px;
                    &.green {
                        background: #34B233;
                    }
                    &.blue {
                        background: #55B9FC;
                    }
                }
                .crp-cm-bubble-green {
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.18rem;
                    letter-spacing: 0.0015rem;
                    color: #FFFFFF;
                    background: #34B233;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    padding-left: 8px;
                    padding-right: 8px;
                    border-radius: 5px;
                    position: absolute;
                    top: -36px;
                    width: 70px;
                    height: 26px;
                    margin-left: -40px;
                    text-align: center;
                    display: inline-block;
                    .crp-cmb-arrow {
                        position: absolute;
                        width: 0; 
                        height: 0; 
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        
                        border-top: 10px solid #34B233;
                        left: 30px;
                    }
                }
                .crp-cm-bubble-blue {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.18rem;
                    letter-spacing: 0.0015rem;
                    color: #FFFFFF;
                    background: #55B9FC;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    padding-left: 8px;
                    padding-right: 8px;
                    border-radius: 5px;
                    position: absolute;
                    top: 35px;
                    width: 70px;
                    margin-left: -40px;
                    text-align: center;
                    .crp-cmb-arrow {
                        position: absolute;
                        width: 0; 
                        height: 0; 
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        
                        border-bottom: 10px solid #55B9FC;
                        left: 30px;
                        top: -8px;
                    }
                }
            }
            &.green {
                margin-top: 42px;
                margin-bottom: 16px;
            }
            &.blue {
                margin-bottom: 32px;
            }
        }
        .crp-card-header-green, .crp-card-header-blue {
            width: calc(100% + 44px);
            height: 66px;
            font-weight: 500;
            font-size: 1rem;
            line-height: 5.125rem;
            text-align: center;
            letter-spacing: 0.0015rem;
            color: #FFFFFF;
            margin-left: -22px;
            margin-top: -18px;
            border-radius: 5px 5px 0px 0px;
        }
        .crp-read-more-button {
            width: calc(100%);
            margin-left: 0px;
            height: 51px;
            font-weight: 500;
            font-size: 1rem;
            line-height: 3.1875rem;
            text-align: left;
            letter-spacing: 0.0015rem;
            color: #1226AA;
            margin-top: 8px;
            cursor: pointer;
            img {
                margin-left: 4px;
                margin-top: -2px;
            }
            margin-bottom: -18px;
        }
        display: inline-block;
        width: 940px;
        max-width: calc(100vw - 32px);
        background: #FFFFFF;
        box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.15006);
        border-radius: 5px;
        padding: 20px;
        text-align: left;
        padding-left: 13px;
        padding-right: 13px;
        padding-top: 18px;
        padding-bottom: 18px;
        position: relative;
        .crp-breakdown-title {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.43rem;
            letter-spacing: 0.0015rem;
            color: #0D1840;
            margin-bottom: 16px;
        }
        .crp-breakdown-canvas {
            width: 145px;
            height: 145px;
        }
        .crp-breakdown-items {
            position: absolute;
            vertical-align: top;
            top: 4px;
            right: 8px;
            .crp-breakdown-item {
                display: block;
                .crp-bd-img-cont {
                    display: inline-block;
                    img {
                        height: 24px;
                    }
                    text-align: center;
                    height: 24px;
                    width: 32px;
                    vertical-align: top;
                }
                .crp-bd-title {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 0.875rem;
                    width: 105px;
                    line-height: 1rem;
                    letter-spacing: -0.05rem;
                    color: inherit;
                }
                .crp-bd-percent {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    letter-spacing: -0.05rem;
                    color: #0D1840;
                }
                margin-bottom: 14px;
            }
        }
        @media screen and (min-width: 979px) {
            .crp-breakdown-report-page {
                .crp-bd-shift-down {
                    position: absolute !important;
                    top: 40px;
                }
            }
        }
        @media screen and (min-width: 481px) and (max-width: 980px) {
            .crp-breakdown-report-page {
                .crp-breakdown-canvas {
                    margin-left: 50px;
                    margin-top: 20px;
                    margin-bottom: 5px;
                }
                .crp-breakdown-items {
                    width: 300px;
                    max-width: 70%;
                    margin-top: 23px;
                    left: 50%;
                    .crp-breakdown-item {
                        display: block;
                        .crp-bd-title {
                            width: 150px;
                        }
                        .crp-bd-percent {
                            margin-right: 90px;
                        }
                    }
                }
            }
        }
        @media screen and (min-width: 979px) {
            .crp-breakdown-report-page {
                .crp-breakdown-canvas {
                    margin-left: 50px;
                    margin-top: 20px;
                    margin-bottom: 5px;
                }
                .crp-breakdown-items {
                    width: 600px;
                    margin-top: 55px;
                    left: 250px;
                    .crp-breakdown-item {
                        display: inline-block;
                        .crp-bd-title {
                            width: 150px;
                        }
                        .crp-bd-percent {
                            margin-right: 90px;
                        }
                    }
                }
            }
        }
    }
    margin-top: 20px;
}
.crp-upgrade-packages-cont {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .crp-upgrade-packages {
        padding: 13px;
        .crp-up-title {
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #0D1840;
            border-bottom: 1px solid #EAEAEA;
            height: 42px;
            text-align: left;
            width: 100%;
        }
        .crp-card-cont {
            .crp-card {
                border: none;
                box-shadow: none;
                max-width: calc(100%);
                padding-left: 0px;
                padding-right: 0px;
                .crp-card-header-blue, .crp-card-header-green {
                    width: calc(100%);
                    margin-left: 0px;
                }
            }
        }
        .crp-card-cont + .crp-card-cont {
            border-top: 1px solid #EAEAEA;
            padding-top: 20px;
        }
        .crp-card-cont + .crp-title-green {
            border-top: 1px solid #EAEAEA;
            padding-top: 20px;
        }
        .crp-card-cont + .crp-title-blue {
            border-top: 1px solid #EAEAEA;
            padding-top: 20px;
        }
        display: inline-block;
        width: 940px;
        max-width: calc(100vw - 32px);
        background: #FFFFFF;
        box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.15006);
        border-radius: 5px;
        .cstat-20-year-savings {
            color: #367B3D;
        }
        .cstat-emissions {
            color: #1672F6;
        }
        .cstat-energy-use {
            color: #007EA6;
        }
        .crp-cstat-line-1, .crp-cstat-line-2 {
            display: block;
            width: 100%;
            margin-bottom: 12px;
        }
        .crp-cstat-line-2 {
            .cstat-block {
                width: calc(50% - 6px);
            }
            .cstat-block + .cstat-block {
                margin-left: 12px;
            }
            margin-bottom: 20px;
        }
        .crp-incl-upgrades-title {
            color: #0D1840;
            text-align: left;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .crp-incl-upgrades-cont {
            padding: 25px;
            padding-left: 0px;
            padding-right: 0px;
            .crp-incl-rec {
                img {
                    max-width: 80px;
                    width: 80px;
                }
                margin-left: 4px;
                margin-right: 4px;
                display: inline-block;
                width: 96px;
                color: #1226AA;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%; /* 18.2px */
                margin-bottom: 26px;
                vertical-align: top;
            }
        }
        .crp-package-details-btn {
            display: inline-block;
            border-radius: 5px;
            width: 100%;
            max-width: 358px;
            background: #4A3DF0;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 46px;
            height: 46px;
            text-align: center;
            margin-bottom: 15px;
        }
    }
}

.crp-home-rating-header-cont {
    .crp-home-rating-header {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 600;
        font-family: Poppins;
        color: #1F2631;
        width: 100%;
        border-bottom: 1px solid #EAEAEA;
        height: 39px; 
    }
    .crp-home-rating-tabs {
        margin-bottom: 22px;
        .crp-home-rating-button {
            vertical-align: top;
            img {
                height: 26px;
                margin-bottom: 8px;
            }
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 118%;
            text-align: center;
            text-transform: uppercase;
            height: 83px;
            width: calc(100% / 3);
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 3px;
            white-space: pre;
            &.btn-selected {
                color: #4A3DF0;
                border-bottom: 4px solid #4A3DF0;
                padding-bottom: 0px;
            }
            &.btn-left {

            }
            &.btn-middle {
                border-left: 1px solid #EAEAEA;
            }
            &.btn-right {
                border-left: 1px solid #EAEAEA;
            }
            border-bottom: 1px solid #EAEAEA;
        }
    }
}

.crp-breakdown-tab-content {
    position: relative;
    padding: 0px;
    margin: 0;
    padding-bottom: 12px;
    border-bottom: 1px solid #EAEAEA;
}
.crp-energy-use-tab-content, .crp-env-impact-tab-content {
    margin-top: 20px;
    padding-top: 0px;
    .rrti-main {
        font-weight: 500;
        font-size: 1.14rem;
        line-height: 1.42rem;
        text-align: center;
        letter-spacing: 0.0018rem;
        color: #030C43;               
        margin-top: 16px;
    }
    .rrti-sub {
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 1.07rem;
        text-align: center;
        color: #030C43;
    }
    .rrti-trees {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.14rem;
        text-align: center;
        letter-spacing: 0.0030rem;
        color: #030C43;
        img {
            margin-right: 4px;
        }
    }
}
.tooltip-energuide-verified {
    z-index: 10;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.14rem !important;
    letter-spacing: 0.0030rem !important;
    color: #030C43 !important;
    background-color: #FFF !important;
    -webkit-filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25)) !important;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25)) !important;
    opacity: 1.0 !important;
}

.bordered-checkbox {
    border: 3px solid #707070;
    border-radius: 10px;
}