// ----------
// - MIXINS -
// ----------

@mixin generate-theme($theme-class) {
    body.customer-page.customer-pages-#{$theme-class} {
        .public-header-cont {
            .public-header, .public-header-mobile {
                border-top-color: $palette-36 !important;
                .ph-text {
                    color: $palette-2 !important;
                }
                .phb-selected {
                    border-bottom-color: $palette-36 !important;
                    color: $palette-36 !important;
                    img {
                        filter: $filter-3 !important;
                    }
                }
                .phls-btn {
                    background-color: $palette-36 !important;
                    border-color: $palette-36 !important;
                }
            }
        }

        .hmp-tw-btn {
            background-color: $palette-36 !important;
            border-color: $palette-36 !important;
        }

        .phls-btn {
            background-color: $palette-36 !important;
            border-color: $palette-36 !important;
        }

        .phb-selected {
            border-bottom-color: $palette-36 !important;
            color: $palette-36 !important;
            img {
                filter: $filter-3 !important;
            }
        }

        .hmp-claim-blank-home {
            > button, .cwp-intro-survey-complete-btn {
                background-color: $palette-37 !important;
                color: $palette-46 !important;
            }
        }

        .cnp-notif-title {
            img {
                filter: $filter-3 !important;
            }
            color: $palette-37 !important;
        }

        .crp-dp-btn.crp-dpb-selected {
            img {
                filter: $filter-3 !important;
            }
            color: $palette-37 !important;
        }

        .crp-tc-button {
            background-color: $palette-37 !important;
            color: $palette-46 !important;
        }

        .hmp-new-step-no {
            background-color: $palette-36 !important;
        }

        .hmp-map-switcher > button {
            color: $palette-36 !important;
            img {
                filter: $filter-3 !important;
            }
        }
        .hmmsdd-map-option.hmmsdd-map-selected {
            color: $palette-36 !important;
            border-top-color: $palette-36 !important;
        }
        .hmp-cc-confirm-btn, .confirm-claim {
            box-shadow: none !important;
            background-color: $palette-37 !important;
            color: $palette-46 !important;
        }
        .hmp-search {
            .search-icon {
                background-color: $palette-36 !important;
                border-radius: 95px !important;
                img {
                    /*filter: $filter-3 !important;*/
                }
            }
        }
        .hmp-info-bubble {
            background-color: $palette-36 !important;
            &::before {
                border-bottom-color: $palette-36 !important;
            }
        }
        .hmp-intro {
            .intro-next {
                background-color: $palette-36 !important;
            }
            .intro-back {
                color: $palette-36 !important;
            }
            .skip-step {
                color: $palette-36 !important;
            }
        }
        .crp-package-details-btn {
            background-color: $palette-36 !important;
            color: $palette-1 !important;
        }
        .crp-priorities-link {
            color: $palette-36 !important;   
        }
        .cdb-step-checkbox {
            > a {
                color: $palette-36 !important;
                img {
                    filter: $filter-3 !important;
                }   
            }
        }
        .cust-faq-more-less {
            color: $palette-6 !important;
        }
        .cdp-ba {
            background-color: $palette-1 !important;
            color: $palette-6 !important;
        }
        .cdp-ba.cdp-ba-selected {
            background-color: $palette-6 !important;
            color: $palette-1 !important;
        }
        .crp-vcb-btn {
            color: $palette-6 !important;
        }
        .cdp-tab.cdp-tab-selected {
            border-color: $palette-6 !important;
            color: $palette-6 !important;
        }
        .cdp-rec-comments-button {
            color: $palette-6 !important;
        }
        .cdd-more-info-button {
            color: $palette-36 !important;
            img {
                filter: $filter-3 !important;
            }
        }
        .cdd-modal-close, .cdd-point-icon {
            filter: $filter-3 !important;
        }
        button.c-faq-button-cont {
            img {
                filter: $filter-3 !important;
            }
        }
        .prio-down {
            img {
                filter: $filter-3 !important;
            }
        }
        .prio-up {
            img {
                filter: $filter-3 !important;
            }
        }
        .cprio-save {
            background-color: $palette-37 !important;
            color: $palette-46 !important;
        }

        *:focus {
            // box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31) !important;
            border-radius: 5px !important;
            box-shadow: 0px 0px 0px 4px $palette-36 !important;
        }
        .mapboxgl-canvas:focus {
            border: 2px solid rgba(120,170,255,0.82) !important;
            border-radius: 0;
        }
        background: $palette-1 !important;
        .ho-back-button {
            margin-top: 32px !important;
            b {
                color: $palette-30 !important;
            }
            img {
                filter: $filter-3 !important;
            }
        }
        .cwp-skip-to-end-desktop, .cssdd-skip-to-end, .crp-print-button {
            img {
                filter: $filter-3 !important;
            }
        }
        .cdb-step-highlight.cdb-step-hl-pending {
            background: $palette-6 !important;
        }
        .cdb-step-cont.cdb-step-cont-pending {
            border-color: $palette-6 !important;
        }
        .cdb-last-published, .cdb-sub-step-text, .cdb-main-title, .cdb-title-2, .cdb-sc-title, .cdb-sc-upgrade, .csp-text1 {
            color: $palette-2 !important;
        }
        .cdb-sub-step-lower-text, .crp-savings-desc, .crp-savings-title, .crp-savings-value, .crp-unavailable-warning, .crp-savings-trees, .crp-recommendation-text {
            color: $palette-3 !important;
        }
        .cdb-logout, .crp-logout, .cwp-logout, .csp-logout {
            filter: brightness(0) saturate(100%) $filter-2 !important;
        }
        .cdb-view-report-block {
            background: $palette-28 !important;
        }
        .cdb-optional-header, span.cdb-sc-link {
            color: $palette-4 !important;
            font-weight: 600 !important;
        }
        .cdb-header, .cwp-header, .crp-header, .csp-header {
            background-color: $palette-header !important;
        }
        .cdb-vrb-title, .cwp-pds-title, .cwp-info-text, .cwp-ov-item, .cwp-option, .cwp-finished-title { 
            color: $palette-5 !important;
        }
        .crp-title-blue {
            color: $palette-2 !important;
        }
        .cdb-vrb-button, .crb-upload-button, .cdb-sc-button, .cdb-sc-button-2, .crp-cta-button {
            background: $palette-47 !important;
            color: $palette-48 !important;
            font-weight: 600 !important;
            box-shadow: 0px 4px $palette-41 !important;
        }
        .crp-ul-invite {
            background: $palette-37 !important;
            color: $palette-45 !important;
            box-shadow: 0px 4px 0px 0px $palette-49 !important;
        }
        .crp-cta-button {
            border-radius: $palette-37-radius !important;
        }
        .cta-button, .cdb-substep-sign-up-here {
            background: $palette-39 !important;
            color: $palette-45 !important;
            box-shadow: 0px 4px 0px 0px $palette-44 !important;
        }
        .cdb-step-content {
            border-color: $palette-7;
        }
        .cdb-sc-desc, .crp-im-title, .crp-im-description, .crp-im-cta, .crp-modal-title, .crp-comment-header, .crp-comment, .cwp-finished-body, .csp-text2 {
            color: $palette-10;
        }
        .cdb-step-cont, .cdb-step-cont-optional, .crp-card {
            background: $palette-1;
        }
        .cdb-step-hl-complete, .crp-cm-bar.green {
            background: $palette-8 !important;
        }
        .cdb-step-status-complete {
            background-color: $palette-32 !important;
            color: $palette-33 !important;
            font-weight: 600 !important;
        }
        .cdb-step-hl-pending, .crp-cm-bar.blue {
            background: $palette-4;
        }
        .cdb-step-hl-not-started {
            background: $palette-7;
        }
        .cdb-step-no {
            color: $palette-9;
            font-weight: 600 !important;
        }
        .cdb-step-status-pending {
            background-color: $palette-34 !important;
            color: $palette-35 !important;
            font-weight: 600 !important;
        }
        .ho-back-button b, .crp-main-title, .crp-ch-left, .crp-ch-right, .crp-comp-meter, .crp-breakdown-title, .crp-bd-percent, .crp-ds-title, .crp-dp-title, .crp-helpful-links-header, .crp-home-rating-header {
            color: $palette-12 !important;
        }
        .crp-cm-label {
            color: $palette-31 !important;
        }
        .crp-info-button {
            color: $palette-6 !important;
            img {
                filter: brightness(0) saturate(100%) $filter-1 !important;
            }
        }
        .crp-im-cta-link {
            color: $palette-29 !important;
        }
        .crp-header {
            background: $palette-1;
        }
        .crp-title-green {
            color: $palette-2 !important;
        }
        .crp-card-header-green {
            background: $palette-13 !important;
        }
        .crp-card-header-blue {
            background: $palette-5 !important;
        }
        .crp-cm-bubble-green {
            color: $palette-31 !important;
            background: $palette-8;
            .crp-cmb-arrow {
                border-color: $palette-8;
            }
        }
        .crp-cm-bubble-blue {
            color: $palette-31 !important;
            background: $palette-4;
            .crp-cmb-arrow {
                border-color: $palette-4;
            }
        }
        .crp-card-header-green, .crp-card-header-blue {
            color: $palette-1;
        }
        .crp-read-more-button {
            border-color: $palette-14 !important;
            color: $palette-36 !important;
            font-weight: 500;
        }
        .crp-savings-row {
            border-color: $palette-14;
        }
        .crp-cta {
            background: $palette-42 !important;
            color: $palette-43 !important;
        }
        .cwp-view-report-button, .csp-homepage-button {
            background: $palette-6 !important;
            color: $palette-31 !important;
            font-weight: 600 !important;
        }
        .csp-homepage-button {
            background: $palette-6 !important;
            color: $palette-38 !important;
            font-weight: 600 !important;
        }
        .cwp-pd-bar-bg {
            background-color: $palette-16;
        }
        .cwp-pd-bar-done {
            background-color: $palette-17 !important;
        }
        .cwp-pds-circle {
            color: $palette-31 !important;
        }
        .cwp-pd-step.active {
            .cwp-pds-circle {
                background-color: $palette-17 !important;
                color: $palette-1 !important;
            }
        }
        .cwp-pd-step-btn {
            cursor: pointer;
            &:hover {
                .cwp-pds-circle {
                    background-color: $palette-17 !important;
                    color: $palette-1 !important;
                }
                .cwp-pds-title {
                    color: #56BBCE;
                }
            }
        }
        .active.cwp-pds-circle {
            background-color: $palette-17 !important;
            color: $palette-1 !important;
        }
        .active.cwp-pds-title, .cwp-pm-step-title {
            color: $palette-17 !important;
        }
        .cwp-pm-next-step {
            color: $palette-23;
        }
        .cwp-question-card {
            background: $palette-1;
        }
        .cwp-qc-title, .cwp-sub-title, .cwp-qc-unit-title {
            color: $palette-15;
        }
        .cwp-order-value-cont {
            background: $palette-18;
            border-color: $palette-18;
        }
        .cwp-option.selected, .cwp-option.toggled {
            background: $palette-19 !important;
            border-color: $palette-17 !important;
        }
        .cwp-i-label {
            color: $palette-20;
        }
        .cwp-i-input {
            background: $palette-18;
        }
        .cwp-i-input-error {
            border-color: $palette-22;
        }
        .cwp-i-input-error-msg {
            color: $palette-22;
        }
        .cwp-continue-button {
            color: $palette-31 !important;
            background: $palette-8 !important;
        }
        .cwp-progress-inner {
            canvas {
                color: $palette-37 !important;
            }
        }
        .cwp-back-button {
            color: $palette-5;
            filter: $filter-3 !important;
            background: $palette-26;
        }
        .cwp-footer, .cdb-footer, .csp-footer, .crp-footer {
            color: $palette-1 !important;
            background: $palette-15 !important;
            a {
                color: $palette-13 !important;
            }
        }
        .crp-uli-manage {
            color: $palette-36 !important;
            img {
                filter: $filter-3 !important;
            }
        }
        .apc-header {
            background: $palette-15 !important;
        }
        .auth-btn {
            color: $palette-31 !important;
            background: $palette-27 !important;
        }
    }
    .en-info-btn, .home-address, .faqs-title {
        img {
            filter: $filter-1 !important;
        }
    }
}

// ----------
// - THEMES -
// ----------


// Converting colors into filter CSS for changing SVG icon colours with CSS: https://codepen.io/sosuke/pen/Pjoqqp

/*
// E1 Theme

$palette-1: #FFFFFF;
$palette-2: #1226AA;
$palette-3: #1F2631;
$palette-4: #4A3DF0;
$palette-5: #164b6f;
$palette-6: #0493cf;
$palette-7: #dddddd;
$palette-8: #2B7C1A;
$palette-9: #7a828d;
$palette-10: #000000;
$palette-11: #f2bf72;
$palette-12: #242424;
$palette-13: #659540;
$palette-14: #EDEDED;
$palette-15: #04203C;
$palette-16: #E5E5E5;
$palette-17: #56BBCE;
$palette-18: #F2F4F5;
$palette-19: rgba(53, 194, 220, 0.3);
$palette-20: #131415;
$palette-21: #B9BCC0;
$palette-22: #E74C3C;
$palette-23: #919191;
$palette-24: rgba(68, 25, 0, 0.93485);
$palette-25: #ECCF61;
$palette-26: transparent;
$palette-27: #55B9FC;
$palette-28: rgba(6, 147, 227, 0.1);
$palette-29: #0493cf;
$palette-30: #242424;
$palette-31: #000000;
$palette-32: rgba(148, 229, 131, 0.3);
$palette-33: #0C451D;
$palette-34: rgba(255, 207, 13, 0.3);
$palette-35: #5D3711;
$palette-36: #1226AA;
$palette-37: #1CCF79;
$palette-38: #000000;
$palette-39: #4A3DF0;
$palette-40: #025171;
$palette-41: transparent;
$palette-37-radius: 10px;
$palette-42: #E5E5E5;
$palette-43: #1226AA;
$palette-44: rgba(74, 61, 240, 0.74);
$palette-45: #FFFFFF;
$palette-46: #1F2631;
$palette-47: #74FAC0;
$palette-48: #000000;
$palette-header: #FFFFFF;
$filter-1: invert(52%) sepia(56%) saturate(7379%) hue-rotate(175deg) brightness(100%) contrast(97%);
$filter-2: none;
$filter-3: none;

@include generate-theme(e1-theme);*/

// Default Theme

$palette-1: #FFFFFF;
$palette-2: #0D1840;
$palette-3: #1F2631;
$palette-4: #4A3DF0;
$palette-5: #030C43;
$palette-6: var(--pal-primary);
$palette-7: #dddddd;
$palette-8: var(--pal-primary);
$palette-9: #7a828d;
$palette-10: #000000;
$palette-11: #FFCF0D;
$palette-12: #0D1840;
$palette-13: #2B7C1A;
$palette-14: #EDEDED;
$palette-15: var(--pal-footer);
$palette-16: #E5E5E5;
$palette-17: #2B7C1A;
$palette-18: #F2F4F5;
$palette-19: rgba(0, 121, 96, 0.1);
$palette-20: #131415;
$palette-21: #B9BCC0;
$palette-22: #E74C3C;
$palette-23: #919191;
$palette-24: #ffffff;
$palette-25: #4A3DF0;
$palette-26: transparent;
$palette-27: #2B7C1A;
$palette-28: #EDEDED;
$palette-29: #030C43;
$palette-30: #4A3DF0;
$palette-31: #FFFFFF;
$palette-32: rgba(148, 229, 131, 0.3);
$palette-33: #0C451D;
$palette-34: rgba(255, 207, 13, 0.3);
$palette-35: #5D3711;
$palette-36: var(--pal-primary);
$palette-37: var(--pal-primary);
$palette-38: var(--pal-primary-inv);
$palette-39: var(--pal-secondary);
$palette-40: #246A48;
$palette-41: rgba(0, 0, 0, 0.5);
$palette-37-radius: var(--pal-cta-button-radius);
$palette-42: var(--pal-primary);
$palette-43: var(--pal-primary-inv);
$palette-44: var(--pal-secondary-faded);
$palette-45: var(--pal-primary-inv);
$palette-46: var(--pal-primary-inv);
$palette-47: var(--pal-secondary);
$palette-48: var(--pal-secondary-inv);
$palette-49: var(--pal-primary-faded);
$palette-header: #122B9B;
$filter-1: invert(63%) sepia(89%) saturate(435%) hue-rotate(95deg) brightness(90%) contrast(86%);
$filter-2: invert(99%) sepia(1%) saturate(1097%) hue-rotate(241deg) brightness(119%) contrast(100%);
$filter-3: var(--pal-primary-filter);

@include generate-theme(default-theme);
