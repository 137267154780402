.cust-data-disclaimer {
    text-align: center;
    .cust-dd-inner {
        display: inline-block;
        width: 940px;
        max-width: calc(100vw - 32px);
        text-align: left;
        .cdd-header {
            color: #030C43;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 137%;
        }
        .cdd-more-info-button {
            border-radius: 5px;
            background: var(--White, #FFF);
            box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.10);
            padding: 16px;
            width: 100%;
            max-width: 382px;
            text-align: left;
            color: #4A3DF0;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 20px */
            letter-spacing: 0.25px;
            .cdd-mib-info {
                margin-right: 2px;
                margin-top: -2px;
            }
            .cdd-mib-arrow {
                margin-top: -2px;
                float: right;
            }
            &:focus {
                outline: 2px solid currentColor !important;
            }
        }
        .cdd-modal-cont {
            position: fixed;
            left: 0px;
            top: 0px;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.25);
            z-index: 20;
            vertical-align: middle;
            .cdd-modal {
                position: relative;
                border-radius: 14px;
                background:#FFF;
                top: 20vh;
                left: 50vw;
                width: 356px;
                margin-left: -178px;
                .cdd-m-title {
                    color: #000;
                    text-align: left;
                    font-family: Poppins;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding-top: 8px;
                    padding-left: 16px;
                    padding-bottom: 8px;
                }
                .cdd-modal-close {
                    position: absolute;
                    top: 10px;
                    right: 16px;
                }
                .cdd-modal-data {
                    .cdd-point-cont {
                        img.cdd-point-icon {
                            vertical-align: top;
                            margin-left: 15px;
                            margin-right: 11px;
                            margin-top: 5px;
                        }
                        span {
                            vertical-align: top;
                            width: calc(100% - 52px);
                            display: inline-block;
                            color: #04203C;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 143.5%; /* 22.96px */
                        }
                    }
                    .cdd-point-cont + .cdd-point-cont {
                        margin-top: 20px;
                    }
                    padding-top: 6px;
                    padding-bottom: 20px;
                }
            }
        }
    }
}