.crp-vcb-number-save {
  color: #367B3D !important;
}
.crp-vcb-number-cost {
  color: #C5271F !important;
}
.ra-negative-color {
  color: #C5271F !important;
}
.ra-negative-color > * {
  color: #C5271F !important;
}

// Public Header/Sidebar
.ph-lang-switcher {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-right: 16px;
  .phls-btn {
    border-radius: 8px;
    padding: 7px 11px 7px 11px;
    border: 1px solid #4A3DF0;
    background: #0E22A1;
    margin-left: 32px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.005rem;
    &:focus {
      border-radius: 8px;
    } 
    cursor: pointer;
    img {
      position: relative;

      margin-right: 4px;
    }
  }
  .phls-dropdown {
    box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.45);
    display: none;
    position: absolute;
    z-index: 20;
    background-color: white;
    border-radius: 6px;
    width: 158px;
    padding: 8px;
    top: 45px;
    left: -60px;
    &.phls-dd-show {
      display: block;
    }
    .phlsdd-lang-option + .phlsdd-lang-option {
      margin-top: 8px;
    }
    .phlsdd-lang-option {
      cursor: pointer;
      color: #242424;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.0015rem;
      &.phlsdd-lang-selected {

      }
    }
  }
}

.public-header-cont {
  *:focus {
    box-shadow: 0px 0px 0px 4px rgba(66, 86, 213, 1);
    border-radius: 5px;
  }
  button {
    border: none;
    background-color: transparent;
    padding: 0px;
    outline: none;
  }
  .loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: rgba(31, 31, 31, 0.5);
    .loading-claiming-msg {
        position: absolute;
        width: 100vw;
        text-align: center;
        left: 0px;
        top: calc(50vh + 10vh);
        color: white;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
    img {
        position: absolute;
        width: 10vh;
        height: auto;
        left: calc(50vw - 5vh);
        top: calc(50vh - 5vh);
    }
    &.loading-show {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.25s, visibility 0.01s;
    }
    &.loading-hide {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: opacity 0.25s, visibility 0.25s;
    }
  }
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  .sa-tools-header {
    position: fixed;
    height: 45px;
    top: 0px;
    left: 0px;
    width: 100vw;
    background-color: #fff;
    z-index: 20;
    border-bottom: 2px solid var(--pal-primary);
  }
  &.has-sa-tools {
    .public-header, .public-header-mobile {
      top: 45px;
    }
    .view-cont {
      top: 45px;
    }
  }
  .public-header {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 74px;
    flex-shrink: 0;
    width: 100%;
    vertical-align: top;
    border-top: 8px solid #1226AA;
    position: relative;
    z-index: 19;
    .ph-right {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .ph-org-logo {
      max-height: 50px;
      max-width: 150px;
      margin-top: -4px;
      margin-left: 20px;
      margin-right: 10px;
    }
    .ph-text {
      margin-top: 15px;
      padding-left: 10px;
      border-left: 1px solid #979797;
      height: 31px;
      display: inline-block;
      color: #1226AA;
      font-family: Poppins;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.008rem;
      line-height: normal;
    }
    .ph-btn {
      display: inline-block;
      height: 66px;
      min-width: 105px;
      text-align: center;
      line-height: 60px;
      color: #292B2C;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.008rem;
      text-transform: uppercase;
      margin-top: 0px;
      vertical-align: top;
      &.phm-btn-wide {
        min-width: 130px !important;
      }
      &.ph-btn-narrow {
        width: 50px;
        min-width: 50px;
      }
      &:focus {
        border-radius: 0px;
      } 
      img {
        position: relative;
        width: 24px;
        height: 24px;
      }
      cursor: pointer;
      &.phb-selected {
        background-color: rgba(18, 38, 170, 0.07);
        border-bottom: 8px solid #1226AA;
        color: #1226AA;
      }
    }
    .ph-signin-btn {
      display: inline-block;
      cursor: pointer;
      margin-right: 10px;
      margin-left: 44px;
      color: #292B2C;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.008rem;
      line-height: normal;
      text-transform: uppercase;
      height: 66px;
      img {
        position: relative;
      }
    }
  }
  .public-header-mobile {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 56px;
    flex-shrink: 0;
    width: 100%;
    vertical-align: top;
    border-top: 8px solid #1226AA;
    position: relative;
    overflow-x: hidden;
    left: 0px;
    transition: left 0.5s;
    z-index: 19;
    white-space: nowrap;
    &.psb-show {
      left: 208px;
      transition: left 0.5s;
    }
    .phm-menu-btn {
      cursor: pointer;
      margin-left: 12px;
      margin-right: 12px;
      margin-top: 12px;
      position: relative;
      display: inline-block;
    }
    .phm-org-logo {
      max-width: 119px;
      max-height: 20px;
      margin-top: 0px;
      margin-right: 3px;
    }
    .phm-text {
      display: inline-block;
      color: #1226AA;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 4px;
      border-left: 1px solid #979797;
      position: relative;
      top: 0px;
    }
    z-index: 2;
  }
  .public-sidebar-mobile {
    position: absolute;
    left: -208px;
    top: 0px;
    width: 208px;
    height: 100vh;
    background: #FFF;
    box-shadow: 5px 0px 7px 0px rgba(0, 0, 0, 0.09);
    opacity: 0;
    pointer-events: none;
    transition: left 0.5s, opacity 0.5s;
    z-index: 21;
    &.psb-show {
      left: 0px;
      opacity: 1.0;
      transition: left 0.5s, opacity 0.5s;
      pointer-events: initial;
    }
    .phm-sb-bottom {
      width: 100%;
      height: 130px;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
    .phm-sb-sep {
      margin-left: 16px;
      width: calc(100% - 32px);
      border-bottom: 1px solid #DDD;
    }
    .phm-btn {
      display: inline-block;
      height: 63px;
      width: 100%;
      line-height: 60px;
      color: #292B2C;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
      text-align: left;
      padding-left: 16px;
      &:focus {
        border-radius: 0px;
      }
      img {
        position: relative;
        width: 24px;
        height: 24px;
      }
      cursor: pointer;
      &.phmb-selected {
        background-color: rgba(18, 38, 170, 0.07);
        border-bottom: 8px solid #1226AA;
        color: #1226AA;
      } 
    }
    .phm-lang-switcher {
      display: block;
      cursor: pointer;
      position: relative;
      .phls-btn {
        border-radius: 8px;
        padding: 7px 11px 7px 11px;
        border: 1px solid #4A3DF0;
        background: #0E22A1;
        margin-left: 16px;
        color: #FFF;
        text-align: left;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.005rem;
        width: calc(100% - 32px);
        &:focus {
          border-radius: 8px;
        } 
        cursor: pointer;
        img {
          position: relative;

          margin-right: 4px;
          &.arrow-right {
            position: absolute;
            right: 24px;
            top: 14px;
          }
        }
      }
      .phls-dropdown {
        box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.45);
        display: none;
        position: absolute;
        z-index: 20;
        background-color: white;
        border-radius: 6px;
        width: 158px;
        padding: 8px;
        top: -80px;
        left: 16px;
        &.phls-dd-show {
          display: block;
        }
        .phlsdd-lang-option + .phlsdd-lang-option {
          margin-top: 8px;
        }
        .phlsdd-lang-option {
          cursor: pointer;
          color: #242424;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.0015rem;
          &.phlsdd-lang-selected {

          }
        }
      }
    }
  }
  .view-cont {
    position: relative;
    width: 100vw;
    height: calc(100vh - 74px);
    left: 0px;
    top: 0px;
    transition: left 0.5s;
    &.psb-show {
      left: 208px;
      transition: left 0.5s;
    }
  }
  .signin-modal-cont {
    display: none;
    opacity: 0;
    position: fixed;
    z-index: 31;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s;
    &.show-modal {
      display: block;
      opacity: 1;
      transition: opacity 0.5s;
    }
    background-color: rgba(0, 0, 0, 0.25);
    .signin-modal {
      border-radius: 10px;
      background: #FFF;
      width: 407px;
      max-width: 100%;
      left: 50%;
      margin-left: -203px;
      position: absolute;
      top: 50%;
      margin-top: -200px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      .sm-title {
        margin-top: 15px;
        margin-left: 20px;
        margin-bottom: 16px;
        color: #1226AA;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        width: 100%;
      }
      .sm-close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      .sm-error-icon {
        display: inline-block;
        margin-right: 6px;
        background-color: #F53611;
        color: #FFF;
        width: 13.838px;
        height: 13.838px;
        border-radius: 50%;
        text-align: center;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 13.838px;
        vertical-align: top;
      }
      .sm-form {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        .smf-label {
          color: #2A2B30;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
        .smf-input {
          outline: none;
          width: 100%;
          border: 1px solid #DADCE0;
          border-radius: 4px;
          padding: 8px;
          &::placeholder {
            color: #565656;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        &.sm-auth-error {
          .smf-label {
            color: #F53611;
          }
          .smf-input {
            border: 1px solid #F53611;
          }
        }
        .smf-sub-wrap {
          margin-top: 17px;
          width: 100%;
          text-align: center;
          .smf-sub {
            margin-top: 10px;
            padding: 7px 31px 7px 31px;
            border-radius: 10px;
            background:#74FAC0;
            margin-left: 0px;
            width: 100%;
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.005rem;
            &:focus {
              border-radius: 8px;
            } 
            cursor: pointer;
          }
        }
        .smf-error {
          margin-top: 17px;
          color: #E74C3C;
          font-family: Poppins;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          .smf-error-inner {
            display: inline-block;
            width: calc(100% - 32px);
            vertical-align: top;
            margin-top: -5px;
          }
          margin-bottom: 19px;
        }
        margin-bottom: 17px;
      }
    }
  }
  @media screen and (min-width: 1038px) {
    .public-header-mobile, .public-sidebar-mobile {
      display: none;
    }
    .psb-show {
      left: 0px !important;
    }
  }
  @media screen and (max-width: 600px) {
    .sah-select-user-dropdown {
      position: fixed;
      width: calc(100vw - 32px);
      left: 16px !important;
      top: 44px;
    }
    .sah-select-user {
      width: 80px;
      overflow-x: hidden;
      overflow-y: hidden;
    }
    .sah-signout-from-user {
      position: relative;
      top: -8px;
    }
    .sah-signed-in-as {
      position: relative;
      top: -8px;
    }
  }

  @media screen and (max-width: 1037px) {
    .public-header {
      display: none;
    }
    .view-cont {
      height: calc(100vh - 54px);
    }
  }
  @media screen and (max-width: 500px) {
    .signin-modal {
      left: 0px !important;
      margin-left: 0px !important;
      width: calc(100% - 8px) !important;
    }
  }
}
// End Public Header/Sidebar

.form-error-border {
  border: 1px solid #E74C3C !important;
  border-radius: 10px;
  input {
    border: 0px !important;
  }
  textarea {
    border: 0px !important;
  }
}
.form-error-message, .form-error-message-rel {
  position: absolute;
  left: 0px;
  bottom: -14px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 12px;
  letter-spacing: 0.015em;
  color: #FF8B7B;
  &.form-error-message-rel {
    position: relative;
    bottom: 0px;
    &.large {
      font-size: 0.8125rem;
      margin-bottom: 10px;
      margin-top: -10px;
    }
  }

}

.ph-signin-options {
  padding: 1.5rem;
  padding-top: 0px;
  .ph-signin-ext-btn {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 14px;
    color: #000;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.02rem;
    &:active {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    }
    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31) !important;
    }
    img {
      margin-right: 10px;
    }
  }
}

.ph-switch-cont {
  margin-top: 8px;
  color: #141D44;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  button {
    color: #00874A;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    text-decoration-line: underline;
  }
}
.smf-sub-wrap + .ph-switch-cont {
  margin-top: 25px;
}

.en-toggle-button {
  margin-top: 16px;
  img {
    display: inline-block;
    margin-right: 17px;
    vertical-align: top;
  }
  .en-toggle-button-msg {
    vertical-align: top;
    display: inline-block;
    text-align: left;
  }
}

// scrollbars
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1074B7;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2084C7;
}

input {
  &::placeholder {
    color: #444 !important;
    opacity: 1 !important;
  }
}

/* BuildingOwnerVerificationForm */
.owner-verification-form {
  padding: 8px;
  .info-box {
    margin-top: -8px;
    margin-bottom: 24px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 10px;
    background: rgba(246, 185, 59, 0.18);
    padding: 12px;
    color: #292B2C;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1875rem; /* 135.714% */
    text-align: left;
    a {
      color: #007960;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.1875rem;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
  .form-group {
    padding: 8px;
    padding-top: 0px;
    max-width: calc(100vw - 16px);
    label {
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.005rem;
      color: #292B2C;
      margin-bottom: -4px;
    }
    input {
      outline: none;
      width: calc(100% - 8px);
      padding: 8px;
      border: none;
      margin-top: 8px;
      border-radius: 5px;
      background: #F0F0F0;
      &.input-error {
        border: 1px solid #E74C3C;
      }
      &::placeholder {
        color: #A4A4A4 !important;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .claim-check {
      margin-bottom: 16px;
      text-align: left;
      margin-top: 19px;
      width: calc(100% - 8px);
      min-height: 48px;
      &.claim-check-checked {
        img {
          width: 25px; height: 25px;
          border-radius: 5px;
          background-color: transparent;
        }
      }
      &.claim-check-unchecked {
        img {
          width: 25px; height: 25px;
          border: 1px solid #DADCE0;
          border-radius: 5px;
          background-color: transparent;
        }
      }
      &:focus{
          img{
              outline: 2px solid #ffffff !important;
              outline-offset: 2px !important;
          }
      }
      img {
          vertical-align: top;
          display: inline-block;
          margin-right: 13px;
          margin-left: 4px;
          margin-top: 0px;
      }
      .claim-checkbox-label {
          vertical-align: top;
          width: calc(100% - 50px);
          display: inline-block;
          color: #292B2C;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          text-align: left;
          font-weight: 500;
          line-height: 129.688%; /* 20.75px */
          letter-spacing: 0.0015rem;
      }
    }
    .confirm-claim {
      margin-top: 8px;
      margin-left: 8px;
      border-radius: 5px;
      background: #4A3DF0;
      box-shadow: 0px 4px 0px 0px rgba(74, 61, 240, 0.74);
      color: #ffffff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
      text-transform: uppercase;
      cursor: pointer;
      width: calc(100% - 16px);
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  &.pei-property-tax {

  }
  .error-message {
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 8px;
    background-color: #FF8B7B;
    color: #292B2C;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    line-height: 129.688%; /* 20.75px */
    letter-spacing: 0.0015rem;
  }
  margin-bottom: -32px;
}

.en-profile-icon {
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  display: inline-block;
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: calc(35px);
  text-align: center;
}

.en-role-badge {
  border-radius: 20px;
  height: 26px;
  padding: 0px 8px;
  display: inline-block;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 214.5%; /* 25.74px */
  &.slim {
    height: 16px;
    line-height: 14px;
  }
}

.en-level-cont {
  display: block;
  span {
    text-transform: uppercase;
    color: #2E2E2E;
    font-family: Poppins;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 118%;
    text-transform: uppercase;
  }
  .en-info-btn {
    cursor: pointer;
  }
}

.en-level-info-cont, .en-level-cont {
  .en-level-badge {
    vertical-align: middle;
    display: inline-block;
    border-radius: 100px;
    padding: 5px 8px;
    color: white;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    offset-position: top;
    text-transform: uppercase;
    img {
      position: relative;
      margin-top: -4px !important;
    }
  }
}

.en-level-info-cont {
  color: #000;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .en-li-info {
    margin-top: 3px;
    margin-bottom: 9px;
  }
}