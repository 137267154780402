@import url('https://fonts.cdnfonts.com/css/poppins');

body {
    font-family: 'Poppins';
    font-style: normal;
}

.public-page-body {
    background: none;
    color: #000000;
    background-color: white;
    overflow-x: hidden;
    width: 100vw;
}

html {
    font-size: 100% !important;
}

mapbox-search-listbox > div > div > [aria-hidden="true"] {
    display: none !important;
}

mapbox-search-listbox > div > div {
    z-index: 3000 !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
}

.homeowner-map-page {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    position: relative;
    .hmp-result.censor-result-1 {
        .res-meter-cont, .rrt-info, .crp-energy-use-tab-content, .crp-env-impact-tab-content  {
            -webkit-filter: blur(5px);
            -moz-filter: blur(5px);
            -o-filter: blur(5px);
            -ms-filter: blur(5px);
            filter: blur(5px);
        }
        .res-rating-tab, .meter-select-package {
            pointer-events: none;
        }
    }
    .hmp-result.censor-result-2 {
    }
    .hmp-result.censor-result-3 {
    }
    .hmp-faq-section-wrap {
        padding: 16px;
    }
    .map-container {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: #081217;
    }
    .hmp-map-switcher {
        display: inline-block;
        cursor: pointer;
        position: absolute;
        top: 28px;
        right: 48px;
        z-index: 3;
        .hmms-btn {
            text-transform: uppercase;
            border-radius: 8px;
            padding: 7px 11px 7px 11px;
            background: #fff;
            margin-left: 32px;
            color: #0E22A1;
            text-align: center;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0051rem;
            &:not(:focus){
                box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.25);
            }
            &:focus {
                border-radius: 8px;
            } 
            cursor: pointer;
            img {
                position: relative;
                margin-right: 4px;
            }
        }
        .hmms-dropdown {
            box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.45);
            display: none;
            position: absolute;
            z-index: 20;
            background-color: white;
            border-radius: 6px;
            width: 125px;
            padding: 8px;
            top: 68px;
            right: 0px;
            &.hmms-dd-show {
                display: block;
            }
            .hmmsdd-map-option + .hmmsdd-map-option {
                margin-top: 8px;
            }
            .hmmsdd-map-option + .hmmsdd-map-option {
                border-top: 1px solid #4A3DF0;
                padding-top: 6px;
            }
            .hmmsdd-map-option {
                cursor: pointer;
                color: #000000;
                width: 110px;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.0015rem;
                text-align: left;
                img {
                    height: 26px;
                    display: inline-block;
                    margin-right: 8px;
                }
                div {
                    display: inline-block;
                    margin-top: 2px;
                }       
                &.hmmsdd-map-selected {
                    color: #4A3DF0;
                }
            }
        }
    }
    .hmp-btmh-floating {
        position: absolute;
        bottom: 16px;
        left: 0px;
        width: 100%;
        padding-left: 16px;
        padding-right: 86px;
        text-align: right;
        .cust-dropdown-button, .btmh-btn {
            width: 345px;
            margin-left: -10px;
            padding-left: 8px !important;
            margin-right: 16px !important;
        }
        .rhb-btn {
            width: 345px;
            margin-left: -10px;
            padding-left: 8px !important;
            margin-right: 16px !important;
            border-radius: 5px;
            background: #F6F5F5;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #111C4E;
            padding-top: 5px;
            padding-bottom: 5px;
            text-transform: uppercase;
            cursor: pointer;
        }
        z-index: 5;
    }
    .hms-offset-y {
        bottom: 87px !important;
    }
    .hmp-main-wrap {
        width: calc(100vw + 16px);
        height: 100%;
        overflow: hidden;
        position: relative;
        overflow-y: scroll;
        .loading {
            position: fixed;
            left: 0px;
            top: 0px;
            width: 100vw;
            height: 100vh;
            z-index: 5000;
            background-color: rgba(31, 31, 31, 0.5);
            .loading-claiming-msg {
                position: absolute;
                width: 100vw;
                text-align: center;
                left: 0px;
                top: calc(50vh + 10vh);
                color: white;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 1.5rem;
                padding-left: 3.5rem;
                padding-right: 3.5rem;
            }
            img {
                position: absolute;
                width: 10vh;
                height: auto;
                left: calc(50vw - 5vh);
                top: calc(50vh - 5vh);
            }
            &.loading-show {
                opacity: 1;
                visibility: visible;
                transition: opacity 0.25s, visibility 0.01s;
            }
            &.loading-hide {
                opacity: 0;
                pointer-events: none;
                visibility: hidden;
                transition: opacity 0.25s, visibility 0.25s;
            }
        }

        .new-hmp-search-block {
            pointer-events: none;
            background-color: rgba(31, 31, 31, 0.5);
            position: fixed;
            z-index: 1000;
            left: 0px;
            top: 0px;
            width: 100vw;
            height: 100vh;
            &.block-vis {
                opacity: 1;
                transition: opacity 0.5s;
            }
            &.block-hidden {
                opacity: 0;
                transition: opacity 0.5s;
            }
        }

        .new-hmp-search-bubble {
            pointer-events: none;
            position: absolute;
            left: 39px;
            top: calc(32px + 131px - 12px);
            z-index: 2002;
            width: 448px;
            img.hmp-bubble-dot {
                position: relative;
                width: 38px;
                height: 38px;
                left: calc(50% - 18px);
            }
            &.bubble-vis {
                opacity: 1;
                transition: opacity 0.5s;
            }
            &.bubble-hidden {
                opacity: 0;
                transition: opacity 0.5s;
            }
            .hmp-info-bubble {
                background-color: #4a3df0;
                width: 100%;
                text-align: left;
                color: #FFF;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.0015rem;
                padding-left: 15px;
                padding-right: 25px;
                padding-top: 13px;
                padding-bottom: 13px;
                border-radius: 9px;
                margin-top: 12px;
                &::before {
                    content: '';
                    width: 0; 
                    height: 0; 
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 15px solid #4a3df0;
                    position: absolute;
                    left: calc(50% - 14px);
                    top: 35px;
                }
            }
        }

        .hmp-header {
            position: absolute;
            z-index: 2;
          
            width: calc(100% - 39px - 39px);
            height: 74px;
            left: 39px;
            top: 30px;
            
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 16px;

            .header-desktop {
                margin-left: 20px;
                margin-top: 18px;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #1226AA;
                display: inline-block;
            }

            .header-mobile {
                display: none;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 21px;
                color: #1226AA;
                width: 100%;
                text-align: center;
                margin-left: 0px;
                margin-bottom: -8px;
            }

            .lang-switcher {
                margin-top: 8px;
                margin-right: 20px;

                float: right;
                display: inline-block;
                box-sizing: border-box;

                cursor: pointer;

                .lang-button {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    text-transform: uppercase;
                    height: 33px;

                    background-color: #ffffff;
                    color: #1226AA;
                    border-radius: 6px 0px 0px 6px;
                    padding-left: 10px;
                    padding-right: 10px;
                    border: 1px solid black;
                    &.selected {
                        border: 1px solid transparent;
                        background: #1226AA;
                        color: #ffffff;
                    }
                    &:focus {
                        /*border: 2px solid #030C43;*/
                        box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                        outline: none;
                        border-radius: 6px 0px 0px 6px;
                    }
                }

                .lang-button + .lang-button {
                    border-radius: 0px 6px 6px 0px;
                }

            }

            .map-switcher {
                float: right;
                width: 180px;
                height: 33px;
                margin-top: 20px;
                margin-right: 20px;
                .map-switcher-left {
                    width: 50%;
                    border-radius: 6px 0px 0px 6px;
                    height: 33px;
                    cursor: pointer;
                    display: inline-block;
                    font-weight: 500;
                    font-size: 0.85rem;
                    line-height: 2.35rem;
                    text-align: center;
                    text-transform: uppercase;
                    box-sizing: border-box;
                    vertical-align: top;
                }
                .map-switcher-right {
                    width: 50%;
                    border-radius: 0px 6px 6px 0px;
                    height: 33px;
                    cursor: pointer;
                    display: inline-block;
                    font-weight: 500;
                    font-size: 0.85rem;
                    line-height: 2.35rem;
                    text-align: center;
                    text-transform: uppercase;
                    box-sizing: border-box;
                    vertical-align: top;
                }
                .map-switcher-left, .map-switcher-right {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    &:focus {
                        border: 2px solid #030C43;
                        box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                        border-radius: 5px;
                        outline: none;
                    }
                }
                &.map-env {
                    .map-switcher-left {
                        background: #1226AA;
                        color: white;
                        border: none;
                    }
                    .map-switcher-right {
                        background: white;
                        color: #1226AA;
                        border: 1px solid #474747;
                    }
                }
                &.map-eng {
                    .map-switcher-left {
                        background: white;
                        color: #1226AA;
                        border: 1px solid #474747;
                        border-right: none;
                    }
                    .map-switcher-right {
                        background: #1226AA;
                        color: white;
                        border: none;
                    }
                }
            }
    
            .map-switcher-btn {
                float: right;
                height: 56px;
                width: 56px;
                padding: 8px;
                border-radius: 4px;
                margin-top: 8px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                cursor: pointer;
                text-align: center;
                img {
                    height: 40px;
                }
                &:hover {
                    border: 3px solid #00b1c871;
                    img {
                        margin-top: -3px;
                    }
                }
                &.selected {
                    border: 3px solid #4A3DF0;
                    img {
                        margin-top: -3px;
                    }
                }
                margin-right: 12px;
            }
        }

        .hmp-intro-bg {
            position: fixed;
            z-index: 10000;
            background-color: rgba(31, 31, 31, 0.5);
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100%;
            min-height: 100vh;
            .hmp-intro {
                position: absolute;
                z-index: 4;
                width: 698px;
                min-height: 313px;
                left: 50%;
                margin-left: -349px;
                top: 189px;
                background: #FFFFFF;
                border-radius: 16px;

                .ph-lang-switcher {
                    position: absolute;
                    top: 16px;
                    left: -12px;
                }

                img.intro-img {
                    position: absolute;
                    width: 186px;
                    max-height: 230px;
                    left: 46px;
                    top: 76px;
                }

                .intro-right {
                    position: relative;
                    display: inline-block;
                    width: calc(100% - 300px);
                    left: 277px;
                    top: 20px;
                    min-height: 275px;
                }

                .intro-top {
                    width: 100%;
                    position: relative;
                    .top-step {
                        font-weight: 400;
                        font-size: 1.21rem;
                        line-height: 1.5rem;
                        color: #030C43;
                    }
                    .skip-step {
                        position: absolute;
                        font-weight: 400;
                        font-size: 1.21rem;
                        line-height: 1.5rem;
                        color: #030C43;
                        right: 20px;
                        top: 0px;
                        cursor: pointer;
                    }
                    margin-bottom: 16px;
                }

                .intro-title {
                    font-weight: 600;
                    font-size: 1.71rem;
                    line-height: 1.72rem;
                    color: #030C43;
                    max-width: 390px;
                    margin-bottom: 9px;
                }

                .intro-content {
                    font-weight: 400;
                    font-size: 1.21rem;
                    line-height: 1.5rem;
                    color: #030C43;
                    max-width: 390px;
                    min-height: 150px;
                    .content-line {
                        min-height: 17px;
                    }
                }

                .intro-buttons {
                    position: relative;
                    margin-top: 20px;
                    bottom: 10px;
                    right: 5px;
                    width: 100%;
                    height: 75px;
                    text-align: right;
                    .intro-back {
                        display: inline-block;
                        width: 107px;
                        height: 44px;
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 1.42rem;
                        line-height: 1.04rem;
                        text-align: center;
                        letter-spacing: 0.0018rem;
                        color: #1226AA;
                        border: none;
                        background-color: white;
                        margin-right: 8px;
                        border-radius: 10px;
                        box-sizing: border-box;
                    }
                    .intro-next {
                        display: inline-block;
                        min-width: 107px;
                        padding-left: 23px;
                        padding-right: 23px;
                        height: 25px;
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 1.42rem;
                        line-height: 2.5rem;
                        text-align: center;
                        letter-spacing: 0.0018rem;
                        color: #FFFFFF;
                        background: #1226AA;
                        border-radius: 10px;
                        height: 44px;
                        border: none;
                        box-sizing: border-box;
                    }
                }
            }

        }

        .hmp-how-bg {
            position: absolute;
            z-index: 8;
            background-color: rgba(31, 31, 31, 0.5);
            top: 0px;
            left: 0px;
            width: 100%;
            min-height: 100%;
            height: auto;
            &.faq-modal {
                position: fixed;
                z-index: 3000 !important;
                height: 100vh;
                overflow-y: scroll;
                .hmp-how {
                    height: auto !important;
                };
            }
            &.tw-modal {
                position: fixed;
                z-index: 3000 !important;
                height: 100vh;
                overflow-y: scroll;
                text-align: center;
                .hmp-tw {
                    display: inline-block;
                    height: auto !important;
                    border-radius: 16px;
                    background: #ffffff;
                    box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.10);
    
                    height: auto !important;
                    width: 453px !important;
                    max-width: calc(100% - 32px);
                    padding: 25px !important;
                    margin-top: 15vh;
                    
                    text-align: left;
                    .hmp-tw-title {
                        color: #030C43;
                        font-family: Poppins;
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        margin-top: -6px;
                    }
                    .hmp-tw-subtitle {
                        color: #030C43;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 30px;
                    }
                    .hmp-tw-btns {
                        width: 100%;
                        .hmp-tw-btn {
                            border-radius: 10px;
                            background: #1226AA;
                            padding: 9px 21px;
                            color: #FFF;
                            text-align: center;
                            font-family: Poppins;
                            font-size: 1.1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 123.688%; /* 24.738px */
                            letter-spacing: 0.001875rem;
                        }
                        .hmp-tw-btn + .hmp-tw-btn {
                            margin-left: 10px;
                            margin-top: 0px;
                        }
                    }
                    position: relative;
                    .ph-lang-switcher {
                        position: absolute !important;
                        right: -8px;
                        top: 8px;
                        .phls-btn {
                        }
                    }
                    @media screen and (max-width: 618px) {
                        top: initial !important;
                        left: initial !important;
                        position: relative !important;
                        .hmp-tw-btn {
                            display: block
                        }
                        .hmp-tw-btn + .hmp-tw-btn {
                            margin-left: 0px !important;
                            margin-top: 10px !important;
                        }
                        .hmp-tw-title {
                            /*margin-top: 32px;*/
                            width: calc(100% - 64px);
                        }
                        .ph-lang-switcher {
                            margin-top: 8px;
                            right: 0px;
                            top: 4px !important;
                            .phls-btn {
                                margin-left: 0px !important;
                            }
                            .phls-dropdown {
                                margin-left: 0px !important;
                            }
                        }
                    }
                }
            }
            &.cc-modal {
                position: fixed;
                z-index: 3000 !important;
                height: 100vh;
                overflow-y: scroll;
                max-width: 100vw;
                .hmp-how {
                    border-radius: 5px;
                    background: #ffffff;
                    box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.10);
    
                    height: auto !important;
                    width: 458px !important;
                    margin-left: calc(50% - 229px) !important;
                    padding-bottom: 18px !important;
                    .how-header {
                        border-bottom: none !important;
                        padding-bottom: 0px !important;
                        margin-bottom: 14px !important;
                        .how-title {
                            color: #04203C;
                            font-family: Poppins;
                            font-size: 1.25rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1rem;
                            max-width: calc(100% - 32px);
                        }
                    }
                }
                @media screen and (max-width: 510px) {
                    .hmp-how {
                        width: calc(100% - 32px) !important;
                        margin-left: 16px !important;
                        .how-header {
                            .how-title {
                                font-size: 1rem;
                            }
                        }
                    }
                }
                .hmp-cc-body {
                    width: calc(100% - 32px);
                    margin-left: 16px;
                    .hmp-cc-addr {
                        color: #2E2E2E;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        border-radius: 10px;
                        background: #ffffff;
                        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                        padding: 10px;
                        margin-bottom: 18px;
                    }
                    .hmp-cc-btn-cont {
                        .hmp-cc-confirm-btn {
                            border-radius: 5px;
                            background: #4A3DF0;
                            box-shadow: 0px 4px 0px 0px rgba(74, 61, 240, 0.74);
                            color: #ffffff;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            padding-top: 8px;
                            padding-bottom: 8px;
                            padding-left: 15px;
                            padding-right: 15px;
                            text-transform: uppercase;
                            cursor: pointer;
                        }
                        .hmp-cc-no-btn {
                            border-radius: 5px;
                            border: 1px solid #F2F4F5;
                            background: #F2F4F5;
                            box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
                            color: #4A3DF0;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            padding-top: 8px;
                            padding-bottom: 8px;
                            padding-left: 15px;
                            padding-right: 15px;
                            text-transform: uppercase;
                            margin-left: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .hmp-how {
                width: 698px;
                margin-left: calc(50% - 349px);
                margin-top: 129px;
                background: #FFFFFF;
                border-radius: 16px;
                &.hmp-show {
                    margin-left: calc(50% - 349px);
                    opacity: 1;
                    visibility: visible;
                    transition: margin-left 0.25s, left 0.25s, opacity 0.01s, visibility 0.1s !important;
                }
                &.hmp-hide {
                    margin-left: -1000px;
                    opacity: 0;
                    visibility: hidden;
                    transition: margin-left 0.25s, left 0.25s, opacity 0.25s, visibility 0.25s !important;
                }
                .how-header {
                    position: relative;
                    width: calc(100% - 40px);
                    margin-left: 20px;
                    margin-top: 20px;
                    height: 50px;
                    top: 20px;
                    .how-title {
                        font-weight: 500;
                        font-size: 1.5rem !important;
                        line-height: 124%;
                        color: #000000;
                    }
                    .how-x-button {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                        outline: none;
                        border: 2px solid transparent;
                        border-radius: 2px;
                        background-color: transparent;
                        img {
                            position: relative; 
                            left: -2px;
                            top: -8px;
                        }
                        &:focus {
                            border: 2px solid #030C43;
                            box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                            border-radius: 5px;
                            outline: none;
                        }
                    }
                    padding-bottom: 28px;
                    border-bottom: 1px solid #EAEAEA;
                    margin-bottom: 24px;
                }
                .how-row {
                    margin-top: 20px;
                    > img {
                        margin-left: 17px;
                        vertical-align: top;
                    }
                    .row-right {
                        vertical-align: top;
                        display: inline-block;
                        width: calc(100% - 140px);
                        margin-left: 20px;
                        .right-header {
                            font-weight: 500;
                            font-size: 1.42rem;
                            line-height: 1.64rem;
                            letter-spacing: 0.0018rem;
                            color: #1226AA;
                            margin-bottom: 6px;
                        }
                        .right-text {
                            font-weight: 400;
                            font-size: 1.14rem;
                            line-height: 1.71rem;
                            text-align: justify;
                            letter-spacing: 0.005em;
                            color: #000000;
                        }
                    }
                }
                .faq-row {
                    min-height: 56px;
                    border-bottom: 1px solid #EAEAEA;
                    width: calc(100% - 40px);
                    margin-left: 20px;
                    margin-right: 20px;
                    .faq-row-title {
                        .fr-text {
                            display: inline-block;
                            cursor: pointer;
                            font-weight: 500;
                            font-size: 1.17rem;
                            line-height: 1.28rem;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            color: #030C43;
                            width: calc(100% - 42px);
                            padding-left: 6px;
                            padding-right: 6px;
                        }
                        img {
                            margin-top: 18px;
                            float: right;
                        }
                        &:focus {
                            border: 2px solid #030C43;
                            box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                            border-radius: 5px;
                            outline: none;
                        }
                        &.faq-expanded {
                            .fr-text {
                                color: #4A3DF0;
                            }
                        }
                    }
                    .faq-desc {
                        max-height: 0;
                        overflow: hidden;
                        opacity: 0;
                        &.faq-expanded {
                            max-height: 500px;
                            opacity: 1;
                        }
                        transition: max-height 0.5s, opacity 0.3s !important;
                        .faq-icon {
                            width: 78px;
                            padding-bottom: 12px;
                            vertical-align: top;
                        }
                        .faq-content {
                            display: inline-block;
                            width: calc(100% - 130px);
                            margin-left: 22px;
                            padding-bottom: 12px;
                            font-weight: 400;
                            font-size: 1.14rem;
                            line-height: 1.71rem;
                            text-align: justify;
                            color: #000000;
                            .faq-link {
                                color: #4A3DF0;
                                cursor: pointer;
                                font-weight: bold;
                            }
                        }
                    }
                }
                .how-footer {
                    margin-top: 44px;
                    width: calc(100% - 40px);
                    margin-left: 20px;
                    margin-bottom: 26px;
                    background: #1226AA;
                    border-radius: 16px;
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-top: 16px;
                    padding-bottom: 14px;
                    font-weight: 500;
                    font-size: 1.28rem;
                    line-height: 157.69%;
                    letter-spacing: 0.0018rem;
                    color: #FFFFFF;
                    text-align: center;
                    border: none;
                    span.footer-link {
                        font-weight: 500;
                        font-size: 1.28rem;
                        line-height: 2.28rem;
                        letter-spacing: 0.0018rem;
                        //text-decoration-line: underline;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
                .how-space {
                    display: block;
                    width: 100%;
                    height: 1px;
                }
            }
        }

        .hmp-search {
            position: absolute;
            z-index: 2;

            width: 448px;
            max-width: calc(100% - 39px - 39px);
            height: 64px;
            left: 39px;
            top: 32px;

            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 16px;

            &.new-hmp-search {
                z-index: 2001;
                height: 135px;
                .search-title {
                    top: 22px;
                    color: #030C43;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .search-box {
                    top: 74px;
                    background: #F0F0F0;
                    padding: 10px;
                    border-radius: 10px;
                    width: calc(100% - 36px);
                    &::placeholder {
                        color: #030C43;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        opacity: 1;
                    }
                }
                .search-icon {
                    top: 74.5px;
                    right: 16px;
                }
            }
            
            .search-icon {
                cursor: pointer;
                position: absolute;
                right: 12px;
                top: 9px;
                border-radius: 90px;
                width: 42px;
                height: 42px;
                overflow: visible;
                img {
                    padding: none;
                    margin-left: 0px;
                    margin-top: -1px;
                }
            }

            .search-title {
                position: absolute;
                left: 20px;
                top: 10px;

                font-weight: 500;
                font-size: 1.21rem;
                line-height: 1.5rem;
                color: #000000;

                .hmp-new-step-no {
                    border-radius: 47px;
                    background: #1226AA;
                    color: #ffffff;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    margin-right: 8px;
                }
            }

            .search-box {
                position: absolute;
                left: 18px;
                top: 32px;
                font-weight: 400;
                font-size: 1.21rem;
                line-height: 1.5rem;
                color: #030C43;
                width: calc(100% - 90px);
                border: none;
                background-color: #fff;

                &::placeholder {
                    color: #565656;
                    opacity: 1.0;
                }

                &:focus {
                    background-color: #fff;
                    border: none;
                    outline: none;
                }
            }
        }

        .hmp-result {
            position: absolute;
            z-index: 2;

            width: 448px;
            min-height: 125px;
            left: 39px;
            top: 116px;

            &.new-hmp-result {
                top: 174px;
            }

            &.hmp-result-404 {
                min-height: 109px;
            }

            .search-title {
                margin-bottom: 24px;
                display: block;
                top: 22px;
                color: #030C43;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                .hmp-new-step-no {
                    border-radius: 47px;
                    background: #1226AA;
                    color: #ffffff;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    margin-right: 8px;
                }
            }

            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 16px;

            padding: 20px;
            padding-bottom: 0px;

            .res-rating-tab + .res-rating-tab {
                border-left: 1px solid #EAEAEA;
            }

            .res-sharing-more-notice {
                margin-top: 18px;
                border-radius: 10px;
                background: rgba(74, 61, 240, 0.13);
                padding-left: 13px;
                padding-right: 13px;
                padding-top: 8px;
                padding-bottom: 8px;
                width: 100%;
                .res-smn-title {
                    display: block;
                    color: #030C43;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 6px;
                }
                .res-smn-desc {
                    display: block;
                    color: #2E2E2E;
                    font-family: Poppins;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 147.2%; /* 20.608px */                    
                }
            }

            .res-rating-tab {
                height: 67px;
                width: 50%;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                background-color: transparent;
                outline: none;
                border: 2px solid transparent;
                &:focus {
                    border: 2px solid #030C43;
                    box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                    border-radius: 5px;
                    outline: none;
                }
                .rrt-img {
                    height: 26px;
                    margin-top: 10px;
                }
                .rrt-text {
                    font-weight: 500;
                    font-size: 0.85rem;
                    line-height: 1.07rem;
                    letter-spacing: 0.0015rem;
                    text-transform: uppercase;
                    color: #030C43;
                    margin-top: 9px;                
                }
                border-bottom: 1px solid #EAEAEA;
                &.rrt-selected {
                    .rrt-text {
                        color: #4A3DF0;
                    }
                    border-bottom: 4px solid #4A3DF0;
                }
            }

            .rrt-info {
                .rrti-main {
                    font-weight: 500;
                    font-size: 1.14rem;
                    line-height: 1.42rem;
                    text-align: center;
                    letter-spacing: 0.0018rem;
                    color: #030C43;               
                    margin-top: 16px;
                }
                .rrti-sub {
                    font-weight: 400;
                    font-size: 0.85rem;
                    line-height: 1.07rem;
                    text-align: center;
                    color: #030C43;
                }
                .rrti-trees {
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.14rem;
                    text-align: center;
                    letter-spacing: 0.0030rem;
                    color: #030C43;
                    img {
                        margin-right: 4px;
                    }
                }
                margin-bottom: 16px;
            }

            .res-meter-cont {
                height: 178px;
                overflow-y: hidden;
                position: relative;
                width: 100%;
                .meter-bg {
                    position: absolute;
                    left: 50%;
                    top: 22px;
                    margin-left: -125px;
                    z-index: 1;
                }
                .meter-pointer {
                    position: absolute;
                    left: 50%;
                    top: 22px;
                    margin-left: -100px;
                    margin-top: -6px;
                    z-index: 2;
                    width: 200px;
                    height: 200px;
                    transform: rotate(0deg);
                    transition: transform 0.5s;
                }
                .meter-label {
                    font-weight: 500;
                    font-size: 1.35rem;
                    line-height: 1.78rem;
                    text-align: center;
                    margin-top: 138px;
                }
            }
            .meter-select-package {
                height: 90px;
                width: 100%;
                .cust-dropdown-button {
                    width: calc(100% - 40px);
                    margin-left: 20px;
                }
                .crp-select-package-label {
                    text-align: center;
                }
                border-bottom: 1px solid #EAEAEA;
                button {
                    border: none !important;
                }
            }

            .res-mobile-min-cont {
                display: none;
                position: absolute;
                width: 100%;
                cursor: pointer;
                top: -5px;
                left: 0px;
                height: 20px;
                .res-mobile-min {
                    position: absolute;
                    width: 68px;
                    height: 4px;
                    left: calc(50% - 34px);
                    top: 10px;
                    background: #D9D9D9;
                    border-radius: 53px;
                }
            }

            .res-header {
                margin-bottom: -16px !important;
                .res-icon {
                    vertical-align: top;
                    margin-right: 11px;
                    margin-bottom: 18px;
                    width: 36px;
                    height: 36px;
                }
                .res-address {
                    vertical-align: top;
                    display: inline-block;
                    font-weight: 600;
                    font-size: 1.21rem;
                    line-height: 1.71rem;
                    color: #030C43;
                    width: calc(100% - 40px - 11px - 43px - 0px);
                    margin-bottom: 18px;
                    .cust-dropdown-button {
                        position: absolute;
                        top: 40px;
                        right: 5px;
                    }
                }
                .res-x-button {
                    cursor: pointer;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    width: 24px;
                    height: 24px;
                    outline: none;
                    border: 2px solid transparent;
                    border-radius: 2px;
                    background-color: transparent;
                    img {
                        left: -2px;
                        top: -2px;
                        right: 0;
                        bottom: 0;
                    }
                    &:focus {
                        border: 2px solid #030C43;
                        box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                        border-radius: 5px;
                        outline: none;
                    }
                }
                .rhb-cont {
                    width: calc(100%);
                    margin-bottom: 16px;
                    text-align: center;
                    margin-left: 0px;
                    margin-bottom: 32px !important;
                    .cust-dropdown-button {
                        width: 50%;
                    }
                    .rhb-btn {
                        display: inline-block;
                        border-radius: 5px;
                        background: #F6F5F5;
                        font-family: Poppins;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        color: #111C4E;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        text-transform: uppercase;
                        width: calc(50% - 8px);
                        margin-right: 8px;
                        cursor: pointer;
                    }
                    &.rhbc-full {
                        .rhb-btn {
                            width: 100%;
                        }
                        .cust-dropdown-button {
                            width: calc(100%);
                            margin-left: -4px;
                        }
                        .cust-dd-bubble {
                            top: 2px !important;
                            margin-left: 0px;
                        }
                    }
                    .rhb-btn + .cust-dropdown-button {
                        margin-left: 16px;
                    }
                }

                /*border-bottom: 1px solid #EAEAEA;*/
            }

            .res-title {
                display: inline-block;
                font-weight: 500;
                font-size: 1.14rem;
                line-height: 1.42rem;
                color: #1F2631;
                margin-top: 15px;
                margin-bottom: 15px;
                max-width: 200px !important;
            }
            .res-how-cont {
                display: inline-block;
                cursor: pointer;
                float: right;
                margin-top: 15px;
                border: 2px solid transparent;
                border-radius: 4px;
                background-color: transparent;
                .res-how {
                    margin-left: 4px;
                    display: inline-block;
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 133.93%;
                    letter-spacing: 0.0018rem;
                    //text-decoration-line: underline;
                    color: #030C43;
                }
                &:focus {
                    border: 2px solid #030C43;
                    box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                    border-radius: 5px;
                    outline: none;
                }
            }
            .res-sep {
                width: 100%;
                height: 1px;
                border-bottom: 1px solid #EAEAEA;
            }
            .res-rating {
                display: inline-block;
                box-sizing: border-box;
                background: #FFFFFF;
                border: 1px solid #E7E7E7;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
                border-radius: 16px;
                width: 198px;
                height: 152px;
                vertical-align: top;
                .rating-top {
                    width: calc(100% - 32px);
                    margin-top: 16px;
                    margin-left: 16px;
                    border-bottom: 1px solid #E6E6E6;
                    img {
                        margin-right: 9px;
                        vertical-align: top;
                        margin-bottom: 9px;
                    }
                    .res-rating-title {
                        vertical-align: top;
                        display: inline-block;
                        width: 110px;
                        height: 42px;
                        font-weight: 600;
                        font-size: 1.21rem;
                        line-height: 1.5rem;
                        color: #030C43;
                    }
                }
                .rating-text {
                    margin-top: 6px;
                    margin-left: 16px;
                    font-weight: 600;
                    font-size: 1.71rem;
                    line-height: 1.2rem;
                    color: #EC8E00;
                }
            }
            .res-rating + .res-rating {
                margin-left: 12px;
            }
            .res-cta {
                margin-top: 20px;
                width: calc(100% + 40px);
                margin-left: -20px;
                min-height: 265px;
                left: 59px;
                top: 542px;
                background: #F5F5F5;
                border-radius: 0px 0px 16px 16px;
                padding-top: 16px;
                text-align: center;
                &.new-res-cta {
                    .cta-button {
                        border-radius: 10px;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 139%; /* 22.24px */
                        text-transform: uppercase;
                    }
                }
                .cta-title {
                    margin-bottom: 12px;
                    font-weight: 600;
                    font-size: 1.42rem;
                    line-height: 133.93%;
                    color: #000000;
                    width: 75%;
                    margin-left: 12.5%;
                }
                .cta-button {
                    display: inline-block;
                    padding: 10px;
                    gap: 10px;               
                    width: 366px;
                    min-height: 71px;
                    left: 77px;
                    top: 602px;
                    background: #4A3DF0;
                    box-shadow: 0px 4px 4px #025171;
                    border-radius: 10px;
                    font-weight: 600;
                    font-size: 1.42rem;
                    line-height: 123.69%;
                    text-align: center;
                    letter-spacing: 0.0018rem;
                    color: #FFFFFF;
                    cursor: pointer;
                    outline: none;
                    border: 2px solid transparent;
                    &:focus {
                        border: 2px solid #030C43;
                        box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                        border-radius: 5px;
                        outline: none;
                    }
                }
                .cta-footer {
                    margin-top: 18px;
                    font-weight: 500;
                    font-size: 1.14rem;
                    line-height: 133.93%;
                    text-align: center;              
                    color: #030C43;
                    width: 90%;
                    margin-left: 5%;
                }
                .rrti-checks {
                    width: calc(100% - 40px);
                    margin-left: 20px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    img {
                        width: 16px;
                        vertical-align: top;
                        margin-right: 4px;
                        margin-bottom: 5px;
                    }
                    .rrti-check {
                        vertical-align: top;
                        display: inline-block;
                        width: calc(50% - 20px);
                        font-weight: 500;
                        font-size: 0.85rem;
                        line-height: 133.93%;
                        color: #030C43;
                        margin-bottom: 5px;
                        text-align: left;
                    }
                }    
            }

            .res-404-cont {
                margin-top: 28px;
                text-align: center;
                .res-404-1 {
                    display: inline-block;
                    font-weight: 600;
                    font-size: 1.42rem;
                    line-height: 1.71rem;
                    color: #474747;
                    margin-top: 9px;
                }
                .res-404-2 {
                    display: inline-block;
                    font-weight: 400;
                    font-size: 1.21rem;
                    line-height: 1.5rem;
                    text-align: center;
                    color: #474747;
                    margin-top: 9px;
                    max-width: 90%;
                    span {
                        display: inline-block;
                    }
                    a {
                        display: inline-block;
                        color: #4A3DF0;
                    }
                    .res-404-2-block {
                        text-align: left;
                        width: 100%;
                        margin-top: 8px;
                        li {
                            margin-top: 10px;
                            font-weight: 400;
                            font-size: 1.21rem;
                            line-height: 1.5rem;
                            color: #474747;
                            margin-left: 1rem;
                        }
                        li::before {
                            content: "•";
                            margin-right: -8px;
                            position: relative;
                            left: -16px;
                        }
                    }
                }
                .res-404-3 {
                    display: inline-block;
                    width: 310px;
                    font-weight: 400;
                    font-size: 1.21rem;
                    line-height: 1.5rem;
                    text-align: center;
                    color: #D73C2C;
                    margin-top: -12px;
                    max-width: 80%;
                }
            }
        }

        .hmp-show {
            left: 39px;
            opacity: 1;
            visibility: visible;
            transition: top 0.25s, left 0.25s, opacity 0.01s, visibility 0.01s !important;
        }

        .hmp-hide {
            left: -1000px !important;
            opacity: 0;
            visibility: hidden;
            transition: top 0.25s, left 0.25s, opacity 0.25s, visibility 0.25s !important;
        }

        .fade-hide {
            opacity: 0.0;
            transition: opacity 0.5s;
            pointer-events: none;
        }

        @media screen and (max-width: 848px) {
            .hmp-header {
                width: calc(100% - 10px - 10px);
                height: 105px;
                left: 10px;
                top: 9px;
            // overflow: hidden;
                text-align: center;
                z-index: 5;

                .header-desktop {
                    display: none;
                }
        
                .header-mobile {
                    display: block;
                    margin-left: 0px;
                    margin-top: 12px;
                }

                .map-switcher {
                    display: block;
                    width: calc(100% - 40px - 80px - 20px);
                    margin-top: 15px;
                    margin-left: 20px;
                    float: left;
                    max-width: 250px;
                }

                .lang-switcher {
                    position: relative;
                    display: inline-block;
                    width: 80px;
                    margin-top: 3px;
                    float: left;
                    display: block;
                    .lang-dropdown {
                        z-index: 5;
                        top: 34px;
                    }
                }
            }

            .hmp-search {
                max-width: calc(100% - 10px - 19px);
                left: 10px;
                top: 32px;
            }

            .hmp-btmh-floating {
                position: absolute;
                bottom: 38px;
                left: 0px;
                text-align: center;
                .cust-dropdown-button {
                    margin-right: 0px !important;
                }
                .rhb-btn {
                    margin-right: 0px !important;
                    z-index: 5;
                }
            }
        }

        @media screen and (max-width: 470px) {
            .new-hmp-search-bubble {
                left: 10px;
                top: calc(32px + 131px - 12px);
                z-index: 2002;
                width: calc(100% - 30px);
                img.hmp-bubble-dot {
                    position: relative;
                    width: 38px;
                    height: 38px;
                    left: calc(50% - 18px);
                }
                &.bubble-vis {
                    opacity: 1;
                    transition: opacity 0.5s;
                }
                &.bubble-hidden {
                    opacity: 0;
                    transition: opacity 0.5s;
                }
                .hmp-info-bubble {
                    background-color: #4a3df0;
                    width: 100%;
                    text-align: left;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.0015rem;
                    padding-left: 15px;
                    padding-right: 25px;
                    padding-top: 13px;
                    padding-bottom: 13px;
                    border-radius: 9px;
                    margin-top: 12px;
                    &::before {
                        content: '';
                        width: 0; 
                        height: 0; 
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        border-bottom: 15px solid #4a3df0;
                        position: absolute;
                        left: calc(50% - 14px);
                        top: 35px;
                    }
                }
            }
            .hmp-result {
                left: 0px;
                width: calc(100% - 12px);
                top: 170px;
                min-height: 100px;
                border-radius: 16px;
                padding-bottom: 76px;
                .res-header {
                    .res-icon {
                        margin-top: 10px;
                    }
                    .res-address {
                        margin-top: 10px;
                        .res-more-btn {
                            top: 40px;
                            right: 5px;
                        }
                    }
                    .res-x-button {
                        top: 5px;
                        right: 5px;
                    }
                }
                .res-rating {
                    display: block;
                    width: 100%;
                    height: auto;
                    min-height: 120px;
                }
                .res-mobile-min-cont {
                    display: block;
                    margin-top: 6px;
                }
                .res-rating + .res-rating {
                    margin-left: 0px;
                    margin-top: 16px;
                }
                .res-cta {
                    width: calc(100% + 40px);
                    border-radius: 0;
                    .cta-button {
                        width: calc(100% - 32px);
                    }
                    height: 320px;
                }
                .res-how-cont {
                    display: block;
                    .res-how {
                        vertical-align: top;
                    }
                }

                &.mobile-min {
                    top: calc(100vh - 300px);
                    left: 0px;
                    transition: top 0.25s, left 0.25s !important;
                }
        
                &.mobile-max {
                    top: 208px;
                    left: 0px;
                    &.hmp-result-404 {
                        top: calc(100vh - 496px);
                    }
                    transition: top 0.25s, left 0.25s !important;
                }
            }
        }

        @media screen and (max-width: 700px) {
            .hmp-map-switcher {
                bottom: 28px;
                top: initial;
                right: 24px;
                .hmms-dropdown {
                    bottom: 68px;
                    top: initial;
                    right: 0px;
                }
            }
            .hmp-how-bg {
                .hmp-how {
                    width: 100%;
                    margin-left: 0px;
                    left: 0px;
                    top: 110px;
                    background: #FFFFFF;
                    border-radius: 16px;
                    height: calc(100% - 110px) !important;
                    &.hmp-show {
                        margin-left: 0px;
                        visibility: visible;
                        transition: margin-left 0.25s, visibility 0.01s;
                    }
                    &.hmp-hide {
                        margin-left: -1000px;
                        visibility: hidden;
                        transition: margin-left 0.25s, visibility 0.25s;
                    }
                    .how-title {
                        font-size: 1.57rem !important;
                    }
                    .right-header {
                        height: 95px;
                        line-height: 95px !important;
                        font-size: 1.21rem !important;
                        margin-left: 5px;
                    }
                    .right-text {
                        margin-left: -95px;
                        margin-top: 10px;
                    }
                    .how-footer {
                        padding: 14px;
                    }
                }
            }
            .hmp-intro-bg {
                .hmp-intro {
                    position: relative;
                    width: 100%;
                    margin-left: 0px;
                    left: 0px;
                    top: 94px;
                    background: #FFFFFF;
                    border-radius: 16px;
                    height: auto;
                    .ph-lang-switcher {
                        top: 8px;
                        left: -24px;
                    }
                    
                    img.intro-img {
                        position: relative !important;
                        width: 50% !important;
                        left: 25% !important;
                        top: 36px !important;
                        max-height: 200px !important;
                    }

                    .phls-dropdown {
                        left: 24px !important;
                    }
        
                    .intro-right {
                        display: inline-block;
                        width: calc(100% - 30px);
                        left: 15px;
                        height: auto;
                    }

                    .intro-content {
                        font-size: 0.789rem;
                        line-height: 19px;
                        min-height: 75px !important;
                    }

                    .intro-buttons {
                        right: 5px;
                        .intro-next {
                            font-size: 1rem;
                        }
                        .intro-back {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
    .hmp-claim-blank-home, .hmp-claim-blank-home-address {
        min-height: 300px;
        .hmp-cbh-msg-top {
            color: #030C43;
            text-align: center;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 133.932%; /* 26.786px */
            width: 100%;
            margin-top: 55px;
            margin-bottom: 30px;
        }
        .hmp-cbh-msg-bottom {
            color: #2E2E2E;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 147.2%; /* 23.552px */
            width: 100%;
        }
        .hmp-cbh-title {
            margin-top: 20px;
            color: #2E2E2E;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 147.2%; /* 29.44px */
            margin-bottom: 30px;
        }
        .hmp-cbh-address-cont {
            border-radius: 5px;
            background: #FFF;
            box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.10);
            width: 100%;
            padding: 18px;
            padding-top: 0px;
            margin-top: -10px;
            .hmp-cbh-title {
                color: #04203C;
                font-family: Poppins;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 10px;
            }
            .hmp-cbh-search-box {
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                width: 100%;
                padding: 6px;
                margin-top: 0px;
                appearance: none; 
                border: none;
                :focus {
                    box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                    border-radius: 5px;
                    outline: none !important;
                }
                outline: none !important;
            }
            .hmp-cbh-error {
                color: #FFF;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background-color: #D73C2C;
                padding: 16px;
                margin-top: 14px;
                border-radius: 6px;
            }
            .hmp-cbh-warning {
                color: #000;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background-color: #ffef9f;
                padding: 16px;
                margin-top: 14px;
                border-radius: 6px;
                .bmp-cbh-undo {
                    width: 50%;
                    padding: 10px;
                    border-radius: 10px;
                    background: #D73C2C;
                    box-shadow: 0px 4px 2px 0px #A72C1C;
                    color: #FFF;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 123.688%; /* 19.79px */
                    letter-spacing: 0.024px;
                    cursor: pointer;
                    &:focus {
                        outline: 2px solid currentColor !important;
                    }
                }
                .bmp-cbh-confirm {
                    margin-left: 16px;
                    width: calc(50% - 16px);
                    padding: 10px;
                    border-radius: 10px;
                    background: #1CCF79;
                    box-shadow: 0px 4px 2px 0px #11995E;
                    color: #0D1840;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 123.688%; /* 19.79px */
                    letter-spacing: 0.024px;
                    cursor: pointer;
                    &:focus {
                        outline: 2px solid currentColor !important;
                    }
                }
            }
        }
        .rhb-btn {
            position: absolute;
            bottom: 20px;
            width: calc(100% - 40px);
            display: block;
            padding: 10px !important;
            border-radius: 5px;
            background: #1CCF79;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #030C43;
            padding-top: 5px;
            padding-bottom: 5px;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
}

.hmp-result {
    .crp-card, .crp-upgrade-packages {
        width: 409px !important;
        max-width: calc(100vw - 40px);
        margin-left: -2px;
        .crp-home-rating-header {
            position: relative;
        }
        .crp-up-title {
            position: relative;
        }
        .crp-faq-button {
            position: absolute;
            right: 2px;
            margin-right: 0px;
        }
    }
    .e1-crp-faq-button {
        position: absolute;
        right: 36px;
        top: 10px;
    }
}

@media screen and (max-width: 470px) {
    .hmp-claim-blank-home, .hmp-claim-blank-home-address {
        height: 250px !important;
    }
    .e1-crp-faq-button {
        right: 32px !important;
        top: 5px !important;
    }
    .hmp-result {
        min-height: calc(100vh - 157px) !important;
    }
}

a.link-with-underline{
    text-decoration: underline;
}