html {
    font-size: 100% !important;
	letter-spacing: initial !important;
	word-spacing: initial !important;
    body {
        font-family: 'Poppins';
	    font-style: normal;
    }
}

body.customer-page {
	background: #ffffff !important;
	margin-top: 0px !important;
	overflow-y: scroll !important;
	.customer-dashboard-page {
		height: 100%;
		position: relative;
		.cdb-header {
			width: 100%;
			height: 70px;
			left: 0px;
			top: 0px;
			background: #ffffff;
			text-align: center;
			box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
            &.left-justify {
				text-align: left;
                a > img {
				    height: 28px;
                    margin-top: 21px;
                    margin-left: 45px;
                }
			}
			.cdb-header-img {
				margin-top: 7px;
				height: 54px;
			}
			.cdb-logout {
				cursor: pointer;
				position: absolute;
				top: 24px;
				right: 24px;
			}
			.cdb-files {
				cursor: pointer;
				position: absolute;
				top: 24px;
				right: 52px;
			}
            .lang-switcher {
                top: 10px;
                right: 50px;
    
                position: absolute;
                display: inline-block;
                box-sizing: border-box;
                border: 1px solid #000;
                border-radius: 7px;
    
                cursor: pointer;
    
                .lang-button {
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    text-transform: uppercase;
                    height: 33px;
    
                    background-color: #ffffff;
                    color: #1226AA;
                    border-radius: 6px 0px 0px 6px;
                    padding-left: 10px;
                    padding-right: 10px;
                    border: 1px solid black;
                    &.selected {
                        border: 1px solid transparent;
                        background: #1226AA;
                        color: #ffffff;
                    }
                    &:focus {
                        border: 2px solid #030C43;
                        box-shadow: 0px 0px 0px 4px rgba(74, 61, 240, 0.31);
                        outline: none;
                        border-radius: 6px 0px 0px 6px;
                    }
                }
    
                .lang-button + .lang-button {
                    border-radius: 0px 6px 6px 0px;
                }
    
            }
            @media screen and (max-width: 550px) {
                .lang-switcher {
                    right: 38px;
                }
            }
		}
		text-align: center;
		.cdb-last-published {
			font-weight: 500;
			font-size: 1.14rem;
			line-height: 2rem;
			letter-spacing: 0.015625rem;
			color: #1226AA;
			margin-top: 8px;
			text-align: center;
			max-width: 353px;
		}
		.cdb-sub-step {
			padding: 14px;
			padding-left: 0px;
			&.cdb-sub-step-current {
				border-top: 1px solid #DDD;
				border-bottom: 1px solid #DDD;
				margin-top: -6px;
			}
			.cdb-substep-subtext {
				color: #1F2631;
				font-family: Poppins;
				font-size: 1rem;
				font-style: normal;
				font-weight: 500;
				line-height: 149.187%;
				letter-spacing: 0.005rem;
				margin-left: 14px;
			}
			.cdb-substep-subtext-highlight {
				color: #096;
				font-family: Poppins;
				font-size: 1rem;
				font-style: normal;
				font-weight: 600;
				line-height: 123.688%; /* 19.79px */
				letter-spacing: 0.0015rem;
				margin-left: 14px;
			}
			.cdb-substep-sign-up-here {
				margin-top: 15px;
				border-radius: 5px;
				background: #4A3DF0;
				box-shadow: 0px 4px 0px 0px rgba(74, 61, 240, 0.74);
				text-transform: uppercase;
				color: #FFFFFF;
				font-family: Poppins;
				font-size: 1rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				padding-top: 9px;
				padding-bottom: 9px;
				padding-left: 9px;
				padding-right: 9px;
				margin-left: 15px;
				max-width: 326px;
				width: calc(100% - 30px);
				cursor: pointer;
			}
			.cdb-sub-step-text {
				position: relative;
				display: inline-block;
				width: 80%;
				margin-left: 6px;
				/*top: -10px;
				left: -5px;*/
				color: #292B2C !important;
				font-family: Poppins;
				font-size: 1REM;
				font-style: normal;
				font-weight: 600;
				line-height: 141.5%;
				letter-spacing: 0.0015rem;
			}
			.cdb-sub-step-lower-text {
				font-weight: 400;
				font-size: 1.14rem;
				line-height: 2rem;
				letter-spacing: 0.005rem;
				color: #1F2631;
				margin-top: 10px;
				margin-left: 10px;
			}
		}
		.cdb-booking-disabled-1 {
			font-weight: 700;
			font-size: 1.14rem;
			line-height: 2rem;
			letter-spacing: 0.005rem;
			color: #FC5555;
			margin-bottom: 24px;
		}
		.cdb-booking-disabled-2 {
			font-weight: 700;
			font-size: 1.14rem;
			line-height: 2rem;
			letter-spacing: 0.005rem;
			color: #1F2631;
		}
		.cdb-disabled-msg {
			font-weight: 700;
			font-size: 0.9rem;
			line-height: 0.95rem;
			letter-spacing: 0.005rem;
			color: #FC5555;
			margin-top: 16px;
			margin-left: 16px;
		}
		.cdb-body {
			display: inline-block;
			width: 940px;
			max-width: calc(100vw - 32px);
			text-align: left;
			padding: 0px;
			margin-left: -32px;
			padding-left: 17px;
			.cdb-optional-header {
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 2.28rem;
				letter-spacing: 0.001rem;
				color: #45A9BC;
				img {
					margin-top: -5px;
					margin-right: 5px;
				}
			}
			.cdb-main-title {
				font-weight: 500;
				font-size: 1.71rem;
				line-height: 100%;
				letter-spacing: 0.0025rem;
				color: #1226AA;
				margin-top: 29px;
				max-width: 100%;
				margin-bottom: 17px;
				text-align: left;
			}
			.cdb-title-2 {
				font-weight: 500;
				font-size: 1.42rem;
				line-height: 1.64rem;
				letter-spacing: 0.0015rem;
				color: #1226AA;
				max-width: 100%;
				margin-top: 19px;
				margin-bottom: 18px;
				text-align: left;
				button {
					float: right;
				}
			}
			.cdb-view-report-block, .cdb-view-report-block-generic {
				background: rgba(6, 147, 227, 0.1);
				border-radius: 5px;
				width: calc(100% + 17px);
				text-align: left;
				padding: 16px;
				.cdb-vrb-title {
					font-weight: 500;
					font-size: 1.18rem;
					line-height: 2rem;
					letter-spacing: 0.0015rem;
					color: #164b6f;
					img {
						margin-right: 4px;
					}
				}
				.cdb-vrb-report-link {
					font-weight: 600;
					font-size: 1rem;
					line-height: 1.3rem;
					letter-spacing: 0.0015rem;
					color: #4A3DF0;
					cursor: pointer;
				}
				.cdb-vrb-button {
					margin-top: 16px;
					max-width: 352px;
					width: 100%;
					height: 36px;
					left: 40px;
					background: #0493cf;
					border-radius: 10px;
					font-weight: 500;
					font-size: 1.14rem;
					line-height: 2.57rem;
					text-align: center;
					letter-spacing: 0.0015rem;
					color: #ffffff;
					cursor: pointer;
					&:hover {
						opacity: 0.7;
					}
				}
				.crb-upload-button {
					margin-top: 16px;
					width: 132px;
					height: 36px;
					left: 40px;
					background: #0493cf;
					border-radius: 10px;
					font-weight: 500;
					font-size: 1.14rem;
					line-height: 2.57rem;
					text-align: center;
					letter-spacing: 0.0015rem;
					color: #ffffff;
					cursor: pointer;
					&:hover {
						opacity: 0.7;
					}
					float: right;
				}
			}
			.cdb-view-report-block-generic {
				img {
					margin-left: -4px;
					margin-right: 4px;
				}
				padding: 12px;
			}
			.cdb-step-content {
				border-top: 1px solid #dddddd;
				&.no-sep-line {
					border-top: none;
				}
				padding-top: 5px;
				margin-top: 0px;
				.cdb-sc-title {
					font-weight: 500;
					font-size: 1.42rem;
					line-height: 1.64rem;
					letter-spacing: 0.0015rem;
					color: #1226AA;
					margin-bottom: 8px;
				}
				a.cdb-sc-link {
					font-weight: 650;
					font-size: 0.875rem;
					line-height: 1.14rem;
					letter-spacing: 0.015rem;
					cursor: pointer;
					color: #000000;
					&:hover {
						color: #202020;
					}
				}
				.cdb-sc-desc {
					font-weight: 400;
					font-size: 1.14rem;
					line-height: 2rem;
					letter-spacing: 0.015625rem;
					color: #000000;
					padding: 20px;
				}
				&.csc-optional {
					margin-top: 18px;
					margin-left: 2px;
					.cdb-sc-title {
						margin-top: -15px;
						margin-bottom: 15px;
					}
					.cdb-sc-desc {
						padding: 0px;
						margin-bottom: 25px;
					}	
				}
				.cdb-sc-button {
					display: inline-block;
					background: #0493cf;
					border-radius: 10px;
					padding-top: 14px;
					padding-bottom: 14px;
					padding-left: 34px;
					padding-right: 34px;
					font-weight: 500;
					font-size: 1.14rem;
					line-height: 2.28rem;
					text-align: center;
					letter-spacing: 0.0015rem;
					color: #ffffff;
					cursor: pointer;
					&:hover {
						opacity: 0.7;
					}
				}
				.cdb-sc-button-2 {
					display: inline-block;
					background: #0493cf;
					border-radius: 10px;
					padding-top: 6px;
					padding-bottom: 6px;
					padding-left: 34px;
					padding-right: 34px;
					font-weight: 500;
					font-size: 1.14rem;
					line-height: 2.28rem;
					text-align: center;
					letter-spacing: 0.0015rem;
					color: #ffffff;
					max-width: 310px;
					cursor: pointer;
					&:hover {
						opacity: 0.7;
					}
					margin-bottom: 10px;
				}
				.cdb-sc-upgrade {
					margin-top: -14px;
					height: 56px;
					line-height: 4rem;
					margin-bottom: 14px;
					font-weight: 500;
					font-size: 1.14rem;
					letter-spacing: 0.0015rem;
					color: #1226AA;
					img {
						margin-right: 9px;
						vertical-align: middle;
					}
				}
				.cdb-sc-upgrade + .cdb-sc-upgrade {
					border-top: 1px solid #dddddd;
				}
			}
			.cdb-step-cont-optional {
				background: #ffffff;
				box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
				border-radius: 5px;
				padding: 20px;
				width: calc(100% + 17px);
				.cdb-step-content {
					border-top: none;
				}
			}
			.cdb-step-cont {
				background: #ffffff;
				&.cdb-step-cont-complete {
					border: none;
					box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
				}
				&.cdb-step-cont-pending {
					border: 2px solid #55B9FC;
					box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);					
				}
				&.cdb-step-cont-unavailable {
					border: 2px solid #FC5555;
					box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);					
				}
				&.cdb-step-cont-not-started {
					box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
				}
				border-radius: 5px;
				padding: 0px;
				width: calc(100% + 17px);
				margin-top: 14px;
				.cdb-step-highlight {
					width: calc(100%);
					height: 4px;
					margin-bottom: 12px;
					border-radius: 5px 5px 0px 0px;
					&.cdb-step-hl-complete {
						background: #34B233;
					}
					&.cdb-step-hl-pending {
						background: #55B9FC;
					}
					&.cdb-step-hl-unavailable {
						background: #FC5555;
					}
					&.cdb-step-hl-not-started {
						background: #DDDDDD;
					}
				}
				img {
					vertical-align: top;
					margin-top: 0px;
					margin-left: 10px;
					margin-bottom: 4px;
				}
				.cdb-step-title {
					margin-top: 4px;
					color: #1226AA;
					font-family: Poppins;
					font-size: 1.25rem;
					font-style: normal;
					font-weight: 600;
					line-height: 127%; /* 25.4px */
					letter-spacing: 0.001875rem;
					margin-left: 16px;
					margin-bottom: 14px;
					max-width: calc(100% - 32px);
				}
				&.cdb-step-cont-not-started {
					.cdb-step-title {
						color: #7A828D;
					}
				}
				.cdb-step-right {
					display: inline-block;
					width: calc(100% - 100px);
					margin-left: 0px;
					margin-top: -10px;
					vertical-align: top;
					position: relative;
					padding: 15px;
					.cdb-step-no {
						position: relative;
						top: 6px;
						left: -5px;
						color: #292B2C;
						font-family: Poppins;
						font-size: 1.125rem;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						letter-spacing: 0.001125rem;
						border-radius: 49px;
					}
					.cdb-step-status {
						text-align: right;
						font-family: Poppins;
						font-size: 1rem;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						letter-spacing: 0.0015rem;
						padding-top: 3px;
						padding-bottom: 3px;
						padding-left: 12px;
						padding-right: 12px;
						border-radius: 49px;
						&.cdb-step-status-complete {
							color: #0C451D;
							background-color: rgba(148, 229, 131, 0.30);
						}
						&.cdb-step-status-pending {
							color: #5D3711;
							background-color: rgba(255, 207, 13, 0.30);
						}
						&.cdb-step-status-unavailable {
							color: #FC5555;
							background-color: rgba(255, 13, 13, 0.10);
						}
						position: absolute;
						right: -25px;
						top: 15px;
					}
				}
				margin-bottom: 12px;
			}
			.cdb-last-updated {
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 1rem;
				letter-spacing: 0.001rem;
				color: #292B2C;
				position: relative;
				left: 37px;
				top: -5px;
			}
			.cdb-step-cont-generic {
				background: #ffffff;
				> img {
					width: 35px;
					height: 35px;
				}
				&.cdb-step-cont-complete {
					border: none;
					box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
					.cdb-step-content {
						padding-bottom: 12px;
						&.cdb-step-has-footer {  
							padding-bottom: 0px !important;
						}
					}
				}
				&.cdb-step-cont-pending {
					border: 2px solid #4A3DF0;
					box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
				}
				&.cdb-step-cont-unavailable {
					border: 2px solid #FC5555;
					box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);					
				}
				&.cdb-step-cont-not-started {
					box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
					> * {
						opacity: 0.4;
					}
					.cdb-step-status, .cdb-step-status > * {
						opacity: 1.0 !important;
					}
					.cdb-step-content {
						padding-bottom: 12px;
					}
				}
				border-radius: 5px;
				padding: 0px;
				width: calc(100% + 17px);
				margin-top: 14px;
				.cdb-step-highlight {
					width: calc(100%);
					height: 4px;
					margin-bottom: 12px;
					border-radius: 2px 2px 0px 0px;
					&.cdb-step-hl-complete {
						//background: #34B233;
						background: transparent !important;
					}
					&.cdb-step-hl-pending {
						background: #4A3DF0;
					}
					&.cdb-step-hl-unavailable {
						background: #FC5555;
					}
					&.cdb-step-hl-not-started {
						background: #888888;
					}
				}
				img {
					vertical-align: top;
					margin-top: 0px;
					margin-left: 18px;
					width: 35px;
					height: 35px;
					margin-right: -6px;
				}
				.cdb-step-right {
					display: inline-block;
					width: calc(100% - 100px);
					margin-left: 0px;
					margin-top: -18px;
					margin-bottom: 5px;
					vertical-align: top;
					position: relative;
					padding: 15px;
					.cdb-step-no {
						font-weight: 500;
						font-size: 1.25rem;
						line-height: 1.4rem;
						letter-spacing: 0.0005rem;
						color: #292B2C;
						margin-top: 11px;
					}
					.cdb-step-status {
						font-weight: 500;
						font-size: 0.875rem;
						line-height: 1.14rem;
						letter-spacing: 0.001rem;
						padding-top: 2px;
						padding-bottom: 2px;
						padding-left: 12px;
						padding-right: 12px;
						border-radius: 49px;
						&.cdb-step-status-complete {
							color: #34b233;
							right: -10px;
						}
						&.cdb-step-status-pending {
							color: #f2bf72;
						}
						&.cdb-step-status-unavailable {
							color: #FC5555;
						}
						&.cdb-step-status-not-started {
							color: #444444;
							margin-right: -11px;
						}
						position: absolute;
						right: -38px;
						top: 26px;
					}
					.cdb-done-expand-collapse {
						position: absolute;
						right: -38px;
						top: 28px;
						height: 15px;
						width: 15px;
						img {
							width: 15px;
							margin-top: -10px;
							margin-left: -6px;
						}
						cursor: pointer;
					}
				}
				.cdb-step-title {
					margin-top: 4px;
					font-weight: 500;
					font-size: 1.25rem;
					line-height: 1.42rem;
					letter-spacing: 0.0015rem;
					margin-left: 16px;
					color: #292B2C;
					max-width: calc(100% - 28px);
					margin-bottom: 12px;
					border-bottom: 1px solid #dddddd;
					padding-bottom: 12px;
				}
				margin-bottom: 12px;
				.cdb-step-content {
					border-top: none;
					&.no-sep-line {
						border-top: none;
					}
					padding-top: 5px;
					margin-top: 0px;
					.cdb-step-footer {
						border-top: 1px solid #dddddd;
						width: calc(100%);
						margin-left: 0px;
						margin-top: 22px;
						height: 45px;
						text-align: center;
						line-height: 45px;
						.cdb-vendor-link {
							display: inline-block;
							line-height: 55px;
							font-weight: 600;
							font-size: 1rem;
							line-height: 123.69%;
							text-align: center;
							letter-spacing: 0.0015rem;
							color: #4A3DF0;
							width: 100%;
							margin-left: -16px;
							img {
								width: 18px;
								height: 18px;
								margin-right: 7px;
								margin-top: -2px;
							}
						}
					}
					.cdb-done-with-this-step {
						display: inline-block;
						font-weight: 500;
						font-size: 1rem;
						line-height: 34px;
						letter-spacing: 0.013671875rem;
						color: #292B2C;
						padding: 0px;
						width: calc(100% - 32px);
						text-align: left;
						margin-top: 6px;
						margin-left: 16px;
						height: 34px;
						img {
							width: 75px;
							height: 34px;
							margin-top: 0px;
							float: right;
							margin-right: 4px;
						}
					}
					.cdb-step-para {
						font-weight: 500;
						font-size: 1rem;
						line-height: 1.5rem;
						letter-spacing: 0.013671875rem;
						color: #292B2C;
						margin-left: 16px;
						width: calc(100% - 32px);
						margin-bottom: 16px
					}
					.cdb-step-checkbox {
						margin-bottom: 10px;
						> img {
							width: 24px;
							height: 24px;
							margin-left: 16px;
							margin-top: -2px;
						}
						.cdb-more-info-icon {
							width: 20px;
							height: 20px;
							margin-right: -2px;
						}
						.cdb-step-check-title {
							display: inline-block;
							margin-left: 14px;
							font-weight: 500;
							font-size: 1rem;
							line-height: 1.5rem;
							letter-spacing: 0.013671875rem;
							color: #292B2C;
							white-space: pre;
						}
						.cdb-step-checkbox-button {
							float: right;
							font-weight: 600;
							font-size: 1rem;
							line-height: 123.69%;
							letter-spacing: 0.0015rem;
							color: #4A3DF0;
							text-align: right;
							padding: 0;
							margin-right: 6px;
							&.pending {
								color: #F39C12;
								margin-right: 16px;
								pointer-events: none;
							}
							&.cdb-more-info {
								margin-right: 12px;
							}
							> img {
								margin-left: -11px;
								height: 12px;
								margin-top: 2px;
							}
						}
					}
					.cdb-sc-title {
						font-weight: 500;
						font-size: 1.42rem;
						line-height: 1.64rem;
						letter-spacing: 0.0015rem;
						color: #1226AA;
						margin-bottom: 8px;
					}
					a.cdb-sc-link {
						font-weight: 650;
						font-size: 0.875rem;
						line-height: 1.14rem;
						letter-spacing: 0.015rem;
						cursor: pointer;
						color: #000000;
						&:hover {
							color: #202020;
						}
					}
					.cdb-sc-desc {
						font-weight: 400;
						font-size: 1.14rem;
						line-height: 2rem;
						letter-spacing: 0.015625rem;
						color: #000000;
						padding: 20px;
					}
					&.csc-optional {
						margin-top: 18px;
						margin-left: 2px;
						.cdb-sc-title {
							margin-top: -15px;
							margin-bottom: 15px;
						}
						.cdb-sc-desc {
							padding: 0px;
							margin-bottom: 25px;
						}	
					}
					.cdb-sc-button {
						display: inline-block;
						background: #0493cf;
						border-radius: 10px;
						padding-top: 14px;
						padding-bottom: 14px;
						padding-left: 34px;
						padding-right: 34px;
						font-weight: 500;
						font-size: 1.14rem;
						line-height: 2.28rem;
						text-align: center;
						letter-spacing: 0.0015rem;
						color: #ffffff;
						cursor: pointer;
						&:hover {
							opacity: 0.7;
						}
					}
					.cdb-sc-button-2 {
						display: inline-block;
						background: #0493cf;
						border-radius: 10px;
						padding-top: 6px;
						padding-bottom: 6px;
						padding-left: 34px;
						padding-right: 34px;
						font-weight: 500;
						font-size: 1.14rem;
						line-height: 2.28rem;
						text-align: center;
						letter-spacing: 0.0015rem;
						color: #ffffff;
						max-width: 310px;
						cursor: pointer;
						&:hover {
							opacity: 0.7;
						}
						margin-bottom: 10px;
					}
					.cdb-sc-upgrade {
						margin-top: -14px;
						height: 56px;
						line-height: 4rem;
						margin-bottom: 14px;
						font-weight: 500;
						font-size: 1.14rem;
						letter-spacing: 0.0015rem;
						color: #1226AA;
						img {
							margin-right: 9px;
							vertical-align: middle;
						}
					}
					.cdb-sc-upgrade + .cdb-sc-upgrade {
						border-top: 1px solid #dddddd;
					}
				}
			}
		}
		.crp-modal-cont {
			position: fixed;
			z-index: 20;
			background-color: rgba(31, 31, 31, 0.5);
			top: 0px;
			left: 0px;
			width: 100%;
			min-height: 100%;
			height: auto;
			overflow-y: scroll;
			.crp-modal, .cnp-modal {
				position: absolute;
				cursor: initial;
				width: 598px;
				margin-left: calc(50% - 299px);
				margin-top: 129px;
				border-radius: 16px;
				background-color: white;
				box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.15006);
				z-index: 5;
				padding: 16px;
				.crp-modal-header {
					.crp-modal-title {
						display: inline-block;
						font-weight: 600;
						font-size: 1.25rem;
						line-height: 1.9rem;
						letter-spacing: 0.0015rem;
						color: #000000;
						width: calc(100% - 48px);
						vertical-align: top;
					}
					button {
						position: relative;
						width: 16px;
						height: 16px;
						vertical-align: top;
						margin-top: 0px;
						cursor: pointer;
						margin-left: 20px;
						img {
							width: 16px;
							height: 16px;
							margin-left: -5px;
						}
					}
					margin-bottom: 9px;
					border-bottom: 1px solid #EAEAEA;
					padding-bottom: 16px;
				}
				.crp-modal-body {
	
				}
			}
		}
		@media screen and (max-width: 598px) {
			.crp-modal-cont {
				.crp-modal {
					margin-top: 0px;
					width: 100%;
					margin-left: 0px;
					min-height: 100vh;
				}
				.cnp-modal {
					margin-top: 10vh;
					width: calc(100% - 32px);
					margin-left: 16px;
					min-height: 40vh;
				}
				&.crp-modal-fullscreen {
					.cnp-modal {
						width: 100%;
						min-height: 100vh;
						left: 0px;
						top: 0px;
						margin-top: 0px;
						margin-left: 0px;
						border-radius: 0px;
					}
				}
			}
		}
		.cdb-share-modal-para {
			font-weight: 500;
			font-size: 1rem;
			line-height: 1.5rem;
			letter-spacing: 0.013671875rem;
			color: #292B2C;
		}
		.cdb-accept-checkbox {
			margin-top: 32px;
			text-align: left;
			> img {
				vertical-align: top;
			}
			&.disabled {
				pointer-events: none;
			}
			.cdb-accept-checkbox-label {
				vertical-align: top;
				display: inline-block;
				font-weight: 500;
				font-size: 1rem;
				line-height: 1.5rem;
				letter-spacing: 0.013671875rem;
				width: calc(100% - 40px);
				margin-left: 11px;
			}
			&.wide {
				img {
					margin-right: 10px;
				}
				width: 100%;
				margin-top: 14px;
			}
		}
		.cdb-preferred-partners-block {
			display: block;
			border-radius: 5px;
			padding: 12px;
			border: 1px solid #DDD;
			background: #FFF;
			max-width: 400px;
			img {
				width: 113px;
				height: 113px;
				margin-left: 0px !important;
			}
			margin: 14px 14px -6px 14px;
			.cdb-ppb-right {
				width: calc(100% - 113px - 12px);
				display: inline-block;
				margin-left: 12px;
				b {
					color: #292B2C;
					font-family: Poppins;
					font-size: 1.125rem;
					font-style: normal;
					font-weight: 600;
					line-height: 116%; /* 20.88px */
					letter-spacing: 0.0016875rem;
				}
				color: #1F2631;
				font-family: Poppins;
				font-size: 1rem;
				font-style: normal;
				font-weight: 500;
				line-height: 137%; /* 21.92px */
				letter-spacing: 0.005rem;
			}
		}
		.cdb-accept-buttons-block {
			width: calc(100% - 32px);
			height: 90px;
		}
		.cdb-accept-buttons {
			position: absolute;
			width: calc(100% - 32px);
			bottom: 0px;
			border-top: 1px solid #EDEDED;
			padding: 17px;
			.cdb-accept-done-btn {
				background: #1CCF79;
				border-radius: 10px;
				font-weight: 600;
				font-size: 0.875rem;
				line-height: 1.41rem;
				letter-spacing: 0.0125rem;
				color: #030303;
				height: 44px;
				width: calc(50% - 10px);
				max-width: 180px;
				float: right;
				&:hover {
					opacity: 0.6;
				}
				&.disabled {
					opacity: 0.3;
					pointer-events: none;
				}
			}
			.cdb-accept-cancel-btn {
				background: rgba(28, 207, 121, 0.12);
				border-radius: 10px;
				font-weight: 600;
				font-size: 0.875rem;
				line-height: 1.41rem;
				letter-spacing: 0.0125rem;
				color: #030303;
				height: 44px;
				width: calc(50% - 10px);
				max-width: 180px;
				float: right;
				&:hover {
					opacity: 0.6;
				}
				margin-left: 20px;
			}
		}
		.cdb-select-so-modal {
			.cdb-ssom-label {
				color: #292B2C;
				font-family: Poppins;
				font-size: 1rem;
				font-style: normal;
				font-weight: 600;
				line-height: 141.5%; /* 22.64px */
				letter-spacing: 0.0015rem;
				margin-bottom: 4px;
				margin-top: 16px;
			}
			.cdb-ssom-link {
				color: #4A3DF0;
				font-family: Poppins;
				font-size: 1rem;
				font-style: italic;
				font-weight: 600;
				line-height: 141.5%;
				letter-spacing: 0.0015rem;
			}
			.cdb-ssom-phone-input {
				outline: none;
				width: 100%;
				border: 1px solid #DADCE0;
				border-radius: 4px;
				padding: 8px;
				&::placeholder {
				  color: #565656;
				  font-family: Poppins;
				  font-size: 1rem;
				  font-style: normal;
				  font-weight: 500;
				  line-height: normal;
				}
				margin-bottom: 1rem;
				margin-top: -16px;
			}
			.cdb-ssom-text {
				color:#111C4E;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-align: left;
			}
			.cdb-ssom-btn-cont {
				margin-top: 20px;
				margin-bottom: 5px;
				border-top: 1px solid #DDD;
				padding-top: 20px;
				text-align: center;
				.cdb-ssom-confirm-btn {
					display: inline-block;
					cursor: pointer;
					border-radius: 5px;
					background: #4A3DF0;
					box-shadow: 0px 4px 0px 0px rgba(74, 61, 240, 0.74);
					width: calc(50% - 8px);
					padding: 10px;
					text-transform: uppercase;
					text-align: center;
					color: #FFFFFF;
					font-family: Poppins;
					font-size: 1rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
				.cdb-ssom-cancel-btn {
					display: inline-block;
					cursor: pointer;
					border-radius: 5px;
					border: 1px solid #F2F4F5;
					background: #F2F4F5;
					box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
					width: calc(50% - 8px);
					padding: 10px;
					margin-left: 12px;
					text-transform: uppercase;
					text-align: center;
					color: #4A3DF0;
					font-family: Poppins;
					font-size: 1rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}
		}
	}
	.cdb-non-footer {
		min-height: calc(100vh - 74px - 45px);
	}
	.cdb-footer {
		margin-top: 45px;
		bottom: 0px;
		z-index: 2;
		font-weight: 600;
		font-size: 1.14rem;
		line-height: 2rem;
		color: #ffffff;
		height: 74px;
		width: 100vw;
		text-align: center;
		background: #04203c;
		.cdb-fspace {
			height: 9px;
		}
	}

	.btmh-address {
		color: #030C43;
		font-family: Poppins;
		font-size: 1rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		width: calc(100% - 16px);
		margin-top: 7px;
		img {
			margin-top: -3px;
		}
	}
	.cdb-faq-cont {
		display: block;
		position: absolute;
		right: 94px;
		top: 21px;
		&.pending {
			right: 80px;
		}
		&.not-started {
			right: -16px;
		}
	}
}
