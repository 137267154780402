$red-danger-dark: #C44343;

.csettings-page-cont {
    .csettings-page-inner {
        width: calc(100% - 32px);
        margin-left: 16px;
        min-height: calc(100vh - 126px) !important;
    }
    @media screen and (min-width: 979px) {
        .csettings-page-inner {
            position: relative;
            left: 50%;
            margin-left: -470px;
            width: 940px;
            text-align: left;
        }
    }
    .cnp-settings-block + .cnp-settings-block {
        margin-top: 12px;
        border-top: 1px solid#EAEAEA;
        padding-top: 12px;
    }
    .cst-title {
        color: #0D1840;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.0040625rem;
        margin-top: 27px;
        margin-bottom: 25px;
    }
    .btmh-address {
        color: #030C43;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: calc(100% - 16px);
        margin-top: 7px;
        img {
            margin-top: -3px;
        }
    }
    .loading {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 200;
        background-color: rgba(31, 31, 31, 0.5);
        .loading-claiming-msg {
            position: absolute;
            width: 100vw;
            text-align: center;
            left: 0px;
            top: calc(50vh + 10vh);
            color: white;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.5rem;
            padding-left: 3.5rem;
            padding-right: 3.5rem;
        }
        img {
            position: absolute;
            width: 10vh;
            height: auto;
            left: calc(50vw - 5vh);
            top: calc(50vh - 5vh);
        }
        &.loading-show {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.25s, visibility 0.01s;
        }
        &.loading-hide {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            transition: opacity 0.25s, visibility 0.25s;
        }
    }
}

.crp-dataprivacy-cont {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .crp-dataprivacy {
        padding: 13px;
        display: inline-block;
        width: 940px;
        max-width: calc(100vw - 32px);
        background: #FFFFFF;
        box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
        border-radius: 5px;
        color: #1F2631;
        text-align: left;
        position: relative;
        .crp-dp-title {
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }   
        .crp-dp-updated-date {
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1.125rem;
            margin-bottom: 10px;
        }
        .crp-dp-copy {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            margin-bottom: 12px;
        }
        .crp-dp-btn-cont {
            width: 100%;
            border-radius: 7px;
            max-width: 358px;
            margin-bottom: 12px;
            padding: 0px;
            .crp-dp-btn {
                color: #292B2C;
                font-family: Poppins;
                font-size: 0.8rem;
                font-style: normal;
                font-weight: 500;
                line-height: calc(47px - 6px);
                text-align: center;
                margin-left: -2px;
                padding: 0px;
                cursor: pointer;
                img {
                    margin-top: -1px;
                    margin-right: -2px;
                }
                &.crp-dpb-selected {
                    color: #4A3DF0;
                    font-family: Poppins;
                    font-size: 0.8rem;
                    font-style: normal;
                    font-weight: 500;
                    border-radius: 6px;
                }
            }
            .crp-dp-btn + .crp-dp-btn {
                margin-left: 4px;
            }
        }
        .faq-li {
            margin-top: 4px;
            .faq-lib {
                color: #1226AA;
                font-weight: bold;
                display: inline-block;
                font-size: 1.5rem;
                vertical-align: top;
                line-height: 1.5rem;
            }
            .faq-lit {
                vertical-align: top;
                margin-left: 0.5rem;
                font-weight: 400;
                font-size: 1rem;
                line-height: 149.19%;
                letter-spacing: 0.005rem;
                color: #000000;
                display: inline-block;
                width: calc(100% - 1.5rem);
            }
        }
    }
}

.cst-share-more-error {
    color: #2E2E2E;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 147.2%; /* 20.608px */
}

.cst-warning-icon {
    margin-right: 6px;
    margin-top: -3px;
}

.cst-delete-account-btn {
    img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }
    height: 36px;
    outline: none;
    border: 0px;
    border-radius: 5px;
    background: #F6F5F5 !important;
    color: #D03525;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    max-width: 400px;
}

.cst-da-info {
    color: #292B2C;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24.5px;
    letter-spacing: 0.219px;
    b {
        font-weight: 700;
    }
    i {
        font-style: italic;
    }
}

.cst-del-step-1-warning {
    // color: #F8AC4B;
    color: #AF6302;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24.5px; /* 153.125% */
    letter-spacing: 0.219px;
}

.cst-delete-account-modal-cont {
    min-height: 400px;
    &.cst-delete-account-modal-cont-step-3 {
        min-height: auto;
    }
    .cst-da-label {
        color: #000;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 7px;
    }
    .cst-da-msg {
        color: #0D1840;
        font-family: Montserrat;
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        letter-spacing: 0.165px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .cst-da-label-2 {
        color: #0D1840;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: 0.0040625rem;
        margin-top: 17px;
        margin-bottom: 15px;
    }
    .cst-da-property-checkbox {
        display: block;
        width: 100%;
        text-align: left;
        padding-left: 26px;
        position: relative;
        img {
            position: absolute;
            left: 0px;
        }
    }
    .cst-da-property-checkbox + .cst-da-property-checkbox {
        margin-top: 8px;
    }
    .cst-da-input {
        border-radius: 10px;
        background: #FFF;
        width: 100%;
        padding: 7px;
        appearance: none;
        border: 1px solid rgba(0, 0, 0, 0.25);
    }
    .cst-delete-button {
        margin-top: 28px;
        background-color: #D73C2C;
        color: white;
        display: block;
        width: 100%;
        padding: 14px;
        text-align: center;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 123.688%; /* 19.79px */
        letter-spacing: 0.024px;
        border-radius: 6px;
        &:disabled {
            color: #7A828D;
            pointer-events: none;
            background-color: #DDD;
        }
    }
}

.cst-del-btn-cont {
    position: absolute;
    bottom: 0px;
    height: 87px;
    width: calc(100% - 16px);
    border-top: 1px solid #EDEDED;
    padding-top: 26px;
    &.cst-no-absolute {
        position: static !important;
        bottom: initial !important;
        margin-top: 20px;
    }
    .cst-del-btn-next {
        width: calc(50%);
        margin-right: 11.5px;
        height: 44px;
        border-radius: 10px;
        background: #1CCF79;
        color: #2D3645;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.175px;
        text-align: center;
        &:disabled {
            background-color: #DDD;
            color: #7A828D;
            pointer-events: none;
        }
        &.cst-del-btn-delete {
            background-color: $red-danger-dark;
            color: white;
            &:disabled {
                opacity: 0.4 !important;
            }
        }
    }
    .cst-del-btn-cancel {
        width: calc(50% - 24px);
        height: 44px;
        border-radius: 10px;
        background: rgba(28, 207, 121, 0.12);
        color: #292B2C;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.175px;
        text-align: center;
    }
}

.cst-da-buildings-title {
    margin-top: 16px;
    color: #0D1840;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.cst-da-buildings-subtitle {
    margin-top: 3px;
    color: #292B2C;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24.5px; /* 153.125% */
    letter-spacing: 0.219px;
}

.cst-da-building-card {
    border-radius: 10px;
    background: #F6F5F5;
    width: 100%;
    padding: 12px;
    margin-top: 16px;
    .en-toggle-button-msg {
        max-width: calc(100% - 101px);
    }
    .cst-da-bc-title {
        color: #0D1840;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 5px;
    }
    .cst-da-bc-desc {
        color: #292B2C;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24.5px; /* 153.125% */
        letter-spacing: 0.219px;
        b {
            font-weight: 700;
        }
        margin-bottom: 20px;
    }
    .cst-da-question {
        color: #292B2C;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24.5px; /* 153.125% */
        letter-spacing: 0.219px;
    }
}

.crp-negative {
    color: #C5271F !important;
}

.cst-invite-user-modal-cont {
    padding-top: 0px;
    .cst-invite-label {
        display: block;
        color: #000;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 214.5%; /* 30.03px */
        margin-bottom: 0px;
    }
    .cst-invite-user-email {
        outline: none;
        width: calc(100% - 8px);
        padding: 8px;
        border: none;
        margin-top: 8px;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        margin-bottom: 16px;
        &.input-error {
            border: 1px solid #E74C3C;
        }
        &::placeholder {
            color: #A4A4A4 !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    .crp-dp-btn + .crp-dp-btn {
        margin-left: 20px;
    }
    .crp-dp-btn-cont {
        margin-top: 0px;
    }
    .crp-dp-btn-cont {
        button {
            img {
            
            }   
        }
    }
    .cst-iu-btn-cont {
        margin-top: 20px;
        .cst-iu-btn-invite {
            padding: 9px 8px;
            width: 100%;
            color: #FFF;
            text-transform: uppercase;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 5px;
            background: var(--pal-primary);
            box-shadow: 0px 4px 0px 0px var(--pal-primary-faded);
            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }
}

.cst-delete-user-modal-cont {
    .cst-du-info {
        margin-top: 16px;
        color: #292B2C;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24.5px; /* 153.125% */
        letter-spacing: 0.219px;
    }
    .cst-du-btn-cont {
        margin-top: 16px;
        border-top: 1px solid #DDD;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 8px;
        .cst-du-btn-delete {
            background-color: #D4493A;
            border-radius: 5px;
            box-shadow: 0px 4px 0px 0px #ff725d;
            padding: 5px 24px;
            cursor: pointer;
            color: #FFF;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .cst-du-btn-cancel {
            background-color: rgba(66, 66, 66, 0.74);
            border-radius: 5px;
            box-shadow: 0px 4px 0px 0px #DDD;
            padding: 5px 24px;
            cursor: pointer;
            color: #FFF;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: 16px;
        }
    }
}

.cst-manage-user-modal-cont {
    padding-top: 16px;
    .en-profile-icon {
        
    }
    .cdb-style-res-more {
        .cust-dd-option {
            padding: 2px;
            height: 2.1rem;
            &.first {
                margin-top: 0px;
            }
        }
    }
    .crp-uli-pending {
        margin-left: 6px;
        padding: 2px 12px;
        margin-top: 6px;
    }
    .cst-mum-dot-menu-cont {
        display: inline-block;
        position: absolute;
        right: 42px;
        margin-top: -20px;
    }
    .crp-uli-name-cont {
        display: inline-block;
        max-width: calc(100vw - 120px);
        vertical-align: top;
        .crp-uli-name {
            vertical-align: top;
            max-width: calc(100vw - 120px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 214.5%; /* 34.32px */
            letter-spacing: 0.219px;
            display: inline-block;
            margin-left: 11px;
        }
    }
    .crp-dp-btn + .crp-dp-btn {
        margin-left: 20px;
    }
    .crp-dp-btn-cont {
        margin-top: 18px;
    }
    .crp-delete-user-cont {
        margin-top: 18px;
        height: 50px;
        border-top: 1px solid #DDD;
        border-bottom: 1px solid #DDD;
        button {
            color: #D4493A;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            height: 48px;
            img {
                margin-right: 2px;
            }
        }
        &.cst-delete-user-cont-disabled {
            opacity: 0.35;
            pointer-events: none;
        }
    }
    .cst-mu-btn-cont {
        border-top: 1px solid #DDD;
        margin-top: 20px;
        padding-top: 12px;
        .cst-mu-btn-save {
            padding: 9px 8px;
            width: calc(50% - 8px);
            margin-right: 8px;
            color: #FFF;
            text-transform: uppercase;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 5px;
            background: var(--pal-primary);
            box-shadow: 0px 4px 0px 0px var(--pal-primary-faded);
            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
        .cst-mu-btn-cancel {
            padding: 9px 8px;
            width: calc(50% - 8px);
            margin-left: 8px;
            color: var(--pal-primary);
            text-transform: uppercase;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 5px;
            background: #F2F4F5;
            box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
        .crp-delete-user {
            width: calc(50% - 8px);
            margin-left: 8px;
            color: #D4493A;
            text-transform: uppercase;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 0px;
            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
        &.three-wide {
            .cst-mu-btn-save {
                width: calc(25% - 9px);
            }
            .cst-mu-btn-cancel {
                width: calc(30% - 9px);
            }
            .crp-delete-user {
                width: calc(45% - 9px);
            }
        }
    }
    .cst-mu-btn-cont-invite {
        .cst-mu-btn-save {
            width: calc(33% - 8px);
        }
        .cst-mu-btn-cancel {
            width: calc(33% - 8px);
        }
        .cst-mu-btn-resend {
            padding: 9px 8px;
            width: calc(33% - 8px);
            margin-left: 8px;
            color: white;
            text-transform: uppercase;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 5px;
            background: var(--pal-secondary);
            box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }
}

.crp-uli-pending {
    position: relative;
    top: -8px;
    display: inline-block;
    background-color: #FF9500;
    color: #292B2C;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: 0.219px;
    padding: 6px 12px;
    border-radius: 12px;
    margin-right: 0px !important;
}

@media screen and (min-width: 620px) {
    .crp-uli-pending {
        top: 0px !important;
    }
}

.crp-building-sharing-cont {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .crp-building-sharing {
        padding: 13px;
        display: inline-block;
        width: 940px;
        max-width: calc(100vw - 32px);
        background: #FFFFFF;
        box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.10006);
        border-radius: 5px;
        color: #1F2631;
        text-align: left;
        position: relative;
        .crp-bs-title {
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }   
    }
    .crp-user-list {
        margin-top: 10px;
        .crp-ul-title {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 138.5%; /* 22.16px */
            margin-bottom: 37px;
            margin-top: 23px;
        }
        .crp-user-list-item + .crp-user-list-item {
            margin-top: 11px;
        }
        .crp-ul-invite {
            border-radius: 5px;
            box-shadow: 0px 4px 0px 0px rgba(74, 61, 240, 0.74);
            padding: 5px 9px;
            cursor: pointer;
            color: #FFF;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            float: right;
            position: relative;
            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
        .crp-uli-manage {
            color: #4A3DF0;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 20px */
            letter-spacing: 0.015625rem;
            cursor: pointer;
            float: right;
            margin-top: 9px;
            position: absolute;
            right: 10px;
        }
        .crp-uli-manage.for-desktop {
            display: none;
        }
        .crp-uli-name-cont {
            vertical-align: top;
            display: inline-block;
            max-width: calc(100% - 120px);
            .crp-uli-name {
                vertical-align: top;
                max-width: calc(100vw - 210px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .crp-user-list-item + .crp-user-list-item {
            border-top: 1px solid #DDD;
            padding-top: 12px;
            margin-top: 0px;
        }
        .en-role-badge {
            position: relative;
            top: -7px;
        }
        @media screen and (min-width: 620px) {
            .en-role-badge {
                top: 0px;
            }
            .crp-uli-manage.for-mobile {
                display: none;
            }
            .crp-uli-manage.for-desktop {
                display: inline-block;
            }
            .crp-user-list-item + .crp-user-list-item {
                border-top: none;
                padding-top: 0px;
                margin-top: 12px;
            }
        }
        .crp-user-list-item {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 214.5%; /* 34.32px */
            letter-spacing: 0.219px;
            div {
                display: inline-block;
                margin-right: 11px;
            }
        }
    }
}